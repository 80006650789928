import connect from 'react-redux/es/connect/connect';
import React from 'react';
import Modal from 'react-modal';
import getText from 'AppUtils/language';
import Preload from "react-preload";
import clsx from "clsx";

import Routes from "./routes";
import BoxShadow from "./components/box-shadow/BoxShadow";
import Loader from "./components/loader/Loader";

import iconClose from './resources/img/icon-close.svg';
import levelUp from './resources/img/level-up.png';

import './App.scss';
import './resources/css/align-right.scss';
import { appGetInterfaceLang, appGetStatus, uiGetModal, uiGetSection } from "./store/selectors";
import { appInitialize, uiToggleModal, uiRedirect } from 'AppStore/actions';
import './resources/sass/ModalGeneral.scss';
import './resources/sass/ModalSkils.scss';
import './resources/sass/ModalBonus.scss';
import './resources/sass/ModalAchievement.scss';
import { authStatus } from "./containers/auth/store/selectors";
import { getValue } from 'AppUtils/objects';

Modal.setAppElement('#root')

//Images to preload
import globy from './containers/home/img/globy.svg';
import background from './resources/img/background.jpg';
import bgMenu from './layout/menu/img/bg-menu.jpg';
import logoHeader from './layout/header/img/logo-header.svg';
import bgHeader from './layout/header/img/bg-header.png';
import bgButtonHeader from './layout/header/img/bg-button-header.svg';
import bgLevel from './layout/header/img/bg-level.png';
import bgTickets from './layout/header/img/bg-tickets.png';
import bgHamburgerMenu from './layout/header/img/bg-hamburger-menu.png';
import iconHamburger from './layout/header/img/icon-hamburger.svg';
import bgBtnPrize from './containers/home/img/bg-btn-prize.svg';
import bgBtnStore from './containers/home/img/bg-btn-store.svg';
import bgFatButton from './components/button/img/bg-fat-button.png';
import bgSlimButton from './components/button/img/bg-slim-button.png';
import prizeBmw from './containers/auth/main/img/prize-bmw.png';
import globe from './components/loader/img/glob.gif';

import NotificationsContainer from "./containers/user/notifications/NotificationsContainer";
import {userRepositoryPrizes} from "./containers/user/store/selectors";
import {userRepositoryLoadPrizes} from "./containers/user/store/actions";

const stateToProps = state => ({
	appGetStatus: appGetStatus(state),
	section: uiGetSection(state),
	uiGetModal: uiGetModal(state),
	authStatus: authStatus(state),
	lang: appGetInterfaceLang(state),
	userRepositoryPrizes: userRepositoryPrizes(state),
});

const actionsToProps = dispatch => ({
	appInitialize: () => dispatch(appInitialize()),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userRepositoryLoadPrizes: (payload) => dispatch(userRepositoryLoadPrizes(payload)),
});

@connect(stateToProps, actionsToProps)
class App extends React.Component {

	state = {
		loading: true,
		showMenu: false,
		imagesLoaded: false,
	};

	toggleMenu = () => {
		let visible = this.state.showMenu;

		this.setState({
			showMenu: !visible
		});
	};

	openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback });
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	handleOffline = () => {
		if (this.props.appGetStatus !== 'error') {
			this.openModal(getText('Error'), getText('No internet connection detected. Please try again.'), true, getText('Retry'), false, false, true, () => {
				if (window.navigator.onLine) {
					// this.props.appInitialize();
					this.props.uiToggleModal({}, { toggle: false }, {});
					this.props.uiRedirect({
						pathname: '/'
					})
				}
			});
		}
	}

	handleServerError = () => {
		this.openModal(getText('Error'), getText('There seems to be a problem. Please try again.'), true, getText('Retry'), false, false, false, () => {
			this.props.appInitialize();
			this.props.uiToggleModal({}, { toggle: false }, {});
		});
	}

	renderGeneralModal = () => {
		return (<Modal
		  isOpen={this.props.uiGetModal.options ? this.props.uiGetModal.options.toggle : false}
		  onRequestClose={this.props.uiGetModal.content && this.props.uiGetModal.content.restrictClose ? false : this.closeModal}
		  className="modal-general"
		>
			<BoxShadow className="modal-wrapper">
				<div className={clsx('modal-content', this.props.uiGetModal.content && this.props.uiGetModal.content.restrictClose && 'no-close')}>
					{this.props.uiGetModal.content && !this.props.uiGetModal.content.restrictClose &&
						<img
						  className="close-modal"
						  onClick={this.props.uiGetModal.content && this.props.uiGetModal.content.restrictClose ? false : this.closeModal}
						  src={iconClose}
						  alt=""/>
					}
					<div className="modal-info">
						<h3
						  className="modal-title">{this.props.uiGetModal.content ? this.props.uiGetModal.content.title : ''}</h3>
						<p
						  className="modal-message">{this.props.uiGetModal.content ? this.props.uiGetModal.content.message : ''}</p>
					</div>
					<div className="buttons-group">
						{this.props.uiGetModal.content && this.props.uiGetModal.content.button2 ?
						  <div
							className="btn-modal"
							onClick={this.props.uiGetModal.content.callback2 && this.props.uiGetModal.content.callback2}
						  >{this.props.uiGetModal.content.button2Text}</div>
						  : null
						}
						{this.props.uiGetModal.content && this.props.uiGetModal.content.button ?
						  <div
							className="btn-modal btn-secondary"
							onClick={() => {
								this.props.uiGetModal.content.callback && this.props.uiGetModal.content.callback()
							}}
						  >{this.props.uiGetModal.content.buttonText}</div>
						  : null
						}
					</div>
				</div>
			</BoxShadow>
		</Modal>);
	};

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	}

	componentDidMount() {
		this.props.appInitialize();
		window.addEventListener('offline', () => this.handleOffline());

		this.props.userRepositoryLoadPrizes();

		// let imgLoadedCount = 0;
		// [globy, background].forEach((picture) => {
		// 	const img = new Image();
		// 	img.src = picture;
		// 	console.log('img', img)
		// 	imgLoadedCount++;
		// });
		// this.setState({
		// 	imagesLoaded: imgLoadedCount
		// })
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.appGetStatus !== this.props.appGetStatus) {
			if (this.props.appGetStatus === 'ready') {
				setTimeout(() => this.setState({ loading: false }), 100);
			} else if (this.props.appGetStatus === 'error') {
				this.handleServerError();
			}
		}

		if(prevProps.lang !== this.props.lang) {
			document.documentElement.lang = this.props.lang;
		}
	}

	render() {
		const { section } = this.props;
		const { loading, imagesLoaded } = this.state;

		if (loading || !imagesLoaded) {
			return (
			  <>
			  <Loader/>
			  {this.renderGeneralModal()}

			  <Preload
				images={[globe, globy, background, bgMenu, logoHeader, bgHeader, bgLevel, bgTickets, bgHamburgerMenu, iconHamburger, bgBtnPrize, bgBtnStore, bgFatButton, bgSlimButton, bgButtonHeader]}
				autoResolveDelay={0}
				// onError={}
				onSuccess={this.handlePreloadImages}
				resolveOnError={true}
				mountChildren={false}
			  >
				  {/* content to be rendered once loading is complete */}
			  </Preload>
			  </>
			)
		}

		return (
		  <div key={this.props.lang || 'en'} className={clsx("App", section, this.props.lang)}>
			  <Routes
				toggleMenu={this.toggleMenu}
				showMenu={this.state.showMenu}
			  />
			  <NotificationsContainer/>
			  {this.renderGeneralModal()}

		  </div>
		);
	}
}

export default App;
