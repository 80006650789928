import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import isEqual from "react-fast-compare";

import PrizesComponent from "./PrizesComponent";
import { userInfo, userRepositoryPrizes } from "../user/store/selectors";
import { appGetInterfaceLang } from "../../store/selectors";
import { userRepositoryLoadPrizes } from "../user/store/actions";
import { uiToggleModal } from "../../store/actions";

const stateToProps = state => ({
	userInfo: userInfo(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
	userRepositoryPrizes: userRepositoryPrizes(state),
});

const actionsToProps = dispatch => ({
	userRepositoryLoadPrizes: (payload) => dispatch(userRepositoryLoadPrizes(payload)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class PrizesContainer extends Component {

    state = {
        imagesLoaded: false,
		repositoryPrizes: [],
    };

    handlePreloadImages = () => {
        this.setState({
            imagesLoaded: true
        })
    }

	openModal = (title, message, button, buttonText, button2, button2Text, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, {
			title,
			message,
			button,
			buttonText,
			button2,
			button2Text,
			callback
		});
	};

	componentDidMount() {
		this.props.userRepositoryLoadPrizes();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { userRepositoryPrizes } = this.props;

		if (userRepositoryPrizes.isLoading == false && userRepositoryPrizes.list.length > 0 && (this.state.repositoryPrizes.length == 0 || !isEqual(userRepositoryPrizes.list, prevProps.userRepositoryPrizes.list))) {
			this.setState(prevState => ({
				repositoryPrizes: [...prevState.repositoryPrizes, ...userRepositoryPrizes.list],
			}));
		}
	}

    render() {
	    const { repositoryPrizes } = this.state;

        return (
            <PrizesComponent
              repositoryPrizes={repositoryPrizes}
              imagesLoaded={this.state.imagesLoaded}
              handlePreloadImages={this.handlePreloadImages}
              lang={this.props.appGetInterfaceLang}
            />
        );
    }
}

export default PrizesContainer;