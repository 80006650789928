import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import Observable from 'Observable';
import { getValue } from 'AppUtils/objects';
import isEqual from "react-fast-compare";

import SigninComponent from "./SigninComponent";
import logger from 'AppUtils/logging';

import {validateSubmit} from "../../../../utils/form";

import { apiPost } from 'AppUtils/api';
import {authStatus, authUuid} from "../../store/selectors";
import { authSet } from "../../store/actions";
import { userLoadEvent, userLoadGuestEvent, userLoadInfo } from "../../../user/store/actions";
import { uiRedirect, uiToggleModal } from "../../../../store/actions";
import getText from 'AppUtils/language';
import ls from 'local-storage';
import { userInfo } from "../../../user/store/selectors";


const stateToProps = state => ({
	authUuid: authUuid(state),
	authStatus: authStatus(state),
	userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
	authSet: (auth) => dispatch(authSet(auth)),
	userLoadInfo: (data) => dispatch(userLoadInfo(data)),
	userLoadEvent: (data) => dispatch(userLoadEvent(data)),
	userLoadGuestEvent: (data) => dispatch(userLoadGuestEvent(data)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
});

@connect(stateToProps, actionsToProps)
class SigninContainer extends Component {

    state = {
        formData: {
            prefix: {
                element: 'input',
                value: `+${this.props.prefix}`,
                config: {
                    id: 'prefix',
                    name: 'prefix',
                    type: 'tel',
                    placeholder: getText('Prefix'),
                },
                validation: {
                    required: false,
                    numeric: true
                },
                valid: false,
                touched: false,
                validationMessage: ''
            },
            phone: {
                element: 'input',
                value: '',
                config: {
                    id: 'phone',
                    name: 'phone',
                    type: 'tel',
                    placeholder: getText('Phone'),
                },
                validation: {
                    required: true,
					minlength: this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.msisdn.minLength ? this.props.appGetConfig.users.msisdn.minLength : 9,
					maxLength: this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.msisdn.maxLength ? this.props.appGetConfig.users.msisdn.maxLength : 9,
                    numeric: true
                },
                valid: false,
                touched: false,
                validationMessage: ''
            },
            password: {
                element: 'input',
                value: '',
                config: {
                    id: 'password',
                    name: 'password',
                    type: 'password',
                    placeholder: getText('Password')
                },
                validation: {
                    required: true,
					minlength: this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.pin.minLength ? this.props.appGetConfig.users.pin.minLength : 6,
					maxLength: this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.pin.maxLength ? this.props.appGetConfig.users.pin.maxLength : 6,
                },
                valid: false,
                touched: false,
                validationMessage: ''
            }
        }
    }

    updateField = (fieldToUpdate, newFieldState) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldToUpdate]: newFieldState
            }
        })
    }

    handleSubmit = (e) => {
        logger.info('submit')
        e.preventDefault();


        let invalid = validateSubmit(this.state.formData);

        if(invalid) {
            this.setState({
                formData: invalid
            })
        } else {
            logger.info('valid');
			this.handleUserLogin();
        }
    }

	openModal = (title, message) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message });
	};

    componentDidUpdate(prevProps) {
    	const { authStatus } = this.props;
    	logger.info(authStatus);
	}

	handlePlayAsGuest = (e) => {
    	e.preventDefault();

    	const apikey = process.env.REACT_APP_GUEST_APIKEY;

		this.handleSuccessAuth(apikey, 200);
	};

	handleUserLogin = () => {
		let msisdn = this.state.formData.phone.value; //this.state.msisdn
		if(this.props.prefix) {
			msisdn = `${this.props.prefix.replace('+', '')}${this.state.formData.phone.value}`;
		}
		let password = this.state.formData.password.value; //this.state.password
		let uuid= this.props.authUuid;;

		apiPost('/user/login', { msisdn, password, uuid })
		  .catch((e) => {
			  this.setState({
				  isSubmitting: false
			  });

			  this.openModal(getText('Error'), getText('Wrong username or password, please try again'));
			  return Observable.empty();
		  })
		  .mergeMap(res => {
			  let resp = Promise.resolve({ error: true });
			  if (res.ok) {
				  resp = res.json();
			  }

			  return Promise.resolve({ resp, statusCode: res.status });
		  })
		  .subscribe((result) => {
			  const { resp, statusCode } = result;

			  resp.then(response => {
				  if (response && !response.error) {
					  this.setState({
						  errorMsg: '',
						  isSubmitting: false
					  });

					  const apikey = response.apikey;

					  if (apikey) {
						  this.handleSuccessAuth(apikey, statusCode);
					  }
				  } else {
					  this.setState({
						  errorMsg: response.error,
						  isSubmitting: false
					  });

					  let errorMsg = '';

					  switch (statusCode) {
						  case 400:
							  errorMsg = getText('Authentification service bad request, please try again later');
							  break;
						  case 401:
							  errorMsg = getText('Wrong username or password, please try again');
							  break;
						  case 403:
							  errorMsg = getText('Authentification service forbidden, please try again later');
							  break;
						  case 404:
							  errorMsg = getText('Authentification service not available, please try again later');
							  break;
						  case 500:
							  errorMsg = getText('Authentification service internal error, please try again later');
							  break;
						  case 502:
							  errorMsg = getText('Authentification service internal error, please try again later');
							  break;
						  case 503:
							  errorMsg = getText('Authentification service too busy, please try again later');
							  break;
						  default:
							  errorMsg = getText('Wrong username or password, please try again');
					  }
					  this.openModal(getText('Error'), errorMsg);
				  }
			  });
		  });
	};

	handleSuccessAuth = (apikey, statusCode) => {
		this.props.authSet({ apikey, status: 'valid' });

		// now we have an apikey yey... load info
		this.props.userLoadInfo({ apikey, status: 'valid' });

		//load event
		if (apikey != process.env.REACT_APP_GUEST_APIKEY) {
			this.props.userLoadEvent({ type: 'LOGIN', statusCode });
		} else {
			this.props.userLoadGuestEvent({ type: 'LOGIN', statusCode });
		}


		this.props.uiRedirect({
			pathname: '/'
		});

		//redirect the dude to previous page.
		// const from = getValue(this.props, 'location.state.from', null);
		//
		// if (from) {
		// 	this.props.uiRedirect({
		// 		pathname: from
		// 	});
		// } else {
		// 	this.props.uiRedirect({
		// 		pathname: '/'
		// 	});
		// }
	};

    render() {
        return (
            <SigninComponent
			  lang={this.props.lang}
              formData={this.state.formData}
              updateField={this.updateField}
              handleSubmit={this.handleSubmit}
			  prefix={this.props.prefix}
			  handlePlayAsGuest={this.handlePlayAsGuest}
			  prize={this.props.userRepositoryPrizes?.list.length > 0 ? this.props.userRepositoryPrizes?.list.filter((prize) => prize.position === 1)[0] : false}
            />
        );
    }
}

export default SigninContainer;