import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import isEqual from "react-fast-compare";
import Observable from 'Observable';

import StoreComponent from "./StoreComponent";
import { userLoadInfo, userRepositoryLoadBanners, userRepositoryLoadCreditPackages } from "../user/store/actions";
import { userInfo, userRepositoryBanners, userRepositoryCreditPackages } from "../user/store/selectors";
import { appGetInterfaceLang, appGetConfig } from "../../store/selectors";
import { uiToggleModal } from "../../store/actions";
import { authStatus, authUuid } from "../auth/store/selectors";
import { apiPost } from 'AppUtils/api';
import getText from 'AppUtils/language';
import Layout from "../../layout/Layout";
import { getValue } from 'AppUtils/objects';

const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
	userRepositoryBanners: userRepositoryBanners(state),
	userRepositoryCreditPackages: userRepositoryCreditPackages(state),
	userInfo: userInfo(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
	authUuid: authUuid(state),
	authStatus: authStatus(state),
});

const actionsToProps = dispatch => ({
	userRepositoryLoadBanners: (payload) => dispatch(userRepositoryLoadBanners(payload)),
	userRepositoryLoadCreditPackages: (payload) => dispatch(userRepositoryLoadCreditPackages(payload)),
	userLoadInfo: (payload) => dispatch(userLoadInfo(payload)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class StoreContainer extends Component {

	state = {
		errorMsg: '',
		imagesLoaded: false,
		repositoryBanners: [],
		repositoryCreditPackages: [],
		filtersUiBanners: {
			isLoading: false
		},
		filtersUiCreditPackages: {
			isLoading: false
		},
	};

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	}
	openModal = (title, message, button, buttonText, button2, button2Text, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, {
			title,
			message,
			button,
			buttonText,
			button2,
			button2Text,
			callback,
			callback2
		});
	};
	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};
	onCreditsBuy = (packageId) => {

		if (this.props.authStatus == 'guest-user') {
			this.openModal(getText('Warning'), getText('This account is limited!'));
		} else {
			this.openModal(getText('Confirmation'), getText('Are you sure you want to buy these tickets?'), true, getText('Yes'), true, getText("No"), () => {
				let uuid = this.props.authUuid;

				apiPost('/user/credits/buy', { packageId, uuid })
				  .catch((e) => {
					  this.setState({
						  isSubmitting: false
					  });
					  this.openModal(getText('Error'), getText('Phone number is not subscribed'));

					  return Observable.empty();
				  })
				  .mergeMap(res => {
					  let resp = Promise.resolve({ error: true });
					  if (res.ok) {
						  resp = res.json();
					  }

					  return Promise.resolve({ resp, statusCode: res.status });
				  })
				  .subscribe((result) => {
					  const { resp, statusCode } = result;

					  resp.then(response => {
						  if (response && !response.error) {
							  this.setState({
								  errorMsg: '',
								  isSubmitting: false
							  });

							  this.props.userLoadInfo();

							  this.openModal(getText('Success'), getText('Please check your SMS in order to finalize your transaction'), true, getText('Ok'), false, false, () => {
								  this.props.uiToggleModal({}, { toggle: false }, {});
							  });
						  } else {
							  this.setState({
								  errorMsg: response.error,
								  isSubmitting: false
							  });
							  let errorMsg = '';
							  switch (statusCode) {
								  case 400:
									  errorMsg = getText('Authentification service bad request, please try again later');
									  break;
								  case 401:
									  errorMsg = getText('Wrong username or password, please try again');
									  break;
								  case 403:
									  errorMsg = getText('Authentification service forbidden, please try again later');
									  break;
								  case 404:
									  errorMsg = getText('Authentification service not available, please try again later');
									  break;
								  case 500:
									  errorMsg = getText('Authentification service internal error, please try again later');
									  break;
								  case 502:
									  errorMsg = getText('Authentification service internal error, please try again later');
									  break;
								  case 503:
									  errorMsg = getText('Authentification service too busy, please try again later');
									  break;
								  default:
									  errorMsg = getText('Wrong username or password, please try again');
							  }

							  this.openModal(getText('Error'), errorMsg);
						  }
					  });
				  });
			}, this.closeModal);
		}
	};

	componentDidMount() {
		this.props.userRepositoryLoadBanners();
		this.props.userRepositoryLoadCreditPackages();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { userRepositoryBanners, userRepositoryCreditPackages } = this.props;

		if (userRepositoryBanners.isLoading == false && userRepositoryBanners.list.length > 0 && (this.state.repositoryBanners.length == 0 || !isEqual(userRepositoryBanners.list, prevProps.userRepositoryBanners.list))) {
			this.setState(prevState => ({
				repositoryBanners: [...prevState.repositoryBanners, ...userRepositoryBanners.list],
				filtersUiBanners: {
					...prevState.filtersUiBanners,
					isLoading: false
				},
			}));
		}

		if (userRepositoryCreditPackages.isLoading == false && userRepositoryCreditPackages.list.length > 0 && (this.state.repositoryCreditPackages.length == 0 || !isEqual(userRepositoryCreditPackages.list, prevProps.userRepositoryCreditPackages.list))) {
			this.setState(prevState => ({
				repositoryCreditPackages: [...prevState.repositoryCreditPackages, ...userRepositoryCreditPackages.list],
				filtersUiCreditPackages: {
					...prevState.filtersUiCreditPackages,
					isLoading: false
				},
			}));
		}
	}

	getTabIndex = (slug) => {
		let index = 0;

		if (slug == 'recommended') {
			index = 0;
		} else if (slug == 'tickets') {
			index = 1;
		}

		return index;
	};

	render() {
		const { repositoryCreditPackages, repositoryBanners, filtersUiBanners, filtersUiCreditPackages } = this.state;
		const { userInfo, appGetInterfaceLang } = this.props;
		const { slug = '' } = getValue(this.props, 'match.params');

		return (
		  <Layout hasFooter={false} hasStats={false} hasSubheader={true} title={getText('Store')} showMenu={this.props.showMenu} toggleMenu={this.props.toggleMenu} backLink={this.props.backLink}>
			  <StoreComponent
				tabIndex={this.getTabIndex(slug)}
				imagesLoaded={this.state.imagesLoaded}
				handlePreloadImages={this.handlePreloadImages}
				repositoryCreditPackages={repositoryCreditPackages}
				repositoryBanners={repositoryBanners}
				filtersUiBanners={filtersUiBanners}
				filtersUiCreditPackages={filtersUiCreditPackages}
				userInfo={userInfo}
				lang={appGetInterfaceLang}
				onCreditsBuy={this.onCreditsBuy}
				disableBuyCredits={this.props.appGetConfig.features.disableBuyCredits}
			  />
		  </Layout>
		);
	}
}

export default StoreContainer;