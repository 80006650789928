import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import isEqual from "react-fast-compare";
import Observable from 'Observable';
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';
import logger from 'AppUtils/logging';

import ProfileEditComponent from "./ProfileEditComponent";

import { validateSubmit } from "../../../../utils/form";
import { userLoadInfo, userLoadUpdateProfile, userRepositoryLoadAvatars } from "../../../user/store/actions";
import { userInfo, userMsg, userProfileUpdate, userRepositoryAvatars } from "../../../user/store/selectors";
import { authStatus, authUuid } from "../../../auth/store/selectors";
import { appGetInterfaceLang } from "../../../../store/selectors";
import { uiToggleModal } from "../../../../store/actions";

const stateToProps = state => ({
	userInfo: userInfo(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
	authUuid: authUuid(state),
	authStatus: authStatus(state),
	avatars: userRepositoryAvatars(state),
	userMsg: userMsg(state),
	userProfileUpdate: userProfileUpdate(state),
});

const actionsToProps = dispatch => ({
	userLoadInfo: (payload) => dispatch(userLoadInfo(payload)),
	userRepositoryLoadAvatars: () => dispatch(userRepositoryLoadAvatars()),
	userLoadUpdateProfile: (payload) => dispatch(userLoadUpdateProfile(payload)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class ProfileEditContainer extends Component {

	state = {
		imagesLoaded: false,
		avatars: '',
		avatarsLoaded: false,
		formSubmitted: false,
		formMsg: '',
		selectedAvatar: '',
		avatarFile: '',
		profileUpdate: '',
		formData: {
			nickname: {
				element: 'input',
				value: '',
				config: {
					id: 'nickname',
					name: 'nickname',
					type: 'text',
					placeholder: getText('Nickname'),
					label: getText('Nickname')
				},
				validation: {
					required: false,
					minlength: 0,
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
			avatar: {
				element: 'input',
				value: '',
				config: {
					id: 'avatar',
					name: 'avatar',
					type: 'file',
					placeholder: getText('Upload picture'),
					label: getText('Avatar'),
				},
				validation: {
					required: false,
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
		},
		filtersUi: {
			isLoading: false,
		}
	}

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	};

	toBase64 = () => new Promise((resolve, reject) => {
		const reader = new FileReader();
		const input = document.getElementById("avatar");
		reader.readAsDataURL(input.files[0]);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

	updateField = (fieldToUpdate, newFieldState) => {
		this.setState({
			...this.state,
			formSubmitted: false,
			formMsg: '',
			formData: {
				...this.state.formData,
				[fieldToUpdate]: newFieldState
			}
		}, () => {
			const avatarFile = getValue(this.state, 'formData.avatar.value');

			if (fieldToUpdate == 'avatar' && avatarFile) {
				const fileExtension = avatarFile.split('.').pop().toLowerCase();
				const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
				if (allowedExtensions.includes(fileExtension)) {
					this.setState({
						selectedAvatar: '',
					});

					(async () => {
						const result = await this.toBase64().catch(e => Error(e));
						if (!(result instanceof Error)) {
							this.setState({
								avatarFile: result
							});
						}
					})();
				} else {
					this.setState({
						formMsg: getText('The file has invalid extension. It must be jpg, jpeg, png or gif'),
						formData: {
						  	...this.state.formData,
							avatar: {
								...this.state.formData.avatar,
								value: ''
							}
						}
					});
				}
			}
		});
	};

	handleSubmit = (e) => {
		logger.info('submit')
		e.preventDefault();

		let invalid = validateSubmit(this.state.formData);

		if (invalid) {
			this.setState({
				formData: invalid
			})
		} else {
			if (this.props.authStatus == 'guest-user') {
				this.openModal(getText('Warning'), getText('This account is limited!'));
			} else {
				logger.info('valid');

				this.props.userLoadUpdateProfile({
					nickname: this.state.formData.nickname.value,
					avatarUrl: this.state.selectedAvatar,
					avatarFile: this.state.avatarFile,
				});

				this.setState({
					formSubmitted: true,
					profileUpdate: '',
					formMsg: '',
					filtersUi: {
						...this.state.filtersUi,
						isLoading: true,
					}
				});
			}
		}
	};

	openModal = (title, message, button, buttonText, button2, button2Text, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, {
			title,
			message,
			button,
			buttonText,
			button2,
			button2Text,
			callback
		});
	};
	onSelectAvatar = (avatar) => {
		this.setState({
			selectedAvatar: avatar,
			formSubmitted: false,
			formMsg: '',
			avatarFile: '',
			formData: {
				...this.state.formData,
				avatar: {
					...this.state.formData.avatar,
					value: '',
				}
			}
		});
	};

	componentDidMount() {
		const { userInfo } = this.props;

		if (userInfo) {
			const userInfoCh = getValue(userInfo, 'character', '');
			const customAvatar = userInfoCh.avatar && userInfoCh.avatar.indexOf('avatar_custom') !== -1 ? true : false;

			if (!customAvatar) {
				this.setState({
					selectedAvatar: userInfoCh.avatar,
				});
			}

			this.setState({
				formData: {
					...this.state.formData,
					nickname: {
						...this.state.formData.nickname,
						value: userInfoCh.nickname,
					}
				}
			});
		}

		this.setState({
			avatarsLoaded: false,
		}, () => {
			this.props.userRepositoryLoadAvatars();
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.formSubmitted === false && this.state.formMsg === true) {
			this.props.userLoadInfo();
			this.openModal(getText('Success'), getText('Successfully Saved!'));
			this.setState({
				formMsg: '',
			});
		}

		if (!this.state.avatars && this.props.avatars.isLoading == false && this.props.avatars.list.length > 0) {
			this.setState({
				avatars: this.props.avatars.list,
				avatarsLoaded: true,
				formSubmitted: false,
			})
		}

		if (this.props.userProfileUpdate.isLoading == false
		  && this.props.userMsg
		  && this.state.formMsg != this.props.userMsg
		  && prevProps.userMsg != this.props.userMsg) {
			this.setState({
				formMsg: this.props.userMsg,
			});
		}

		if (this.state.formMsg && this.state.formMsg !== true && this.state.formMsg !== prevState.formMsg) {
			this.openModal(getText('Error'), this.state.formMsg);
			this.setState({
				filtersUi: {
					...this.state.filtersUi,
					isLoading: false,
				}
			})
		} else if (this.props.userProfileUpdate.data
		  && this.props.userProfileUpdate.isLoading == false
		  && this.state.formSubmitted == true
		  && !isEqual(this.props.userProfileUpdate.data, this.state.profileUpdate)) {
				this.setState({
					formMsg: true,
					profileUpdate: this.props.userProfileUpdate.data,
					formSubmitted: false,
					filtersUi: {
						...this.state.filtersUi,
						isLoading: false,
					}
				});
		}

	}

	render() {
		const { avatarsLoaded, avatars, selectedAvatar, formSubmitted, formMsg } = this.state;
		const { userInfo } = this.props;

		return (
		  <ProfileEditComponent
			imagesLoaded={this.state.imagesLoaded}
			selectedAvatar={this.state.selectedAvatar}
			avatars={this.state.avatars}
			formSubmitted={this.state.formSubmitted}
			formMsg={this.state.formMsg}
			onSelectAvatar={this.onSelectAvatar}
			avatarsLoaded={this.state.avatarsLoaded}
			handlePreloadImages={this.handlePreloadImages}
			formData={this.state.formData}
			userInfo={userInfo}
			updateField={this.updateField}
			handleSubmit={this.handleSubmit}
			isLoading={this.state.filtersUi.isLoading}
		  />
		);
	}
}

export default ProfileEditContainer;