import React from 'react';
import Preload from "react-preload";
import { motion } from "framer-motion";
import InfiniteScroll from 'react-infinite-scroller';
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';

import LoaderPage from "../../components/loader-page/LoaderPage";
import PositionContainer from "./subcomponents/position/PositionContainer";
import BoxShadow from "../../components/box-shadow/BoxShadow";

import waveAvatar from './img/wave-avatar.svg';

import './Leaderboard.scss'


const leaderboardVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const myPositionVariants = {
    hidden: {
        y: '100%',
    },
    visible: {
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.4,
            delay: 0.2
        }
    }
}

const listVariants = {
    hidden: {
        y: -40,
        opacity: 0,
    },
    visible: (custom) => ({
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.3,
            delay: custom * 0.1
        }
    })
}

const LeaderboardComponent = (props) => {

    let items = '';

    if (props.leaderBoard.length > 0) {
        items = <>{props.leaderBoard.map((item, i) => (<motion.li
          key={i}
          custom={((i % props.limit) + 2)}
          variants={listVariants}
          initial="hidden"
          animate="visible"
        >
            <BoxShadow>
                <div className="user">
                    <PositionContainer position={item.rank} />

                    <div className="avatar-user">
                        <img src={item.avatar + `?t=${window.performance.now()}`} alt=""/>
                    </div>

                    <div className="info-user">
                        <h4>{item.nickname}</h4>
                        <p>{item.points} XP</p>
                    </div>
                </div>
            </BoxShadow>
        </motion.li>))}</>;
    }

    // items = (
    //   <>
    //       <motion.li
    //         custom={2}
    //         variants={listVariants}
    //         initial="hidden"
    //         animate="visible"
    //       >
    //           <BoxShadow>
    //               <div className="user">
    //                   <PositionContainer position="1" />
	//
    //                   <div className="avatar-user">
    //                       <img src={user1} alt=""/>
    //                   </div>
	//
    //                   <div className="info-user">
    //                       <h4>Deanna87K</h4>
    //                       <p>25.820 XP</p>
    //                   </div>
    //               </div>
    //           </BoxShadow>
    //       </motion.li>
	//
    //       <motion.li
    //         custom={3}
    //         variants={listVariants}
    //         initial="hidden"
    //         animate="visible"
    //       >
    //           <BoxShadow>
    //               <div className="user">
    //                   <PositionContainer position="2" />
	//
    //                   <div className="avatar-user">
    //                       <img src={user1} alt=""/>
    //                   </div>
	//
    //                   <div className="info-user">
    //                       <h4>Roger_Fisher</h4>
    //                       <p>25.800 XP</p>
    //                   </div>
    //               </div>
    //           </BoxShadow>
    //       </motion.li>
	//
    //       <motion.li
    //         custom={4}
    //         variants={listVariants}
    //         initial="hidden"
    //         animate="visible"
    //       >
    //           <BoxShadow>
    //               <div className="user">
    //                   <PositionContainer position="3" />
	//
    //                   <div className="avatar-user">
    //                       <img src={user1} alt=""/>
    //                   </div>
	//
    //                   <div className="info-user">
    //                       <h4>XxJohnxX</h4>
    //                       <p>25.820 XP</p>
    //                   </div>
    //               </div>
    //           </BoxShadow>
    //       </motion.li>
	//
    //       <motion.li
    //         custom={5}
    //         variants={listVariants}
    //         initial="hidden"
    //         animate="visible"
    //       >
    //           <BoxShadow>
    //               <div className="user">
    //                   <PositionContainer position="4" />
	//
    //                   <div className="avatar-user">
    //                       <img src={user1} alt=""/>
    //                   </div>
	//
    //                   <div className="info-user">
    //                       <h4>Kristina-Foster</h4>
    //                       <p>25.820 XP</p>
    //                   </div>
    //               </div>
    //           </BoxShadow>
    //       </motion.li>
	//
    //       <motion.li
    //         custom={6}
    //         variants={listVariants}
    //         initial="hidden"
    //         animate="visible"
    //       >
    //           <BoxShadow>
    //               <div className="user">
    //                   <PositionContainer position="5" />
	//
    //                   <div className="avatar-user">
    //                       <img src={user1} alt=""/>
    //                   </div>
	//
    //                   <div className="info-user">
    //                       <h4>Dan Phillips</h4>
    //                       <p>25.820 XP</p>
    //                   </div>
    //               </div>
    //           </BoxShadow>
    //       </motion.li>
	//
    //       <motion.li
    //         custom={7}
    //         variants={listVariants}
    //         initial="hidden"
    //         animate="visible"
    //       >
    //           <BoxShadow>
    //               <div className="user">
    //                   <PositionContainer position="6" />
	//
    //                   <div className="avatar-user">
    //                       <img src={user1} alt=""/>
    //                   </div>
	//
    //                   <div className="info-user">
    //                       <h4>Mildred Holt</h4>
    //                       <p>25.820 XP</p>
    //                   </div>
    //               </div>
    //           </BoxShadow>
    //       </motion.li>
    //   </>
    // );

    const userInfo  = getValue(props, 'userInfo.character');

    return (
        <>
            <LoaderPage isVisible={props.imagesLoaded && !props.filtersUi.isLoading} />
            <Preload
              images={[waveAvatar]}
              autoResolveDelay={5000}
              // onError={}
              onSuccess={props.handlePreloadImages}
              resolveOnError={true}
              mountChildren={false}
            >
            </Preload>

            {props.imagesLoaded && !props.filtersUi.isLoading && (
              <motion.div
                className="leaderboard-component"
                variants={leaderboardVariants}
                initial="hidden"
                animate="visible"
              >
                  <div className="content-wrapper-small">
                      <div className="listing-users-positions">
                          {props.authStatus != 'guest-user' && <motion.div
                            className="my-position"
                            variants={myPositionVariants}
                            initial="hidden"
                            animate="visible"
                          >
                              <p>{getText('My position')}</p>
                              <BoxShadow>
                                  <div className="user">
                                      <PositionContainer position={userInfo.position} />

                                      <div className="avatar-user">
                                          <img src={getValue(userInfo, 'avatar')} alt=""/>
                                      </div>

                                      <div className="info-user">
                                          <h4>{userInfo.nickname}</h4>
                                          <p>{userInfo.points} XP</p>
                                      </div>
                                  </div>
                              </BoxShadow>
                          </motion.div>}


                          <div className="leaderboard-list-wrapper">
                              <InfiniteScroll
                                element="ul"
                                className="leaderboard-list"
                                pageStart={0}
                                loadMore={() => props.loadItems()}
                                hasMore={props.hasMore}
                                useWindow={false}
                                initialLoad={false}
                                getScrollParent={() => props.mainRef.current}
                              >
                                  {items}
                              </InfiniteScroll>
                          </div>
                      </div>
                  </div>
              </motion.div>
            )}
        </>
    );
};

export default LeaderboardComponent;