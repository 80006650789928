import React from 'react';
import {Link} from "react-router-dom";
import { motion } from "framer-motion";
import getText from 'AppUtils/language';

import PromptContainer from "../subcomponents/prompt/PromptContainer";
import TextComponent from "../subcomponents/text/TextComponent";

import arrowLeft from "../../../../../layout/header/subcomponents/subheader/img/arrow-left.png";
import prizeBmwPng from '../../../main/img/prize-bmw.png';

import './Recover.scss';

const prizeVariants = {
    hidden: {
        scale: 0,
        opacity: 0,
    },
    visible: {
        scale: 1,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2
        }
    }
}

const RecoverComponent = (props) => {
    return (
        <div className="recover-component small-form-component auth-component">
            <div className="form-container">
                <div className="content-wrapper-small">
                    <div className="page-title">
                        <Link className="back-btn" to={{ pathname: '/signin', state: { from: window.location.pathname } }}><img src={arrowLeft} alt=""/></Link>
                        <h3>{getText('Reset your password')}</h3>
                    </div>
					{props.type === 'direct' ?
                        <PromptContainer prefix={props.prefix} appGetConfig={props.appGetConfig} />
						:
                        <TextComponent />
					}

                    <div className="recover-info d-none">
                        <p className="instructions-auth">{getText('We have sent an SMS with intructions to this number')}:</p>
                        <p className="phone-no">+31087088232</p>
                        <div className="form-field submit-input-group">
                            <Link className="box-shadow default-input submit-btn" to={{ pathname: '/signin' }}>{getText('OK')}</Link>
                        </div>
                    </div>
                </div>
            </div>

            <motion.div
              className="prize-wrapper"
              variants={prizeVariants}
              initial="hidden"
              animate="visible"
            >
                <img
                  className="prize-bg"
                  src={prizeBmwPng}
                  alt=""
                />
                <img
                  className="prize"
                  src={props.prize?.image}
                  alt=""
                />
            </motion.div>
        </div>
    );
};

export default RecoverComponent;