import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import Modal from 'react-modal';
import clsx from "clsx";
import { HtmlParser } from 'AppUtils/language';

import { userInfo, userNotifications, userNotificationsConsume } from "../store/selectors";
import { uiToggleModal, uiRedirect } from 'AppStore/actions';
import { appGetInterfaceLang, uiGetSection, uiGetModal } from "../../../store/selectors";
import { userLoadInfo, userLoadNotifications, userLoadNotificationsConsume } from "../store/actions";
import getText from 'AppUtils/language';
import { getValue } from 'AppUtils/objects';
import isEqual from "react-fast-compare";
import BoxShadow from "../../../components/box-shadow/BoxShadow";
import iconClose from '../../../resources/img/icon-close.svg';
import levelUp from '../../../resources/img/level-up.png';
import bonusModal from './img/bonus-modal.png';

const stateToProps = state => ({
	userInfo: userInfo(state),
	section: uiGetSection(state),
	notifications: userNotifications(state),
	userNotificationsConsume: userNotificationsConsume(state),
	lang: appGetInterfaceLang(state),
	uiGetModal: uiGetModal(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadNotificationsConsume: (payload) => dispatch(userLoadNotificationsConsume(payload)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	userLoadInfo: (payload) => dispatch(userLoadInfo(payload)),
});

@connect(stateToProps, actionsToProps)
class NotificationsContainer extends Component {

	constructor(props) {
		super(props);

		this.disabledSections = [
			'challenge',
		  	'challenge-instructions',
			'bonus-challenge',
		  	'bonus-challenge-instructions',
			'signin',
			'signup',
			'skills',
		  	'achievements',
		  	'result-game',
		  	'result',
		];

		this.state = {
			currentSection: ''
		};
	}

	openModal = (notification, restrictClose, callback_pri, callback_sec) => {
		this.props.uiToggleModal({}, { toggleNotificationsModal: true }, {
			notification,
			restrictClose,
			callback_pri,
			callback_sec
		});
	};

	closeModal = (callback) => {
		this.props.uiToggleModal({}, { toggleNotificationsModal: false }, {
			notification: {},
			restrictClose: false,
			callback_pri: '',
			callback_sec: ''
		});
	};

	triggerNotification = () => {
		if (!this.state.currentSection || this.props.section != this.state.currentSection) {
			this.setState({
				currentSection: this.props.section,
			}, () => {
				if (!this.disabledSections.includes(this.state.currentSection) && this.props.notifications.list.length > 0) {
					const notification = this.props.notifications.list[0];

					this.handleNotification(notification);
				}
			});
		}
	};

	handleNotification = (notification) => {
		const type = notification.type;

		switch (type) {
			case 'bonusGame':
				this.openModal(
				  notification,
				  true,
				  () => {
					  this.closeModal();

					  this.props.uiRedirect({
						  pathname: `/bonus-challenge/instructions`, state: {
							  playId: notification.target_id,
							  notificationId: notification.id
						  }
					  });
				  }
				);
				break;
			case 'levelUp':
				this.props.userLoadInfo();

				this.openModal(
				  notification,
				  false,
				  () => {
					  this.consumeNotifications(notification);
					  this.props.uiRedirect({ pathname: `/skills` });
				  },
				  () => {
					  this.consumeNotifications(notification);
				  }
				);
				break;
			case 'achievement':
				this.openModal(
				  notification,
				  false,
				  () => {
					  this.consumeNotifications(notification);
					  this.props.uiRedirect({ pathname: `/achievements-details/${notification.target_id}` });
				  },
				  () => {
					  this.consumeNotifications(notification);
				  }
				);
				break;
			case 'newCity':
				this.openModal(
				  notification,
				  false,
				  () => {
					  this.consumeNotifications(notification);
					  this.props.uiRedirect({ pathname: `/map` });
				  },
				  () => {
					  this.consumeNotifications(notification);
				  }
				);
				break;
			case 'custom':
				this.openModal(
				  notification,
				  false,
				  () => {
					  this.consumeNotifications(notification);
					  this.props.uiRedirect({ pathname: notification.button_action });
				  }
				);
				break;
		}
	}

	consumeNotifications = (notification) => {
		this.props.userLoadNotificationsConsume({ notificationId: notification.id });
		this.closeModal();
	}

	componentDidUpdate(prevProps, prevState) {
		this.triggerNotification();

		if (this.props.notifications.isLoading == false
		  && this.props.notifications.list.length > 0
		  && !isEqual(prevProps.notifications.list, this.props.notifications.list)) {
			this.setState({
				notifications: this.props.notifications.list,
				currentSection: '',
			});
		}
	}

	componentDidMount() {
		this.triggerNotification();
	}

	renderNotificationsModal = () => {
		const notification = getValue(this.props, 'uiGetModal.content.notification') ? this.props.uiGetModal.content.notification : {};
		const type = notification.type;
		const lang = this.props.lang;
		const toggleNotificationsModal = getValue(this.props, 'uiGetModal.options.toggleNotificationsModal', false);
		const restrictClose = getValue(this.props, 'uiGetModal.content.restrictClose');
		const callbackPri = getValue(this.props, 'uiGetModal.content.callback_pri') ? this.props.uiGetModal.content.callback_pri : () => {
		};
		const callbackSec = getValue(this.props, 'uiGetModal.content.callback_sec') ? this.props.uiGetModal.content.callback_sec : () => {
		};

		let modalInfo = '';

		if (type == 'levelUp') {
			const userInfo = getValue(this.props, 'userInfo.character');
			const currentLevel = getValue(userInfo, 'level.current');
			const threshold = getValue(userInfo, 'level.nextThreshold');
			const currentPoints = getValue(userInfo, 'points');

			modalInfo = (<div className="modal-info">
				<div className="level">
					<img src={levelUp} alt=""/>
					<p>{currentLevel}</p>
				</div>
				<p className="points">{currentPoints} {getText('Points')}</p>
				<p className="modal-message">{getText('Congratulations!')} <br/>
					<HtmlParser html="You are now <strong>level {0}</strong> in order to reach <strong>level {1}</strong> you need <strong>{2} more points</strong>. Keep playing to level up and unlock new skills."
								strFormat={[currentLevel, currentLevel + 1, threshold - currentPoints]}/></p>

			</div>);
		} else if (type == 'achievement') {
			modalInfo = (<div className="modal-info">
				<h3
				  className="modal-title">{getValue(notification, `title.${lang}`, 'Default title')}</h3>
				<div className="level">
					<img src={notification.image} alt=""/>
				</div>
				<p
				  className="modal-message">{getValue(notification, `text.${lang}`)}</p>
			</div>);
		} else if (type == 'newCity') {
			modalInfo = (<div className="modal-info">
				<h3
				  className="modal-title">{getValue(notification, `title.${lang}`, 'Default title')}</h3>
				<div className="level">
					<img src={notification.image} alt=""/>
				</div>
				<p
				  className="modal-message">{getValue(notification, `text.${lang}`)}</p>
			</div>);
		}else if (type == 'bonusGame') {
			modalInfo = (<div className="modal-info">
				<h3
				  className="modal-title">{getValue(notification, `title.${lang}`)}</h3>
				<div className="modal-image">
					<img src={bonusModal} alt=""/>
				</div>
				<p
				  className="modal-message">{getValue(notification, `text.${lang}`)}</p>
			</div>);
		} else {
			modalInfo = (<div className="modal-info">
				<h3
				  className="modal-title">{getValue(notification, `title.${lang}`)}</h3>
				<p
				  className="modal-message">{getValue(notification, `text.${lang}`)}</p>
			</div>);
		}

		return (<Modal
			isOpen={toggleNotificationsModal}
			onRequestClose={restrictClose ? () => {} : callbackSec}
			className={clsx({
				'modal-general': true,
				'skills-modal': (type == 'levelUp' ),
				'bonus-modal': (type == 'bonusGame'),
				'achievement-modal': (type == 'achievement'),
			})}
		  >
			  <BoxShadow className="modal-wrapper">
				  <div
					className={clsx('modal-content', restrictClose && 'no-close')}>
					  {!restrictClose && <img
						className="close-modal"
						onClick={() => { callbackSec(); }}
						src={iconClose}
						alt=""/>}
					  {modalInfo}
					  <div className="buttons-group">
						  <div
							className="btn-modal btn-secondary"
							onClick={() => {
								callbackPri();
							}}
						  >{getValue(notification, `button_text.${lang}`)}</div>
					  </div>
				  </div>
			  </BoxShadow>
		  </Modal>
		);
	}

	render() {
		return (<>{this.renderNotificationsModal()}</>);
	}
}

export default NotificationsContainer;