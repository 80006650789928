import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import isEqual from "react-fast-compare";
import Observable from 'Observable';
import getText from 'AppUtils/language';
import { getValue } from 'AppUtils/objects';

import SkillsComponent from "./SkillsComponent";
import { userInfo, userRepositorySkills } from "../user/store/selectors";
import { userLoadInfo, userLoadUpdateOnboarding, userRepositoryLoadSkills } from "../user/store/actions";
import { appGetInterfaceLang } from "../../store/selectors";
import { apiPost } from 'AppUtils/api';
import { authStatus, authUuid } from "../auth/store/selectors";
import { uiToggleModal } from "../../store/actions";


const stateToProps = state => ({
	userRepositorySkills: userRepositorySkills(state),
	userInfo: userInfo(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
	authUuid: authUuid(state),
	authStatus: authStatus(state),
});

const actionsToProps = dispatch => ({
	userRepositoryLoadSkills: (payload) => dispatch(userRepositoryLoadSkills(payload)),
	userLoadInfo: (payload) => dispatch(userLoadInfo(payload)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	userLoadUpdateOnboarding: (payload) => dispatch(userLoadUpdateOnboarding(payload)),
});

@connect(stateToProps, actionsToProps)
class SkillsContainer extends Component {

    state = {
		errorMsg: '',
		isSubmitting: false,
        imagesLoaded: false,
		repositorySkills: [],
		filtersUi: {
			isLoading: false
		},
    };

    handlePreloadImages = () => {
        this.setState({
            imagesLoaded: true
        })
    }

	componentDidMount() {
		this.props.userRepositoryLoadSkills();

		const onboardingStep = getValue(this.props, 'userInfo.settings.onboardingStep', 1);
		const onboardingSubstep = getValue(this.props, 'userInfo.settings.onboardingSubstep', 1);

		if (onboardingStep == 5 && onboardingSubstep == 2) {
			this.props.userLoadUpdateOnboarding({ onboardingStep: 6, onboardingSubstep: 1 });
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { userRepositorySkills } = this.props;

		if (userRepositorySkills.isLoading == false && userRepositorySkills.list.length > 0 && (this.state.repositorySkills.length == 0 || !isEqual(userRepositorySkills.list, prevProps.userRepositorySkills.list))) {
			this.setState(prevState => ({
				repositorySkills: [...prevState.repositorySkills, ...userRepositorySkills.list],
				hasMore: userRepositorySkills.list.length > 0,
				filtersUi: {
					...prevState.filtersUi,
					isLoading: false
				},
			}));
		}
	}

	openModal = (title, message, button, buttonText, button2, button2Text, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, button2, button2Text, callback  } );
	};

	levelUp = (skillCode, points) => {
		let uuid = this.props.authUuid;

		if (this.props.authStatus == 'guest-user') {
			this.openModal(getText('Warning'), getText('This account is limited!'));
		} else if (!getValue(this.props.userInfo, 'character.skills.points.available', 0)) {
			// tbc
			this.openModal(getText('No points available'), getText('No points available'));
		} else {
			apiPost('/user/skills/invest', { skillCode, uuid, points })
			  .catch((e) => {
				  this.setState({
					  isSubmitting: false
				  });
				  this.openModal(getText('Error'), getText('Phone number is not subscribed'));

				  return Observable.empty();
			  })
			  .mergeMap(res => {
				  let resp = Promise.resolve({ error: true });
				  if (res.ok) {
					  resp = res.json();
				  }

				  return Promise.resolve({ resp, statusCode: res.status });
			  })
			  .subscribe((result) => {
				  const { resp, statusCode } = result;

				  resp.then(response => {
					  if (response && !response.error) {
						  this.setState({
							  errorMsg: '',
							  isSubmitting: false
						  });

						  this.props.userLoadInfo();
						  this.props.userRepositoryLoadSkills();
					  } else {
						  this.setState({
							  errorMsg: response.error,
							  isSubmitting: false
						  });
						  let errorMsg = '';
						  switch (statusCode) {
							  case 400:
								  errorMsg = getText('Authentification service bad request, please try again later');
								  break;
							  case 401:
								  errorMsg = getText('Wrong username or password, please try again');
								  break;
							  case 403:
								  errorMsg = getText('Authentification service forbidden, please try again later');
								  break;
							  case 404:
								  errorMsg = getText('Authentification service not available, please try again later');
								  break;
							  case 500:
								  errorMsg = getText('Authentification service internal error, please try again later');
								  break;
							  case 502:
								  errorMsg = getText('Authentification service internal error, please try again later');
								  break;
							  case 503:
								  errorMsg = getText('Authentification service too busy, please try again later');
								  break;
							  default:
								  errorMsg = getText('Wrong username or password, please try again');
						  }
						  this.openModal(getText('Error'), errorMsg);
					  }
				  });
			  });
		}
	};

    render() {
		const { repositorySkills, filtersUi } = this.state;
		const { userInfo, appGetInterfaceLang } = this.props;

        return (
          <SkillsComponent
            imagesLoaded={this.state.imagesLoaded}
            handlePreloadImages={this.handlePreloadImages}
            userInfo={userInfo}
            repositorySkills={repositorySkills}
            filtersUi={filtersUi}
			appGetInterfaceLang={appGetInterfaceLang}
			levelUp={this.levelUp}
          />
        );
    }
}

export default SkillsContainer;