import { createAction } from 'redux-actions';

import * as USER from './types';

const loadMsg = createAction(USER.LOAD_MSG);
const setMsg = createAction(USER.SET_MSG);
const userLoadEvent = createAction(USER.USER_LOAD_EVENT, data => data);
const userLoadGuestEvent = createAction(USER.USER_LOAD_GUEST_EVENT, data => data);
const userLoadInfo = createAction(USER.USER_LOAD_INFO);
const userLoadUpdateInfo = createAction(USER.USER_LOAD_UPDATE_INFO);
const userLoadUpdateOnboarding = createAction(USER.USER_LOAD_UPDATE_ONBOARDING);
const userLoadPoints = createAction(USER.USER_LOAD_POINTS);
const userLoadCredits = createAction(USER.USER_LOAD_CREDITS);
const userLoadBuyCredits = createAction(USER.USER_LOAD_BUY_CREDITS);
const userLoadLeaderBoard = createAction(USER.USER_LOAD_LEADER_BOARD, data => data);
const userRepositoryLoadAchievements = createAction(USER.USER_REPOSITORY_LOAD_ACHIEVEMENTS);
const userRepositoryLoadSkills = createAction(USER.USER_REPOSITORY_LOAD_SKILLS);
const userLoadUnsubscribe = createAction(USER.USER_LOAD_UNSUBSCRIBE);
const userLoadGenerate = createAction(USER.USER_LOAD_GENERATE);
const userLoadSubscribeDirect = createAction(USER.USER_LOAD_SUBSCRIBE_DIRECT);
const userRepositoryLoadBanners = createAction(USER.USER_REPOSITORY_LOAD_BANNERS);
const userRepositoryLoadCreditPackages = createAction(USER.USER_REPOSITORY_LOAD_CREDIT_PACKAGES);
const userRepositoryLoadPrizes = createAction(USER.USER_REPOSITORY_LOAD_PRIZES);
const userRepositoryLoadMap = createAction(USER.USER_REPOSITORY_LOAD_MAP);
const userRepositoryLoadAvatars = createAction(USER.USER_REPOSITORY_LOAD_AVATARS);
const userRepositoryLoadSteps = createAction(USER.USER_REPOSITORY_LOAD_STEPS);
const userLoadNotifications = createAction(USER.USER_LOAD_NOTIFICATIONS);
const userLoadNotificationsConsume = createAction(USER.USER_LOAD_NOTIFICATIONS_CONSUME, data => data);
const userLoadUpdateProfile = createAction(USER.USER_LOAD_UPDATE_PROFILE, data => data);

export {
	loadMsg,
	setMsg,
	userLoadEvent,
	userLoadGuestEvent,
	userLoadInfo,
	userLoadUpdateInfo,
	userLoadPoints,
	userLoadCredits,
	userLoadBuyCredits,
	userLoadLeaderBoard,
	userRepositoryLoadAchievements,
	userRepositoryLoadSkills,
	userLoadUnsubscribe,
	userLoadGenerate,
	userLoadSubscribeDirect,
	userRepositoryLoadBanners,
	userRepositoryLoadCreditPackages,
	userRepositoryLoadPrizes,
	userRepositoryLoadMap,
	userLoadNotifications,
	userLoadNotificationsConsume,
	userRepositoryLoadAvatars,
	userRepositoryLoadSteps,
	userLoadUpdateProfile,
	userLoadUpdateOnboarding,
}
