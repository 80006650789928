import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

import SubheaderComponent from "./SubheaderComponent";

class SubheaderContainer extends Component {

    render() {
        return (
            <SubheaderComponent
              title={this.props.title}
              hasHint={this.props.hasHint}
              backLink={this.props.backLink}
              history={this.props.history}
              handleHint={this.props.handleHint}
              skills={this.props.skills}
              isHint={this.props.isHint}
              hideBack={this.props.hideBack}
              gameReady={this.props.gameReady}
            />
        );
    }
}

export default withRouter(SubheaderContainer);