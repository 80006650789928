import React, {useState} from 'react';
import clsx from "clsx";
import { useGesture } from 'react-use-gesture';
import { useSprings, animated } from 'react-spring';
import { motion } from "framer-motion";
import logger from 'AppUtils/logging';

import BoxShadow from "../../../../../../components/box-shadow/BoxShadow";
import BoxWave from "../../../../../../components/box-wave/BoxWave";

const answerVariants = {
	hidden: {
		y: -20,
		opacity: 0,
	},
	visible: (custom) => ({
		y: 0,
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
			delay: custom * 0.1
		}
	})
}

const QuizArea = (props) => {
	const [springs, setSprings] = useSprings(props.dataSet.answers.length, index => ({ dragWidth: 0, dragWidthRight: 0 }));
	const [directionSwipe, setDirectionSwipe] = useState([]);

	const answerRef = React.useRef(null);

	const handleDragStart = (index) => {

	}

	const handleDrag = (index, delta, down, direction) => {
		if(props.answerChoice && props.answerChoice[props.current].length < 3 && !props.answerChoice[props.current].includes(index)) {
			setSprings(springIndex => {
				if(springIndex === index && direction === -1) {
					return {
						dragWidth:  delta[0], immediate: true
					}
				} else if(springIndex === index && direction === 1) {
					return {
						dragWidthRight:  -delta[0], immediate: true
					}
				}
			})
			let newDirectionSwipe = [...directionSwipe];
			newDirectionSwipe[index] = direction;
			setDirectionSwipe(newDirectionSwipe);

			if(delta[0] > answerRef.current.clientWidth && direction === -1) {
				setSprings(index => ({dragWidth: 0, immediate: true}))
				props.handleClick(index)
			} else if(-delta[0] > answerRef.current.clientWidth && direction === 1) {
				setSprings(index => ({dragWidthRight: 0, immediate: true}))
				props.handleClick(index)
			}
		}
	}

	const handleDragEnd = (index, delta, down, direction) => {
		let newDirectionSwipe = [...directionSwipe];
		newDirectionSwipe[index] = direction;
		setDirectionSwipe(newDirectionSwipe);

		if(delta[0] < answerRef.current.clientWidth && direction === -1) {
			setSprings(index => ({dragWidth: 0, immediate: true}))
		} else if(-delta[0] < answerRef.current.clientWidth && direction === 1) {
			setSprings(index => ({dragWidthRight: 0, immediate: true}))
		}
	}

	const bindDrag = useGesture({
		onDragStart: () => {
			logger.info('drag')
		},
		onDrag: ({ args: [originalIndex, direction], down, delta, velocity }) => {
			logger.info(originalIndex, 'originalIndex')
			handleDrag(originalIndex, delta, down, direction);
		},
		onDragEnd: ({ args: [originalIndex, direction], down, delta, velocity }) => {
			handleDragEnd(originalIndex, delta, down, direction);
		}
	})

	const renderAnswers = () => {
		return springs.map(({dragWidth, dragWidthRight}, i) => {
			return (
			  <motion.div
				className={clsx({
					answer: true,
					selected: props.answerChoice[props.current] && props.answerChoice[props.current].includes(i) && props.touched,
					'selected-right': props.answerChoice[props.current] && props.answerChoice[props.current].includes(i) && props.touched && directionSwipe[i] === 1,
					// correct: props.answerChoice[props.current] && props.answerChoice[props.current].includes(i) && props.status && props.touched && props.validating,
					// incorrect: props.answerChoice[props.current] && props.answerChoice[props.current].includes(i) && !props.status && props.touched && props.validating
				})}
				key={i}
				custom={i+2}
				variants={answerVariants}
				initial="hidden"
				animate="visible"
			  >
				  <button
					ref={answerRef}
					// onClick={(e) => {
					//   props.answerChoice[props.current].length < 3 && !props.answerChoice[props.current].includes(i) ?
					// 	props.handleClick(i)
					// 	:
					// 	e.preventDefault()
					//   }}
				  >
					  {props.answerChoice[props.current] && props.answerChoice[props.current].includes(i) && props.touched && props.validating ?
						<>
							<p>{props.dataSet.answers[i].text[props.lang]}</p>
							<BoxWave isBig={true}><span>{props.dataSet.answers[i].text[props.lang]}</span></BoxWave>
						</>
						:
						<>
							<p>{props.dataSet.answers[i].text[props.lang]}</p>
							<BoxShadow><span>{props.dataSet.answers[i].text[props.lang]}</span></BoxShadow>
						</>
					  }
					  <animated.div
						className="drag-handle"
						{...bindDrag(i, -1)}
						style={{ transform: dragWidth.interpolate(dragWidth => `translate3d(${dragWidth}px,0px,0)`) }}
					  ></animated.div>
					  <animated.div
						className="drag-handle-right"
						{...bindDrag(i, 1)}
						style={{ transform: dragWidthRight.interpolate(dragWidthRight => `translate3d(${dragWidthRight}px,0px,0)`) }}
					  ></animated.div>
					  {dragWidth > 0 ? alert(true) : null}
					  <animated.div
						className="bg-pattern"
						style={{ width: dragWidth.interpolate(dragWidth => `${dragWidth}px`) }}
					  ></animated.div>
					  <animated.div
						className="bg-pattern-right"
						style={{ width: dragWidthRight.interpolate(dragWidthRight => `${dragWidthRight}px`) }}
					  ></animated.div>
				  </button>
			  </motion.div>
			)
		})
	};

	return (
	  <div className="quiz-area" key={props.current}>
		  <motion.h3
			className="question"
			custom={1}
			variants={answerVariants}
			initial="hidden"
			animate="visible"
		  >{props.dataSet.question[props.lang]}</motion.h3>
		  <div className="answer-list">
			  {renderAnswers()}
		  </div>
	  </div>
	);
};

export default QuizArea;