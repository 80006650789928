import React from 'react';
import clsx from 'clsx';
import { motion } from "framer-motion";
import Preload from "react-preload";

import getText from 'AppUtils/language';

import WrapperGame from '../../../subcomponents/wrapper-game/WrapperGame';
import TimerComponent from "../../../../../components/timer/Timer";
import InstructionsGame from "../../../subcomponents/instructions-game/InstructionsGame";
import QuizArea from "./subcomponents/QuizArea";
import ProgressBar from "../../../../../components/progress-bar/ProgressBar";
import LoaderPage from "../../../../../components/loader-page/LoaderPage";

//Images to preload
import bgPattern from '../quiz/subcomponents/img/bg-pattern.png';
import correct from '../../../img/correct.svg';
import incorrect from "../../../img/incorrect.svg";

const gameVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const validIconVariant = {
	hidden: {
		top: '50%',
		left: 0,
		right: 0,
		opacity: 0
	},
	visible: {
		top: '30%',
		opacity: [1, 0],
		transition: {
			type: 'tween',
			duration: 1,
			times: [0, 0.9]
		},
	},
}

const WrongAnswersComponent = (props) => {

	//handle hint
	if(props.runHint && props.availableSkills > 0) {
		props.handleClick(null, true);
	}

	let progress = 0;

	if(props.imagesLoaded && props.challengePlay) {
		progress = (((props.challengePlay.current_round+1)/props.answers.length) * 100).toFixed(3);

		if(props.challengePlay.current_round + 1 === props.challengePlay.total_rounds) {
			progress = 100;
		}
	}

	return (
	  <>
		  <LoaderPage isVisible={props.imagesLoaded && props.challengePlay} />
		  <Preload
			images={[bgPattern]}
			autoResolveDelay={5000}
			// onError={}
			onSuccess={props.handlePreloadImages}
			resolveOnError={true}
			mountChildren={false}
		  >
		  </Preload>
		  {props.imagesLoaded && props.challengePlay && (
			<motion.div
			  className="wrong-answers-component quiz-component content-wrapper"
			  variants={gameVariants}
			  initial="hidden"
			  animate="visible"
			>
				<WrapperGame>
					<TimerComponent timeLeft={props.returnTimeLeft} timeCritical={props.timeCritical} />

					<QuizArea
					  handleClick={props.handleClick}
					  dataSet={props.challengePlay.rounds}
					  answerChoice={props.answerChoice}
					  current={props.challengePlay.current_round}
					  touched={props.touched}
					  lang={props.lang}
					  status={props.answers[props.challengePlay.current_round]}
					  validating={props.validating}
					/>

					<ProgressBar steps={[...props.answers]} progress={progress} total={props.challengePlay.current_round+1} />

					{ props.isHint && props.answers[props.challengePlay.current_round] && props.answers[props.challengePlay.current_round] !== null && props.validating ?
					  <motion.img
						className="corect-icon"
						src={correct}
						style={{height: 90, width: 90, position: "absolute", marginLeft: 'auto', marginRight: 'auto', zIndex: 2}}
						variants={validIconVariant}
						initial="hidden"
						animate="visible"
					  />
					  : null
					}
				</WrapperGame>

				<InstructionsGame
				  lineFirst={props.challengePlay.hint[props.lang]}
				/>
			</motion.div>
		  )}
	  </>
	);
};

export default WrongAnswersComponent;