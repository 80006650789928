import React from 'react';
import clsx from "clsx";

import './BoxShadow.scss';

const BoxShadow = (props) => {
    return (
        <div className={clsx('box-shadow', props.className && `${props.className}`)}>
            {props.children}
        </div>
    );
};

export default BoxShadow;