import React, { useState } from 'react';
import { useSprings, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { motion } from "framer-motion"
import getText from 'AppUtils/language';
import Preload from "react-preload";
import logger from 'AppUtils/logging';

import WrapperGame from '../../../subcomponents/wrapper-game/WrapperGame';
import TimerComponent from "../../../../../components/timer/Timer";
import InstructionsGame from "../../../subcomponents/instructions-game/InstructionsGame";
import ProgressBar from "../../../../../components/progress-bar/ProgressBar";
import LoaderPage from "../../../../../components/loader-page/LoaderPage";

import './SwipeIt.scss';

import iconArrowLeft from './img/icon-arrow-left.png';
import iconArrowRight from './img/icon-arrow-right.png';
import iconHot from './img/icon-hot.png';
import iconCold from './img/icon-cold.png';
import iconCorrect from './img/icon-correct.png';
import iconIncorrect from './img/icon-incorrect.png';
import correct from '../../../img/correct.svg';
import incorrect from '../../../img/incorrect.svg';

const SwipeItComponent = (props) => {

	const gameVariants = {
		hidden: {
			opacity: 0,
		},
		visible: {
			opacity: 1,
			transition: {
				type: 'tween',
				duration: 0.2,
			}
		}
	}

	const validIconVariant = {
		hidden: {
			top: '53%',
			left: props.currentDir === 1 ? "auto" : '10%',
			right: props.currentDir === 1 ? '10%' : "auto",
			opacity: 0
		},
		visible: {
			top: "35%",
			opacity: 1,
			transition: {
				type: 'tween',
				duration: 0.6,
			}
		}
	}

	const validIconCenterVariant = {
		hidden: {
			top: '50%',
			left: 0,
			right: 0,
			opacity: 0
		},
		visible: {
			top: '30%',
			opacity: [1, 0],
			transition: {
				type: 'tween',
				duration: 1,
				times: [0, 0.9]
			},
		},
	}

	const hotColdVariant = {
		hidden: (custom) => ({
			x: custom === 'left' ? "-100%" : "100%",
		}),
		visible: {
			x: 0,
			transition: {
				type: 'tween',
				duration: 0.6,
				delay: 0.2
			}
		}
	}

	let renderCards = false;
	let renderContent = false;
	let iconLeft = '';
	let iconRight = '';
	let progress = 0;
	// let gone = new Set();
	let render = false;


	if(props.imagesLoaded && props.challengePlay) {
		render = true;

		progress = (((props.challengePlay.current_round+1)/props.answers.length) * 100).toFixed(3);

		if(props.challengePlay.current_round + 1 === props.challengePlay.total_rounds) {
			progress = 100;
		}

		let cards = props.challengePlay.rounds.map((card) => {
			return card.image;
		});

		switch(props.challengePlay.category_code) {
			case 'season':
				iconLeft = iconHot;
				iconRight = iconCold;
				break;
			case 'yes_no':
				iconLeft = iconCorrect;
				iconRight = iconIncorrect;
				break;
			default:
		  // code block
		}

		// These two are just helpers, they curate spring data, values that are later being interpolated into css
		let offsetTopBetween = 15;
		if(props.windowHeight && props.windowHeight < 700) {
			offsetTopBetween = 10;
		}
		const to = i => ({ x: 0, y: i * +offsetTopBetween, scale: 1, rot: 0, delay: i * 50 })
		const from = i => ({ x: 0, rot: 0, scale: 0.5, y: -300, opacity: 1 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
		const trans = (r, s) => `perspective(1500px) rotateX(0deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`

		const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
		const [properties, setProperties] = useSprings(props.initialCards, i => ({ ...to(i), from: from(i) })) // Create a bunch of springs using the helpers above
		// Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity

		let totalToAdd = props.challengePlay.total_rounds;
		for(let i = 0; i < props.challengePlay.total_rounds - props.initialCards; i++) {
			totalToAdd--;
			gone.add(totalToAdd)
		}

		const bind = useGesture({
			onDrag: ({ args: [index], down, delta: [xDelta], distance, direction: [xDir], velocity }) => {
				logger.info('drag', index)
				if(props.lastItemValid === null) {
					const trigger = velocity > 0.2 // If you flick hard enough it should trigger the card to fly out
					const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right
					if (!down && trigger) {
						gone.add(index)
						//validate cards
						props.validateCard(index, dir);
						props.handleControl()
					} // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
					setProperties(i => {
						if (index !== i) return // We're only interested in changing spring-data for the current spring
						const isGone = gone.has(index)
						const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
						const rot = down ? xDelta / 10 + (isGone ? dir * 10 * velocity : 0) : 0 // How much the card tilts, flicking it harder makes it rotate faster
						const scale = down ? 1.1 : 1 // Active cards lift up a bit
						return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } }
					})

					// if (!down && gone.size === cards.length) {
					// 	setTimeout(() => {
					// 		props.uiRedirect({
					// 			pathname: '/result-game'
					// 		})
					// 	}, 1000)
					// }
					// if (!down && gone.size === cards.length) setTimeout(() => gone.clear() || setProperties(i => to(i)), 600)
				}
			},
			// onMouseDown: ({ args: [index], down, delta: [xDelta], distance, direction: [xDir] }) => {
			// 	logger.info('cllick')
			// 	const trigger = true // If you flick hard enough it should trigger the card to fly out
			// 	const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right
			// 	if (!down && trigger) {
			// 		gone.add(index)
			// 		props.changeIndex()
			// 	} // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
			// 	setProperties(i => {
			// 		if (index !== i) return // We're only interested in changing spring-data for the current spring
			// 		const isGone = gone.has(index)
			// 		const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
			// 		const rot = down ? xDelta / 10 + (isGone ? dir * 10 * velocity : 0) : 0 // How much the card tilts, flicking it harder makes it rotate faster
			// 		const scale = down ? 1.1 : 1 // Active cards lift up a bit
			// 		return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } }
			// 	})
			// }
		})

		renderCards = () => {
			return properties.map(({ x, y, rot, scale, opacity }, i) => (
			  <animated.div key={i} className="cards-list" style={{ transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`) }}>
				  {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
				  <animated.div {...bind(i)} className={`card-item card-item-${i}`} style={{ transform: interpolate([rot, scale], trans), backgroundImage: `url(${cards[i]})`, opacity: opacity }} />
			  </animated.div>
			))
		}

		renderContent = () => {
			if(render) {
				return (
				  <motion.div
					className="swipe-it-component content-wrapper"
					variants={gameVariants}
					initial="hidden"
					animate="visible"
				  >
					  <WrapperGame>
						  <TimerComponent timeLeft={props.returnTimeLeft} timeCritical={props.timeCritical} />
						  <div className="cards-list-wrapper">
							  {props.challengePlay ? renderCards() : null}
						  </div>

						  <div className="swipe-controls">
							  <div className="wrapper-control-left">
								  <img
									src={iconArrowLeft}
									className="control-left"
									onClick={() => {
										if(!props.preventClick) {
											logger.info('swipe left')
											const trigger = true // If you flick hard enough it should trigger the card to fly out
											const dir = -1 // Direction should either point left or right
											gone.add(props.index)
											props.validateCard(props.index, dir);
											setProperties(i => {
												if (props.index !== i) return // We're only interested in changing spring-data for the current spring
												const x = (200 + window.innerWidth) * dir // When a card is gone it flys out left or right, otherwise goes back to zero
												const rot = 20 * dir // How much the card tilts, flicking it harder makes it rotate faster
												const scale = 1.1 // Active cards lift up a bit
												return { x, rot, scale, delay: undefined, config: { friction: 50, tension: 200 } }
											})
											props.handleControl()
										}
									}}
								  />
							  </div>
							  <div className="wrapper-control-right">
								  <img
									src={iconArrowRight}
									className="control-right"
									onClick={() => {
										if(!props.preventClick) {
											logger.info('swipe right')
											const trigger = true // If you flick hard enough it should trigger the card to fly out
											const dir = 1 // Direction should either point left or right
											gone.add(props.index);
											props.validateCard(props.index, dir);
											setProperties(i => {
												if (props.index !== i) return // We're only interested in changing spring-data for the current spring
												const x = (200 + window.innerWidth) * dir // When a card is gone it flys out left or right, otherwise goes back to zero
												const rot = 20 * dir // How much the card tilts, flicking it harder makes it rotate faster
												const scale = 1.1 // Active cards lift up a bit
												return { x, rot, scale, delay: undefined, config: { friction: 50, tension: 200 } }
											})
											props.handleControl()
										}
									}}
								  />
							  </div>
						  </div>

						  <ProgressBar steps={[...props.answers]} progress={progress} total={props.challengePlay.current_round+1} />
					  </WrapperGame>

					  <motion.img
						src={iconLeft}
						className="icon-control-left"
						custom="left"
						variants={hotColdVariant}
						initial="hidden"
						animate="visible"
						onClick={() => {
							if(!props.preventClick) {
								logger.info('swipe left')
								const trigger = true // If you flick hard enough it should trigger the card to fly out
								const dir = -1 // Direction should either point left or right
								gone.add(props.index)
								props.validateCard(props.index, dir);
								setProperties(i => {
									if (props.index !== i) return // We're only interested in changing spring-data for the current spring
									const x = (200 + window.innerWidth) * dir // When a card is gone it flys out left or right, otherwise goes back to zero
									const rot = 20 * dir // How much the card tilts, flicking it harder makes it rotate faster
									const scale = 1.1 // Active cards lift up a bit
									return { x, rot, scale, delay: undefined, config: { friction: 50, tension: 200 } }
								})
								props.handleControl()
							}
						}}
					  />
					  <motion.img
						src={iconRight}
						className="icon-control-right"
						custom="right"
						variants={hotColdVariant}
						initial="hidden"
						animate="visible"
						onClick={() => {
							if(!props.preventClick) {
								logger.info('swipe right')
								const trigger = true // If you flick hard enough it should trigger the card to fly out
								const dir = 1 // Direction should either point left or right
								gone.add(props.index)
								props.validateCard(props.index, dir);
								setProperties(i => {
									if (props.index !== i) return // We're only interested in changing spring-data for the current spring
									const x = (200 + window.innerWidth) * dir // When a card is gone it flys out left or right, otherwise goes back to zero
									const rot = 20 * dir // How much the card tilts, flicking it harder makes it rotate faster
									const scale = 1.1 // Active cards lift up a bit
									return { x, rot, scale, delay: undefined, config: { friction: 50, tension: 200 } }
								})
								props.handleControl()
							}
						}}
					  />

					  <InstructionsGame
						lineFirst={props.challengePlay.hint[props.lang]}
					  />

					  { props.lastItemValid && props.lastItemValid !== null ?
						<motion.img
						  className="incorect-icon"
						  src={correct}
						  style={{height: props.isHint ? 90 : 60, width: props.isHint ? 90 : 60, position: "absolute", marginLeft: 'auto', marginRight: 'auto'}}
						  variants={props.isHint ? validIconCenterVariant : validIconVariant}
						  initial="hidden"
						  animate="visible"
						/>
						: !props.lastItemValid && props.lastItemValid !== null ?
						  <motion.img
							className="correct-icon"
							src={incorrect}
							style={{height: 60, width: 60, position: "absolute"}}
							variants={validIconVariant}
							initial="hidden"
							animate="visible"
						  />
						  : null
					  }
				  </motion.div>
				)
			}
		}

		//handle hint
		if(props.runHint && props.availableSkills > 0 && props.lastItemValid === null) {
			const dir = null // Direction should either point left or right
			gone.add(props.index)
			props.validateCard(props.index, dir, true);
			setProperties(i => {
				if (props.index !== i) return // We're only interested in changing spring-data for the current spring
				// const x = (200 + window.innerWidth) * dir // When a card is gone it flys out left or right, otherwise goes back to zero
				// const rot = 20 * dir // How much the card tilts, flicking it harder makes it rotate faster
				const y = -300;
				const opacity = 0;
				const scale = 0; // Active cards lift up a bit
				return { y, scale, opacity, delay: undefined, config: { friction: 25, tension: 200 } }
			})
			props.handleControl()
		}
	}

	return (
	  <>
		  <LoaderPage isVisible={props.imagesLoaded && props.challengePlay} />
		  <Preload
			images={[iconHot, iconCold, iconArrowLeft, iconArrowRight, iconCorrect, iconIncorrect]}
			autoResolveDelay={5000}
			// onError={}
			onSuccess={props.handlePreloadImages}
			resolveOnError={true}
			mountChildren={false}
		  >
		  </Preload>
		  {renderContent ? renderContent() : null}
	  </>
	);
};

export default SwipeItComponent;