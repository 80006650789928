import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import isEqual from "react-fast-compare";
import { getValue } from 'AppUtils/objects';
import getText, { HtmlParser } from "AppUtils/language"

import MapComponent from "./MapComponent";
import { userInfo, userRepositoryMap } from "../user/store/selectors";
import { appGetInterfaceLang } from "../../store/selectors";
import { userRepositoryLoadMap } from "../user/store/actions";
import {uiRedirect, uiToggleModal} from "../../store/actions";

const stateToProps = state => ({
	userRepositoryMap: userRepositoryMap(state),
	userInfo: userInfo(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userRepositoryLoadMap: () => dispatch(userRepositoryLoadMap()),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class MapContainer extends Component {

    state = {
        imagesLoaded: false,
		repositoryMap: [],
		filtersUi: {
			isLoading: true
		},
    };

    handlePreloadImages = () => {
        this.setState({
            imagesLoaded: true
        })
    }

    handleStep = (urlParam, status, challengeId) => {
		if((status === 'active' || status === 'unlocked') && this.props.userInfo.eligibility.canPlay && (parseInt(this.props.userInfo.eligibility.credits.purchased) >= 1 || parseInt(this.props.userInfo.eligibility.credits.subscription) >= 1)) {
			this.props.uiRedirect({
				pathname: `/challenge/instructions/${challengeId}`,
				challengeId
			})
		} else if(!this.props.userInfo.eligibility.canPlay) {
			this.openModal(getText('Error'), <HtmlParser html="You are not eligible to participate. <br/> Check you balance and try again" />);
		}
	}

	openModal = (title, message, button, buttonText, button2, button2Text, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, button2, button2Text, callback  } );
	};

	componentDidMount() {
		this.props.userRepositoryLoadMap();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { userRepositoryMap } = this.props;

		if (userRepositoryMap.isLoading == false && userRepositoryMap.list && (this.state.repositoryMap.length == 0 || !isEqual(userRepositoryMap.list, prevProps.userRepositoryMap.list))) {
			this.setState(prevState => ({
				repositoryMap: userRepositoryMap.list,
				filtersUi: {
					...prevState.filtersUi,
					isLoading: false
				},
			}));
		}
	}



    render() {
		const { repositoryMap, filtersUi } = this.state;
		const { userInfo, appGetInterfaceLang } = this.props;

        return (
            <MapComponent
              imagesLoaded={this.state.imagesLoaded}
              handlePreloadImages={this.handlePreloadImages}
              userInfo={userInfo}
              appGetInterfaceLang={appGetInterfaceLang}
              repositoryMap={repositoryMap}
              filtersUi={filtersUi}
			  id={this.props.match.params.id ? this.props.match.params.id : false}
			  handleStep={this.handleStep}
			  isBottom={this.props.isBottom}
            />
        );
    }
}

export default MapContainer;