import Observable from 'Observable';
import {
	put, fork, race, take, takeLatest, all, takeEvery, select, call
} from 'redux-saga/effects';
import { getValue } from 'AppUtils/objects';
import { apiGet, apiPost, apiPut } from "AppUtils/api";
import { getQueryVariable } from 'AppUtils/url';
import noop from 'lodash-es/noop';

import * as USER from './types';
import * as AUTH from '../../auth/store/types';
import { authUuid, authApikey } from "../../auth/store/selectors";
import { userInfo } from "./selectors";


function* onLoadLoginWithToken(action) {
	const token = getValue(action, 'payload.token', '');
	const uuid = yield select(authUuid);

	const retry = 3;
	let apikey = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost('/user/login-via-token', { token, uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && response.apikey) {
				apikey = response.apikey;

			} else {
				msg = response.error;
			}
		});

	if (apikey && apikey != process.env.REACT_APP_GUEST_APIKEY) {
		yield put({ type: USER.USER_LOAD_EVENT, payload: { type: 'LOGIN' } });
	} else {
		yield put({ type: USER.USER_LOAD_GUEST_EVENT, payload: { type: 'LOGIN' } });
	}

	yield put({ type: USER.USER_SET_LOGIN_WITH_TOKEN, payload: { apikey, msg, statusCode }});
}

function* onLoadUserGuestEvent(action) {
	// APP_LAUNCH: Application Launch
	// LOGIN: Login attempt
	// ACTVITIY_START: Session Launch
	// ACTIVITY_END: session end
	// SESSION_END (or GAME_END): Session End
	// APP_CLOSE: Application Close
	// UPD_POINTS: Points Update
	// VIEW_SP: View subscription prompt
	// CLICK_SP: Clicked subscription prompt
	const type = getValue(action, 'payload.type', '');
	const uuid = yield select(authUuid);

	const retry = 3;
	let guestEvent = '';
	let statusCode = '';

	const response = yield apiPost(`/user/guestevent`, { ...action.payload, uuid, type })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response) {
			  guestEvent = type;
		  } else {
			  guestEvent = 'error';
		  }
	  });

	yield put({ type: USER.USER_SET_GUEST_EVENT, payload: { guestEvent, statusCode }});
}

function* onLoadUserEvent(action) {
	// APP_LAUNCH: Application Launch
	// LOGIN: Login attempt
	// ACTVITIY_START: Session Launch
	// ACTIVITY_END: session end
	// SESSION_END (or GAME_END): Session End
	// APP_CLOSE: Application Close
	// UPD_POINTS: Points Update
	// VIEW_SP: View subscription prompt
	// CLICK_SP: Clicked subscription prompt
	const type = getValue(action, 'payload.type', '');
	const uuid = yield select(authUuid);

	const retry = 3;
	let event = '';
	let statusCode = '';

	const response = yield apiPost(`/user/event`, { ...action.payload, uuid, type })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response) {
				event = type;
			} else {
				event = 'error';
			}
		});

	yield put({ type: USER.USER_SET_EVENT, payload: { event, statusCode }});
}

function* onLoadUserInfo(action) {
	const uuid = yield select(authUuid);
	const apikey = getValue(action, 'payload.apikey', '') ? action.payload.apikey : yield select(authApikey);

	const retry = 3;
	let info = '';
	let statusCode = '';
	let expires = '';
	let canPlay = '';
	let msg = '';

	if (!apikey) {
		yield put({
			type: AUTH.AUTH_RESET,
			payload: { status: 'guest' },
		});
	}

	//yield put({ type: USER.USER_LOAD_CREDITS });

	const response = yield apiGet(`/user/info`, { uuid }, { apikey })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && response.eligibility) {
				info = response;
				expires = getValue(response, 'eligibility.expires');
				canPlay = getValue(response, 'eligibility.canPlay');
			} else {
				msg = 'Error';
			}
		});

	if (!msg) {
		//user valid
		// && expires && new Date(expires).getTime() > new Date().getTime()
		if (canPlay) {
			yield put({
				type: AUTH.AUTH_SET,
				payload: { apikey, status: 'valid', statusCode },
			});
		} else {
			yield put({
				type: AUTH.AUTH_SET,
				payload: { apikey, status: 'restricted', statusCode },
			});
		}

		yield put({
			type: USER.USER_SET_INFO,
			payload: { info, statusCode },
		});

		if (!getValue(info, 'settings.onboardingStep') || !getValue(info, 'settings.onboardingSubstep')) {
			yield put({ type: USER.USER_LOAD_UPDATE_INFO, payload: { settings: JSON.stringify({ onboardingStep: 1, onboardingSubstep: 1 }) }});
		}

		if (getValue(info, 'settings.onboardingStep') <= 4 && getValue(info, 'character.level.current') == 2) {
			yield put({ type: USER.USER_LOAD_UPDATE_INFO, payload: { settings: JSON.stringify({ onboardingStep: 5, onboardingSubstep: 1 }) }});
		}
	}
}

function* onLoadUpdateUserInfo(action) {
	const settings = getValue(action, 'payload.settings', '');

	const retry = 3;
	let updateInfo = '';
	let statusCode = '';
	let msg = '';

	const response = yield apiPut(`/user/info`, { settings })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				updateInfo = response;
			} else {
				msg = response.error;
			}
		});

	if (updateInfo) {
		yield put({ type: USER.USER_SET_INFO, payload: { info: updateInfo, statusCode }});

		if (getValue(updateInfo, 'settings.onboardingStep') <= 4 && getValue(updateInfo, 'character.level.current') == 2) {
			yield put({ type: USER.USER_LOAD_UPDATE_INFO, payload: { settings: JSON.stringify({ onboardingStep: 4, onboardingSubstep: 1 }) }});
		}
	}

	yield put({ type: USER.USER_SET_UPDATE_INFO, payload: { updateInfo: msg ? '' : updateInfo, msg, statusCode }});
	yield put({ type: USER.USER_LOAD_INFO });
}

function* onLoadUserPoints() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let points = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/user/points`, { uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				points = response;
			} else {
				msg = response.error;
			}
		});

	yield put({ type: USER.USER_SET_POINTS, payload: { points, msg, statusCode }});
}

function* onLoadUserCredits() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let credits = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/user/credits`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  credits = response;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_SET_CREDITS, payload: { credits, msg, statusCode }});
}

function* onLoadUserBuyCredits() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let buyCredits = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/user/credits/buy`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  buyCredits = response;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_SET_BUY_CREDITS, payload: { buyCredits, msg, statusCode }});
}

function* onLoadLeaderBoard(action) {
	const uuid = yield select(authUuid);
	const offset = getValue(action, 'payload.offset', 0);
	const limit = getValue(action, 'payload.limit', 50);

	const retry = 3;
	let list = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/user/leaderboard`, { uuid, offset, limit })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				list = response.items;
			} else {
				msg = response.error;
			}
		});

	yield put({ type: USER.USER_SET_LEADER_BOARD, payload: { list, msg, statusCode }});
}

function* onLoadUnsubscribe() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let unsubscribe = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/user/unsubscribe`, { uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				unsubscribe = 'done';
			} else {
				unsubscribe = 'error';
			}
		});

	yield put({ type: USER.USER_SET_UNSUBSCRIBE, payload: { unsubscribe, msg, statusCode }});
}

function* onLoadGenerate(action) {
	const uuid = yield select(authUuid);
	const msisdn = getValue(action, 'payload.msisdn', '');

	const retry = 3;
	let generate = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/user/generate`, { uuid, msisdn })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  generate = 'done';
		  } else {
			  generate = response.error;
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_SET_GENERATE, payload: { generate, msg, statusCode }});
}

function* onLoadSubscribeDirect(action) {
	const uuid = yield select(authUuid);
	const msisdn = getValue(action, 'payload.msisdn', '');
	const password = getValue(action, 'payload.password', '');

	const retry = 3;
	let subscribeDirect = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/user/subscribe-direct`, { uuid, msisdn, password })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  subscribeDirect = 'done';
		  } else {
			  subscribeDirect = response.error;
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_SET_SUBSCRIBE_DIRECT, payload: { subscribeDirect, msg, statusCode }});
}

function* onLoadAchievements() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let list = [];
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/repository/achievements`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  list = response.items;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_REPOSITORY_SET_ACHIEVEMENTS, payload: { list, msg, statusCode }});
}

function* onLoadSkills() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let list = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/repository/skills`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  list = response.items;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_REPOSITORY_SET_SKILLS, payload: { list, msg, statusCode }});
}

function* onLoadBanners() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let list = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/repository/banners`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  list = response.items;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_REPOSITORY_SET_BANNERS, payload: { list, msg, statusCode }});
}

function* onLoadCreditServices() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let list = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/repository/credit-packages`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  list = response.items;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_REPOSITORY_SET_CREDIT_PACKAGES, payload: { list, msg, statusCode }});
}

function* onLoadPrizes() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let list = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/repository/prizes`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  list = response.items;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_REPOSITORY_SET_PRIZES, payload: { list, msg, statusCode }});
}

function* onLoadMap() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let list = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/repository/map`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  list = response;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_REPOSITORY_SET_MAP, payload: { list, msg, statusCode }});
}

function* onLoadAvatars() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let list = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/repository/avatars`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  list = response.items;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_REPOSITORY_SET_AVATARS, payload: { list, msg, statusCode }});
}

function* onLoadSteps() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let list = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/repository/onboard-steps`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  list = response.items;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_REPOSITORY_SET_STEPS, payload: { list, msg, statusCode }});
}

function* onLoadNotifications() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let list = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/user/notifications`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  list = response.items;
		  } else {
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_SET_NOTIFICATIONS, payload: { list, msg, statusCode }});
}

function* onLoadNotificationsConsume(action) {
	const uuid = yield select(authUuid);
	const id = getValue(action, 'payload.notificationId', '');
	const callback = getValue(action, 'payload.callback', '') ? action.payload.callback : '';

	const retry = 3;
	let data = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/user/notifications/consume`, { uuid, id })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  data = response;
		  } else {
			  msg = response.error;
		  }
	  });

	if (!msg) {
		yield put({ type: USER.USER_LOAD_NOTIFICATIONS });
		if (callback) {
			yield take(USER.USER_SET_NOTIFICATIONS);
			callback();
		}
	}

	yield put({ type: USER.USER_SET_NOTIFICATIONS_CONSUME, payload: { data, msg, statusCode }});
}

function* onLoadProfile(action) {
	const uuid = yield select(authUuid);
	const info = yield select(userInfo);
	const nickname = getValue(action, 'payload.nickname', '');
	const avatarUrl = getValue(action, 'payload.avatarUrl', '');
	const avatarFile = getValue(action, 'payload.avatarFile', '');

	const retry = 3;
	let data = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/user/profile`, { uuid, nickname, avatarUrl, avatarFile })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  data = response;
		  } else {
			  msg = response.error;
		  }
	  });

	if (!getValue(info, 'settings.onboardingStep') || getValue(info, 'settings.onboardingStep') <= 1) {
		yield put({ type: USER.USER_LOAD_UPDATE_INFO, payload: { settings: JSON.stringify({ onboardingStep: 2, onboardingSubstep: 1 }) }});
	}

	yield put({ type: USER.USER_SET_UPDATE_PROFILE, payload: { data, msg, statusCode }});
}

function* onLoadUpdateOnboarding(action) {
	const info = yield select(userInfo);
	let onboardingStep = getValue(action, 'payload.onboardingStep', '');
	let onboardingSubstep = getValue(action, 'payload.onboardingSubstep', '');

	if (!onboardingStep) {
		onboardingStep = getValue(info, 'settings.onboardingStep', 0);
		onboardingStep++;
	}

	if (!onboardingSubstep) {
		onboardingSubstep = getValue(info, 'settings.onboardingSubstep', 0);
		onboardingSubstep++;
	}

	yield put({ type: USER.USER_LOAD_UPDATE_INFO, payload: { settings: JSON.stringify({ onboardingStep, onboardingSubstep }) }});
}

function* userWatchInitialize() {
	yield takeEvery(USER.USER_LOAD_UPDATE_INFO, onLoadUpdateUserInfo);
	yield takeEvery(USER.USER_LOAD_INFO, onLoadUserInfo);
	yield takeEvery(USER.USER_LOAD_POINTS, onLoadUserPoints);
	yield takeEvery(USER.USER_LOAD_CREDITS, onLoadUserCredits);
	yield takeEvery(USER.USER_LOAD_BUY_CREDITS, onLoadUserBuyCredits);
	yield takeEvery(USER.USER_LOAD_EVENT, onLoadUserEvent);
	yield takeEvery(USER.USER_LOAD_GUEST_EVENT, onLoadUserGuestEvent);
	yield takeEvery(USER.USER_LOAD_LOGIN_WITH_TOKEN, onLoadLoginWithToken);
	yield takeEvery(USER.USER_LOAD_LEADER_BOARD, onLoadLeaderBoard);
	yield takeEvery(USER.USER_LOAD_UNSUBSCRIBE, onLoadUnsubscribe);
	yield takeEvery(USER.USER_LOAD_UNSUBSCRIBE, onLoadGenerate);
	yield takeEvery(USER.USER_LOAD_UNSUBSCRIBE, onLoadSubscribeDirect);
	yield takeEvery(USER.USER_REPOSITORY_LOAD_ACHIEVEMENTS, onLoadAchievements);
	yield takeEvery(USER.USER_REPOSITORY_LOAD_SKILLS, onLoadSkills);
	yield takeEvery(USER.USER_REPOSITORY_LOAD_BANNERS, onLoadBanners);
	yield takeEvery(USER.USER_REPOSITORY_LOAD_CREDIT_PACKAGES, onLoadCreditServices);
	yield takeEvery(USER.USER_REPOSITORY_LOAD_PRIZES, onLoadPrizes);
	yield takeEvery(USER.USER_REPOSITORY_LOAD_MAP, onLoadMap);
	yield takeEvery(USER.USER_REPOSITORY_LOAD_AVATARS, onLoadAvatars);
	yield takeEvery(USER.USER_REPOSITORY_LOAD_STEPS, onLoadSteps);
	yield takeEvery(USER.USER_LOAD_NOTIFICATIONS, onLoadNotifications);
	yield takeEvery(USER.USER_LOAD_NOTIFICATIONS_CONSUME, onLoadNotificationsConsume);
	yield takeEvery(USER.USER_LOAD_UPDATE_PROFILE, onLoadProfile);
	yield takeEvery(USER.USER_LOAD_UPDATE_ONBOARDING, onLoadUpdateOnboarding);
}


export default function* sagas() {
	yield fork(userWatchInitialize);
}
