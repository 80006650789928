import React, {Component} from 'react';
import clsx from "clsx";
import isEqual from "react-fast-compare";

import {validate} from "../../utils/form";
import File from "./File";

import './FormField.scss';

class FormField extends Component {

	state = {
		field: {
			...this.props.element
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (!isEqual(props.element, state.field)) {
			return { field: props.element };
		}
		return null;
	}

	handleChange = (element) => {
		const newElement = {
			...this.state.field
		}
		newElement.value = element.event.target.value;

		let validData = validate(newElement);
		newElement.valid = validData[0];
		newElement.validationMessage = validData[1];

		newElement.touched = element.blur;

		this.setState({
			field: newElement
		})

		if(this.props.updateField) {
			this.props.updateField(newElement.config.name, newElement)
		}
	}

	renderTemplate = () => {
		let formTemplate = null;

		switch(this.state.field.element) {
			case('input'):
				if(this.state.field.config.type === 'file') {
					formTemplate = (
					  <File
						id={this.state.field.config.id}
						name={this.state.field.config.name}
						type={this.state.field.config.type}
						placeholder={this.state.field.config.placeholder}
						required={this.state.field.validation.required}
						value={this.state.field.value}
						onChange={this.handleChange}
						onBlur={this.handleChange}
						disabled={this.props.disabled ? this.props.disabled : false}
					  />
					);
				} else {
					formTemplate = (
					  <input
						className={clsx('box-shadow default-input', this.state.field.validationMessage && "error-input")}
						id={this.state.field.config.id}
						name={this.state.field.config.name}
						type={this.state.field.config.type}
						placeholder={this.state.field.config.placeholder}
						required={this.state.field.validation.required}
						value={this.state.field.value}
						onChange={(event) => this.handleChange({event, blur: false})}
						onBlur={(event) => this.handleChange({event, blur: true})}
						disabled={this.props.disabled ? this.props.disabled : false}
						maxLength={this.state.field.validation.maxLength}
					  />
					);
				}
				break;
			default:
				formTemplate = null;
		}

		return formTemplate;
	}

	render() {
		return (
		  <div className={`form-field ${this.state.touched ? (this.state.valid ? 'valid' : 'invalid') : ''}`}>
			  {this.state.field.config.label && <label>{this.state.field.config.label}</label>}
			  {this.renderTemplate()}
			  {this.state.field.validationMessage &&
			  <p className="validation-message">{this.state.field.validationMessage}</p>
			  }
		  </div>
		);
	}
}

export default FormField;