import React, {Component} from 'react';

import LineWordsInstructionsComponent from "./LineWordsInstructionsComponent";

class LineWordsInstructionsContainer extends Component {

    state = {

    };


    render() {
        return (
            <LineWordsInstructionsComponent
              imagesLoaded={this.props.imagesLoaded}
              handlePreloadImages={this.props.handlePreloadImages}
              challengePlay={this.props.challengePlay}
              handleStart={this.props.handleStart}
              lang={this.props.appGetInterfaceLang}
            />
        );
    }
}

export default LineWordsInstructionsContainer;