import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import getText from 'AppUtils/language';

import ProfileComponent from "./ProfileComponent";
import { userInfo } from "../user/store/selectors";
import {uiToggleModal} from "../../store/actions";
import {userLoadUnsubscribe} from "../user/store/actions";
import {appGetConfig} from "../../store/selectors";

const stateToProps = state => ({
	userInfo: userInfo(state),
    appGetConfig: appGetConfig(state),
});

const actionsToProps = dispatch => ({
    uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
    userLoadUnsubscribe: () => dispatch(userLoadUnsubscribe()),
});

@connect(stateToProps, actionsToProps)
class ProfileContainer extends Component {

    state = {
        imagesLoaded: false,
    };

    handlePreloadImages = () => {
        this.setState({
            imagesLoaded: true
        });
    }

    openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
        this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2 });
    };

    handleUnsubscribe = () => {
        this.openModal(getText('Unsubscribe'), getText('Please send a sms to 1234 in order to unsubscribe.'), true, getText('Ok'), false, false, false, () => {
            this.props.uiToggleModal({}, { toggle: false }, {});
        });
    }

    render() {
        return (
            <ProfileComponent
              imagesLoaded={this.state.imagesLoaded}
              handlePreloadImages={this.handlePreloadImages}
              userInfo={this.props.userInfo}
              handleUnsubscribe={this.handleUnsubscribe}
              disableBuyCredits={this.props.appGetConfig.features.disableBuyCredits}
            />
        );
    }
}

export default ProfileContainer;