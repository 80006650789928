import React from 'react';
import getText from 'AppUtils/language';
import ReactFitText from 'react-fittext';

import WrapperGame from '../../subcomponents/wrapper-game/WrapperGame';
import InstructionsGame from "../../subcomponents/instructions-game/InstructionsGame";
import Button from "../../../../components/button/Button";
import LoaderPage from "../../../../components/loader-page/LoaderPage";

import videoRaffle from './video/raffle_video.mp4';
import congrats from '../img/congrats.png';

import './LuckyCage.scss';
import {motion} from "framer-motion";

const bonusVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const instructionsVariants = {
    hidden: {
        opacity: 0,
        transition: {
            type: 'tween',
            duration: 0.4,
            delay: 1.1
        }
    },
    visible: {
        opacity: 1,
    }
}

const buttonVariants = {
    hidden: {
        y: '100vh'
    },
    visible: {
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.6,
            delay: 1.5
        }
    }
}

const congratsVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.4,
            delay: 2.2
        }
    }
}

const prizeTextVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.4,
            delay: 2
        }
    }
}

const prizeVariants = {
    hidden: {
        y: '2vh',
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.4,
            delay: 1.9
        }
    }
}

const LuckyCageComponent = (props) => {
    return (
         <>
             <LoaderPage isVisible={props.gamesStartBonus}/>

             {props.gamesStartBonus && (
               <motion.div
                 className="bonus-kit-component content-wrapper"
                 variants={bonusVariants}
                 initial="hidden"
                 animate="visible"
               >
                   <div className="prize">
                       <motion.div
                         className="congrats"
                         variants={congratsVariants}
                         initial="hidden"
                         animate={props.showPrize ? 'visible' : 'hidden'}
                       >
                           <div className="text">
                               <ReactFitText>
                                   <p>Congrats!</p>
                               </ReactFitText>
                           </div>
                           <img src={congrats} alt=""/>
                       </motion.div>
                       <motion.span
                         variants={prizeTextVariants}
                         initial="hidden"
                         animate={props.showPrize ? 'visible' : 'hidden'}
                       >{getText('You have won')}:</motion.span>
                       <motion.p
                         variants={prizeVariants}
                         initial="hidden"
                         animate={props.showPrize ? 'visible' : 'hidden'}
                       >
                           {props.gamesStartBonus.prize_type === 'points' ?
                             `${props.gamesStartBonus.points} ${getText('points xp')}`
                             :
                             (props.gamesStartBonus.prize ? props.gamesStartBonus.prize.name[props.lang] : null)
                           }
                       </motion.p>
                   </div>
                   <div className="video-wrapper">
                       <div className="video-inner">
                           <video controls={false} autoPlay={true} ref={props.handleVideoMounted} className="video-raffle" onClick={() => props.shakeEventDidOccur()} playsInline>
                               <source src={videoRaffle} type="video/mp4" />
                               {getText('Your browser does not support the video tag.')}
                           </video>
                       </div>
                   </div>
                   <motion.div
                     variants={instructionsVariants}
                     initial="visible"
                     animate={props.showPrize ? 'hidden' : ''}
                   >
                       <InstructionsGame
                         lineFirst={props.gamesStartBonus.bonus_game ? (props.shakeSupport ? props.gamesStartBonus.bonus_game.hint[props.lang].replace('$action', getText('Shake')) : props.gamesStartBonus.bonus_game.hint[props.lang].replace('$action', getText('Tap'))) : false }
                       />
                   </motion.div>
                   <motion.div
                     className="btn-redeem"
                     variants={buttonVariants}
                     initial="hidden"
                     animate={props.showPrize ? 'visible' : 'hidden'}
                     onClick={() => props.handleRedeem()}
                   >
                       <Button title={getText('REDEEM')}/>
                   </motion.div>
               </motion.div>
             )}
         </>
    );
};

export default LuckyCageComponent;