import React from 'react';
import {Link} from "react-router-dom";
import { motion } from "framer-motion";
import getText from 'AppUtils/language';

import PromptContainer from "../subcomponents/prompt/PromptContainer";
import TextComponent from "../subcomponents/text/TextComponent";

import arrowLeft from "../../../../../layout/header/subcomponents/subheader/img/arrow-left.png";
import prizeBmwPng from '../../../main/img/prize-bmw.png';

import './Signup.scss';

const prizeVariants = {
    hidden: {
        scale: 0,
        opacity: 0,
    },
    visible: {
        scale: 1,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2
        }
    }
}

const SignupComponent = (props) => {
    return (
        <div className="signup-component small-form-component auth-component">
            <div className="form-container">
                <div className="content-wrapper-small">
                    <div className="page-title">
                        <Link className="back-btn" to={{ pathname: '/signin' }}><img src={arrowLeft} alt=""/></Link>
                        <h3>{getText('Create your account')}</h3>
                    </div>

                    {props.type === 'direct' ?
                      <PromptContainer direct={true} prefix={props.prefix} appGetConfig={props.appGetConfig} /> :
                      props.type === 'prompt' ?
                        <PromptContainer prefix={props.prefix} appGetConfig={props.appGetConfig} />
                        :
                      <TextComponent />
                    }
                </div>
            </div>

            <motion.div
              className="prize-wrapper"
              variants={prizeVariants}
              initial="hidden"
              animate="visible"
            >
                <img
                  className="prize-bg"
                  src={prizeBmwPng}
                  alt=""
                />
                <img
                  className="prize"
                  src={props.prize?.image}
                  alt=""
                />
            </motion.div>
        </div>
    );
};

export default SignupComponent;