import React from 'react';
import {Link} from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { motion } from "framer-motion";
import Preload from "react-preload";
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';
import moment from 'moment';
import noop from 'lodash-es/noop';

import LoaderPage from "../../components/loader-page/LoaderPage";
import BoxShadow from "../../components/box-shadow/BoxShadow";
import BoxWave from "../../components/box-wave/BoxWave";

import 'react-tabs/style/react-tabs.css';
import './Achievements.scss';

const achievementsVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const listVariants = {
    hidden: {
        y: -40,
        opacity: 0,
    },
    visible: (custom) => ({
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.3,
            delay: custom * 0.1
        }
    })
}

const AchievementsComponent = (props) => {
	const userInfo  = getValue(props, 'userInfo.character');

    let myAchievements = [];
    let achievementsIds = [];
    if (userInfo.achievements && props.repositoryAchievements.length > 0) {
		for (let i in userInfo.achievements) {
		    const fullAchievement = props.repositoryAchievements.find((ach) => ach.id == userInfo.achievements[i].id);

		    if (fullAchievement) {
				myAchievements.push({ ...fullAchievement, ...userInfo.achievements[i]});
				achievementsIds.push(userInfo.achievements[i].id);
            }
		}
    }

    return (
        <>
            <LoaderPage isVisible={props.imagesLoaded} />
            <Preload
              images={[]}
              autoResolveDelay={5000}
              // onError={}
              onSuccess={props.handlePreloadImages}
              resolveOnError={true}
              mountChildren={false}
            >
            </Preload>

            {props.imagesLoaded && (
              <motion.div
                className="achievments-component"
                variants={achievementsVariants}
                initial="hidden"
                animate="visible"
              >
                  <div className="content-wrapper-small">
                      <Tabs selectedIndex={props.tabIndex} onSelect={noop} className="custom-tabs" selectedTabClassName="selected-tab">
                          <TabList className="tabs-nav">
                              <Tab className="tab-title">
                                  <Link to={`/achievements/unlocked`}>
                                      <BoxWave>
                                          {getText('Unlocked')}
                                      </BoxWave>
                                  </Link>
                              </Tab>
                              <Tab className="tab-title">
                                  <Link to={`/achievements/locked`}>
                                      <BoxWave>
                                          {getText('Locked')}
                                      </BoxWave>
                                  </Link>
                              </Tab>
                          </TabList>

                          <TabPanel>
                              <div className="achievements-list">
								  {myAchievements.length > 0 && myAchievements.map((item, i) => {
									  return (<motion.div
                                        key={item.id}
                                        className="achievement"
                                        variants={listVariants}
                                        custom={i + 2}
                                        initial="hidden"
                                        animate="visible"
                                      >
                                          <Link to={`/achievements-details/${item.id}`}>
                                              <BoxShadow>
                                                  <img src={item.image} alt=""/>
                                                  <p className="title-achievement">{item.name[props.appGetInterfaceLang]}</p>
                                                  <p className="date-achievement">{moment(item.unlockDate).format('D MMM')}</p>
                                              </BoxShadow>
                                          </Link>
                                      </motion.div>);
								  })}
                              </div>
                          </TabPanel>
                          <TabPanel>
                              <div className="achievements-list">
                              {props.repositoryAchievements.length > 0 && props.repositoryAchievements.map((item, i) => {

                                  if (achievementsIds.includes(item.id)) {
                                      // if the achievement was unlocked remove it from this list
                                      return;
                                  }

                                  return (<motion.div
                                    key={item.id}
                                    className="achievement"
                                    variants={listVariants}
                                    custom={i + 2}
                                    initial="hidden"
                                    animate="visible"
                                  >
                                      <Link to={`/achievements-details/${item.id}`}>
                                          <BoxShadow>
                                              <img src={item.image} alt=""/>
                                              <p className="title-achievement">{item.name[props.appGetInterfaceLang]}</p>
                                          </BoxShadow>
                                      </Link>
                                  </motion.div>);
                              })}
                              </div>
                          </TabPanel>
                      </Tabs>
                  </div>
              </motion.div>
            )}
        </>
    );
};

export default AchievementsComponent;