import React, {Component, createRef} from 'react';
import Observable from 'Observable';

import connect from 'react-redux/es/connect/connect';
import clsx from "clsx";
import { motion } from "framer-motion"
import {apiPost} from "../utils/api";
import {detectScrollBottom} from "../utils/detect-scroll";

import HeaderContainer from "./header/HeaderContainer";
import FooterContainer from "./footer/FooterContainer";
import MenuContainer from "./menu/MenuContainer";

import {uiRedirect} from "../store/actions";
import {uiGetSection} from "../store/selectors";

const layoutVariants = {
	visible: {
		x: 0,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	},
	hidden: {
		x: '100%',
		transition: {
			type: 'tween',
			duration: 0.2
		}
	},
}

const stateToProps = state => ({
	uiGetSection: uiGetSection(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
});

@connect(stateToProps, actionsToProps)
class Layout extends Component {

	constructor(props) {
		super(props);

		this.state = {
			title: this.props.title,
			playId: false,
			runHint: false,
			isBottom: false,
			availableSkills: 0,
			isHint: false,
			gameReady: false
		}

		this.mainRef = createRef();
	}

	getTitle = (title) => {
		this.setState({
			title: title
		})
	}

	generate = () => {
		const uuid = this.props.authUuid;

		apiPost('/bonus-games/generate', { uuid })
		  .catch((e) => {
			  this.setState({
				  isSubmitting: false
			  });

			  return Observable.empty();
		  })
		  .mergeMap(res => {
			  let resp = Promise.resolve({ error: true });
			  if (res.ok) {
				  resp = res.json();
			  }

			  return Promise.resolve({ resp, statusCode: res.status });
		  })
		  .subscribe((result) => {
			  const { resp, statusCode } = result;

			  resp.then(response => {
				  if (response && !response.error) {
					  // playId: "5f8f2b816c2a9206f32d4a03"
					  // type: "balloons"
					  this.setState({
						  playId: response.playId
					  })
				  }
			  });
		  });
	};

	handleScrollBottom = () => {
		this.setState({
			isBottom: detectScrollBottom(this.mainRef)
		})
	}

	handleHint = (canStart, current = false) => {
		this.setState({
			runHint: canStart,
			isHint: current,
		});
	}

	checkSkills = (availableSkills) => {
		this.setState({
			availableSkills
		})
	}

	checkGameReady = (value) => {
		this.setState({
			gameReady: value
		})
	}

	componentDidMount() {
		if(this.props.uiGetSection === 'map') {
			this.mainRef.current.addEventListener('scroll', this.handleScrollBottom);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.title !== this.props.title && this.props.title) {
			this.setState({
				title: this.props.title
			})
		}

		if(this.state.playId && !this.props.dnd) {
			this.props.uiRedirect({
				pathname: '/bonus-challenge/instructions',
				state: {
					playId: this.state.playId,
				}
			})
		}

		if(prevProps.uiGetSection !== this.props.uiGetSection && this.props.uiGetSection === 'map') {
			this.mainRef.current.addEventListener('scroll', this.handleScrollBottom);
		}
	}

	componentWillUnmount() {
		this.mainRef.current.removeEventListener('scroll', this.handleScrollBottom);
	}

	render() {
		return (
		  <>
			  <MenuContainer generate={this.generate} showMenu={this.props.showMenu} toggleMenu={this.props.toggleMenu}/>
			  <motion.div
				className="layout-inner"
				variants={layoutVariants}
				initial="visible"
				animate={this.props.showMenu ? "hidden" : "visible"}
			  >
				  <HeaderContainer
					hasStats={this.props.hasStats}
					hasSubheader={this.props.hasSubheader}
					hasHint={this.props.hasHint}
					title={this.state.title}
					toggleMenu={this.props.toggleMenu}
					backLink={this.props.backLink}
					gameType={this.props.gameType}
					handleHint={this.handleHint}
					isHint={this.state.isHint}
					gameReady={this.state.gameReady}
					hideBack={this.props.hideBack}
				  />
				  <div
					ref={this.mainRef}
					className={clsx('main', this.props.hasFooter && 'has-footer')}
					key={this.props.title}
				  >
					  {React.cloneElement(this.props.children, {
					  	getTitle: this.getTitle,
						  mainRef: this.mainRef,
						  playId: this.state.playId,
						  runHint: this.state.runHint,
						  handleHint: this.handleHint,
						  isBottom: this.state.isBottom,
						  checkGameReady: this.checkGameReady
					  })}
				  </div>
				  {this.props.hasFooter && <FooterContainer/>}
			  </motion.div>
		  </>
		);
	}
}

export default Layout;