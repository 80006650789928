import React from 'react';
import {Link} from "react-router-dom";
import clsx from "clsx";

import './BuyButton.scss';

const BuyButton = (props) => {
    return (
        <Link {...props} to={props.link} className={clsx('buy-button', props.className && `${props.className}`)}>
            {props.title}
        </Link>
    );
};

export default BuyButton;