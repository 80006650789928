import React from 'react';
import { motion } from "framer-motion";
import Preload from "react-preload";
import getText from 'AppUtils/language';
import Button from "../../../../../../components/button/Button";

import globy from '../../../../../home/img/globy.svg';

//Images to preload
import instructionsImage from './img/logo-linewords.png';

const InstructionsVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.2,
        }
    }
}

const buttonVariants = {
    hidden: {
        y: '100vh'
    },
    visible: {
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.6,
            delay: 0.2
        }
    }
}

const earthVariants = {
    hidden: {
        x: '100vw'
    },
    visible: {
        x: 0,
        transition: {
            type: 'tween',
            duration: 0.6,
            delay: 0.2
        }
    }
}

const dialogVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.6,
            delay: 0.2
        }
    }
}

const gameIconVariants = {
    hidden: {
        scale: 0,
        rotate: '2000deg',
    },
    visible: {
        scale: 1,
        rotate: '0deg',
        transition: {
            type: 'tween',
            duration: 1.2,
            delay: 0.2
        }
    }
}

const LineWordsInstructionsComponent = (props) => {
    return (
        <>
            <Preload
              images={[instructionsImage, globy]}
              autoResolveDelay={5000}
              // onError={}
              onSuccess={props.handlePreloadImages}
              resolveOnError={true}
              mountChildren={false}
            >
            </Preload>

            {props.imagesLoaded && props.challengePlay && (
              <motion.div className="line-words-instructions-component game-instructions">
                  <motion.div
                    className="instructions-image"
                    variants={InstructionsVariants}
                    initial="hidden"
                    animate="visible"
                  >
                      <motion.img
                        src={instructionsImage}
                        alt=""
                        variants={gameIconVariants}
                        initial="hidden"
                        animate={props.imagesLoaded ? 'visible' : 'hidden'}
                      />
                  </motion.div>

                  <div className="instructions-globy">
                      <motion.div
                        className="instructions-message"
                        variants={dialogVariants}
                        initial="hidden"
                        animate={props.imagesLoaded ? 'visible' : 'hidden'}
                      >
                          <p>{props.challengePlay.description[props.lang]}</p>
                      </motion.div>
                      <motion.img
                        className="globy"
                        src={globy} alt=""
                        variants={earthVariants}
                        initial="hidden"
                        animate={props.imagesLoaded ? 'visible' : 'hidden'}
                      />
                  </div>

                  <motion.div
                    className="cta"
                    variants={buttonVariants}
                    initial="hidden"
                    animate={props.imagesLoaded ? 'visible' : 'hidden'}
                    onClick={() => props.handleStart()}
                  >
                      <Button title={getText('START')} slim={true}/>
                  </motion.div>
              </motion.div>
            )}
        </>
    );
};

export default LineWordsInstructionsComponent;