import React, {Component} from 'react';

import WrongAnswersInstructionsComponent from "./WrongAnswersInstructionsComponent";

class WrongAnswersInstructionsContainer extends Component {

    state = {

    };

    render() {
        return (
            <WrongAnswersInstructionsComponent
              imagesLoaded={this.props.imagesLoaded}
              handlePreloadImages={this.props.handlePreloadImages}
              challengePlay={this.props.challengePlay}
              handleStart={this.props.handleStart}
              lang={this.props.appGetInterfaceLang}
            />
        );
    }
}

export default WrongAnswersInstructionsContainer;