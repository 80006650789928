import Observable from 'Observable';
import ls from 'local-storage';
import * as AUTH from '../containers/auth/store/types';
import { param } from 'AppUtils/objects';

let store;
let apikey = null;
let uuid = null;
const REQUESTS_STACK = new Map();

const host = process.env.REACT_APP_API_HOST;
const path = process.env.REACT_APP_API_PATH;

try {
	apikey = ls.get('apikey');
    uuid = ls.get('uuid');
} catch (e) {}


const middleware = store => next => (action) => {
    const result = next(action);
    if (action.type == AUTH.AUTH_SET) {
        apikey = action.payload.apikey;
    }

    return result;
};
middleware.register = (_store) => { store = _store; };


function encodeLongQueryData(obj, prefix) {
    const str = [];

    let p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            const k = prefix ? `${prefix}[${p}]` : p;


            const v = obj[p];
            str.push((v !== null && typeof v === 'object')
                ? encodeLongQueryData(v, k)
                : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
        }
    }
    return str.join('&');
}

const adjustUrl = function (url) {
    return `${host}${path}${url}`;
};

const adjustUrlData = function (url, data = {}, options = {}) {
    if (Object.keys(data).length) {
        const query = encodeLongQueryData(data);
        if (query) url = `${url}?${query}`;
    }

    let basePath = options.basePath ? options.basePath : '/api';

   // if (Object.entries(options).length === 0 && options.constructor === Object) {
        basePath = `${host}${path}`;
    //}

    return `${basePath}${url}`;
};

const getHeaders = (options = {}) => {
	let headers = {
		'Accept': 'application/json',
	};

	if (!options.emptyHeaders) {
		headers = {
			'Content-Type': 'application/x-www-form-urlencoded',
			//'X-Locale': __FC__.language,
			'Accept': 'application/json',
		};
	}


    if (!options.disableToken) {
		if (options.apikey) headers['Authorization'] = (options.apikey ? `Bearer ${options.apikey}` : null);
		else if (apikey) headers['Authorization'] = (apikey ? `Bearer ${apikey}` : null);
	}

    return headers;
};

const apiGet = (url, data = {}, options = {}) => {
    // Network request cancellation should only be done on `GET` !
    const URI = adjustUrlData(url, data, options);
    const reqController = new AbortController();
    const prevReq = REQUESTS_STACK.get(URI);

    if (prevReq) {
    	prevReq.abort();
	    console.info(
		    `%c__REQUEST__ Cancelled for URI: %c${URI}`,
		    'color: blue',
		    'font-weight: bold',
	    );
    }

    REQUESTS_STACK.set(URI, reqController);

    const result = Observable.from(fetch(URI, {
        method: 'GET',
        //mode: 'no-cors',
        responseType: 'text', // force text because older browser version of Safari do not support json as response type
        headers: getHeaders(options),
      }));

    return result
      .catch(handleError)
      .finally(() => {
        if (REQUESTS_STACK.has(URI)) {
          REQUESTS_STACK.delete(URI);
        }
      });
};

const apiGetPlain = (url, data = {}, options = {}) => {
    const result = Observable.fromPromise(fetch(adjustUrlData(url, data, options), {
        method: 'GET',
		mode: 'no-cors',
        responseType: 'text',
        headers: getHeaders(options),
    }));

    return result
        .map((e) => { return e.response; })
        .catch(handleError);
};

const apiCachedGet = (url) => {
    const result = Observable.fromPromise(fetch(url, {
        method: 'GET',
		mode: 'no-cors',
        headers: getHeaders(),
		cache: "force-cache",
    }));

    return result
        .map(e => e.response)
        .catch(handleError);
};

const apiDelete = (url, data = {}) => {
    const result = Observable.from(fetch(adjustUrl(url), {
        method: 'DELETE',
		mode: 'no-cors',
        headers: getHeaders(),
    }));

    return result
        .map(e => e.response)
        .catch(handleError);
};

// const apiPut = (url, data = {}) => {
//     const result = Observable.fromPromise(fetch(adjustUrl(url), {
//         method: 'PUT',
// 		mode: 'no-cors',
//         headers: getHeaders(),
//         body: JSON.stringify(data),
//     }));
//
//     return result
//         .map(e => e.response)
//         .catch(handleError);
// };

const apiPut = (url, data = {}, options = {}) => {
	const result = Observable
		.from(fetch(adjustUrl(url), {
			method: 'PUT',
			headers: getHeaders(options),
			body: param(data),
		}));

	return result.catch(handleError);
};

const apiPost = (url, data = {}, options = {}) => {
	const result = Observable
	  .from(fetch(adjustUrl(url), {
		  method: 'POST',
		  headers: getHeaders(options),
		  body: param(data),
	  }));

    return result.catch(handleError);
};

const apiPatch = (url, data = {}) => {
    const result = Observable.fromPromise(fetch(adjustUrl(url), {
        method: 'PATCH',
		mode: 'no-cors',
        headers: getHeaders(),
        body: JSON.stringify(data),
    }));

    return result
        .map(e => e.response)
        .catch(handleError);
};

function apiPostFile(url, data = {}) {
    const result = Observable.fromPromise(fetch(adjustUrl(url), {
        method: 'POST',
        headers: getHeaders({ emptyHeaders: true }),
        body: data,
    }));

    return result.mergeMap(response => response.json()).catch(handleError);
}

const fetchPost = (url, data = {}) => {
    return fetch(adjustUrl(url), {
        method: 'POST',
        body: JSON.stringify(data),
    });
};

const handleError = (err) => {
    return Observable.throw(err);
};


export {
    apiGet, apiGetPlain, apiCachedGet, apiDelete, apiPut, apiPost, fetchPost, apiPatch, apiPostFile, middleware,
};
