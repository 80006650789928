import { createSelector } from 'reselect';
import { getValue } from 'AppUtils/objects';

const gamesStartBonus = state => state.games.startBonus;
const gamesFinishBonus = state => state.games.finishBonus;
const gamesStatusCode = state => state.games.statusCode;
const gamesNotificationId = state => state.games.notificationId;
const gamesMsg = state => state.games.msg;
const gamesSocket = state => state.games.socket;
const gamesSocketCall = state => state.games.socket.call;
const gamesSocketChallengeId = state => getValue(state, 'games.socket.data.challengePlay.challenge_id', '');
const gamesSocketChallengePlayId = state => getValue(state, 'games.socket.data.challengePlay.id', '');

export {
	gamesStartBonus,
	gamesFinishBonus,
	gamesStatusCode,
	gamesMsg,
	gamesSocket,
	gamesSocketChallengeId,
	gamesSocketChallengePlayId,
	gamesSocketCall,
	gamesNotificationId,
};
