import React from 'react';
import { motion } from "framer-motion";
import getText from 'AppUtils/language';
import { getValue } from 'AppUtils/objects';

import arrowLeft from "./img/arrow-left.png";

import './Subheader.scss';
import {Link} from "react-router-dom";

const pageTitleVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.4,
            // delay: 0.6
        }
    }
}

const hintVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.4,
            // delay: 0.6
        }
    }
}

const SubheaderComponent = (props) => {
    let showBack = !props.hideBack && getValue(props, 'history.length') > 2;

    return (
        <div className="subheader">
            {showBack && <Link
              onClick={!props.backLink && props.history ? (e) => {e.preventDefault(); props.history.goBack();} : () => {} }
              to={props.backLink ? props.backLink : "/"}
              className="back"
            ><img src={arrowLeft} alt=""/>{getText('BACK')}</Link>}
            <motion.h3
              className="title-game"
              variants={pageTitleVariants}
              initial="hidden"
              animate="visible"
            >{props.title ? props.title : ''}</motion.h3>
            { props.hasHint && props.skills.available > 0 && props.gameReady ?
              <motion.div
                className="hint"
                variants={hintVariants}
                initial="hidden"
                animate="visible"
                onClick={() => {
                    if(!props.isHint) {
                        props.handleHint(true, true)
                    }
                }}
              >
                  <p>{getText('Hint')}</p>
                  <p className="hint-icon">?</p>
              </motion.div>
              : null
            }
        </div>
    );
};

export default SubheaderComponent;