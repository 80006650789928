import React from 'react';

import './Text.scss';
import getText from 'AppUtils/language';

const TextComponent = () => {
	return (
	  <div className="signup-text-component">
		  <p className="instructions-auth">{getText('To subscribe, send OK to 85875')}</p>
	  </div>
	);
};

export default TextComponent;