import React, {Component, createRef} from 'react';
import connect from 'react-redux/es/connect/connect';
import Timer from 'react-compound-timer'
import isEqual from "lodash-es/isEqual";

import LineWordsComponent from "./LineWordsComponent";
import logger from 'AppUtils/logging';

import bgLine from './img/bg-line.png';
import {uiRedirect, uiToggleModal} from "../../../../../store/actions";
import {gamesSocket} from "../../../store/selectors";
import {appGetInterfaceLang} from "../../../../../store/selectors";
import {
	socketLoadCloseConnection,
	socketLoadFinishChallenge,
	socketLoadStartChallenge,
	socketLoadUpdateChallenge
} from "../../../store/actions";
import getText from "../../../../../utils/language";
import {userInfo} from "../../../../user/store/selectors";
import {userLoadInfo} from "../../../../user/store/actions";

const stateToProps = state => ({
	gamesSocket: gamesSocket(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
	userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadInfo: () => dispatch(userLoadInfo()),
	socketLoadUpdateChallenge: (data) => dispatch(socketLoadUpdateChallenge(data)),
	socketLoadStartChallenge: () => dispatch(socketLoadStartChallenge()),
	socketLoadFinishChallenge: () => dispatch(socketLoadFinishChallenge()),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	socketLoadCloseConnection: () => dispatch(socketLoadCloseConnection()),
});

@connect(stateToProps, actionsToProps)
class LineWordsContainer extends Component {
	constructor(props) {
		super(props);

		this.randomTemplateVal = this.getRandomInt(1, 5);

		this.state = {
			challengePlay: false,
			imagesLoaded: false,
			wordsTemplate: this.randomTemplateVal,
			scrollable: true,
			startPosition: {x: -1000, y: -1000},
			lineCoordinates: {x: -1000, y: -1000},
			startPositionSecond: {x: -1000, y: -1000},
			lineCoordinatesSecond: {x: -1000, y: -1000},
			selectedNo: 0,
			patternTransform: 0,
			pattern2Transform: 0,
			offsetYPattern: 0,
			offsetXPattern: 0,
			offsetYPattern2: 0,
			offsetXPattern2: 0,
			itemsSelected: [],
			allItems: [],
			current: 0,
			answers: [],
			secondsNegative: 0,
			validating: false,
			availableSkills: props.userInfo.character.skills.items[this.props.location.state.gameType].available,
		}

		this.canvasRef = createRef();
		this.gameContainerRef = createRef();
		this.line1Ref = createRef();
		this.line2Ref = createRef();
		this.item1Ref = createRef();
		this.item2Ref = createRef();
		this.itemLast = createRef();

		if(!this.props.location.start) {
			this.props.uiRedirect({
				pathname: `/challenge/instructions/${this.props.match.params.challengeId}`
			})
		} else if(!this.props.match.params.challengeId) {
			this.props.uiRedirect({
				pathname: '/map'
			})
		}

		this.lineUp = {
			getClientOffset: () => {},
			drawLine: () => {},
			mouseDownListener: () => {},
			mouseMoveListener: () => {},
			mouseupListener: () => {},
			snapToCenter: () => {},
			validate: () => {},
			nextWords: () => {},
			addListeners: () => {},
		}

		this.interval = false;
	}

	countNegative = () => {
		let stateCopy = {...this.state};
		this.interval = setInterval(() => {
			if(this.state.pauseNegative) {
				// clearInterval(interval)
			} else {
				this.setState({
					secondsNegative: stateCopy.secondsNegative--
				})
			}
		}, 1000);

	}

	openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback } );
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	componentDidMount() {

		document.addEventListener('touchmove', this.listener, { passive:false });
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.gamesSocket.error !== this.props.gamesSocket.error && this.props.gamesSocket.error) {
			if(this.state.isHint) {
				this.props.handleHint(false, false);
			}

			this.openModal(getText('Error'), getText('Something went wrong. Please try again later.'), true, getText('Retry'), false, false, true, () => {
				this.props.uiRedirect({
					pathname: `/map`
				})
				this.closeModal();
			});
		} else if(!this.props.gamesSocket.error) {
			if(!isEqual(prevState.challengePlay, this.state.challengePlay) && this.props.gamesSocket.call === 'start_challenge') {
				this.props.checkGameReady(true);

				const gameContainer = this.gameContainerRef.current;
				const item1 = this.item1Ref.current;
				const item2 = this.item2Ref.current;
				const itemLast = this.itemLast.current;
				let startPosition = this.state.startPosition;
				let lineCoordinates = this.state.lineCoordinates;
				let startPositionSecond = this.state.startPositionSecond;
				let lineCoordinatesSecond = this.state.lineCoordinatesSecond;
				let isDrawStart = false;
				let startElement = '';


				this.lineUp.getClientOffset = (event) => {
					const {pageX, pageY} = event.touches ? event.touches[0] : event;
					const x = pageX - gameContainer.offsetLeft;
					const y = pageY - gameContainer.offsetTop;

					return {
						x,
						y
					}
				}

				this.lineUp.drawLine = () => {
					if(!this.state.selectedNo) {
						this.setState({
							lineCoordinates
						})
					} else {
						this.setState({
							lineCoordinatesSecond
						})
					}
				}

				this.lineUp.mouseDownListener = (event) => {
					this.onDragstartHandler();
					startPosition = this.lineUp.getClientOffset(event);
					if(event.target.classList.contains('item')) {
						isDrawStart = true;
						startElement = event.target;

						startPositionSecond = {
							x: event.target.offsetLeft + event.target.offsetWidth / 2,
							y: event.target.offsetTop + event.target.offsetHeight / 2,
						}

						if(!this.state.selectedNo) {
							this.setState({
								startPosition: {
									x: event.target.offsetLeft + event.target.offsetWidth / 2,
									y: event.target.offsetTop + event.target.offsetHeight / 2,
								},
								lineCoordinates: {
									x: event.target.offsetLeft + event.target.offsetWidth / 2,
									y: event.target.offsetTop + event.target.offsetHeight / 2,
								},
							})
						} else {
							this.setState({
								startPositionSecond: {
									x: event.target.offsetLeft + event.target.offsetWidth / 2,
									y: event.target.offsetTop + event.target.offsetHeight / 2,
								},
								lineCoordinatesSecond: {
									x: event.target.offsetLeft + event.target.offsetWidth / 2,
									y: event.target.offsetTop + event.target.offsetHeight / 2,
								},
							})
						}
					}
				}

				 this.lineUp.mouseMoveListener = (event) => {
					if(!isDrawStart) return;

					let newState = {...this.state}

					if(!newState.itemsSelected.includes(parseInt(startElement.dataset.start))) {
						newState.itemsSelected.push(parseInt(startElement.dataset.start))
					}

					lineCoordinates = this.lineUp.getClientOffset(event);
					lineCoordinatesSecond = this.lineUp.getClientOffset(event);

					// 	let radians = Math.atan2((startPosition.y - lineCoordinates.y), (startPosition.x - lineCoordinates.x));
					// 	let degrees = (radians * 180 / Math.PI);
					//
					// this.setState({
					// 	patternTransform: degrees,
					// 	offsetYPattern: -(startPosition.y%30),
					// 	offsetXPattern: 0,
					// })

					if(this.state.selectedNo === 0) {
						let radians = Math.atan2((lineCoordinates.y - startPosition.y), (lineCoordinates.x - startPosition.x));
						let degrees = (radians * 180 / Math.PI);

						this.setState({
							patternTransform: degrees
						})
					} else {
						let radians2 = Math.atan2((lineCoordinatesSecond.y - startPositionSecond.y), (lineCoordinatesSecond.x - startPositionSecond.x));
						let degrees2 = (radians2 * 180 / Math.PI);

						this.setState({
							pattern2Transform: degrees2
						})
					}

					this.lineUp.drawLine();

					let elementTouchOver = event.changedTouches ? document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY) : '';
					if(isDrawStart) {
						if(event.target.classList.contains('item') && event.type === 'mouseup') {
							// if(parseInt(startElement.dataset.end) === parseInt(event.target.dataset.start)) {
							// 	snapToCenter(event.target);
							// }
						} else if(elementTouchOver && elementTouchOver.dataset.start !== startElement.dataset.start && elementTouchOver.classList.contains('item') && event.type === 'touchmove') {
							if(!this.state.itemsSelected.includes(parseInt(elementTouchOver.dataset.start))) {
								this.lineUp.snapToCenter(elementTouchOver);
								startElement = elementTouchOver;

								startPositionSecond = {
									x: elementTouchOver.offsetLeft + elementTouchOver.offsetWidth / 2,
									y: elementTouchOver.offsetTop + elementTouchOver.offsetHeight / 2,
								}

								if(this.state.selectedNo < 2) {
									this.setState({
										startPositionSecond: startPositionSecond,
										lineCoordinatesSecond: {
											x: elementTouchOver.offsetLeft + elementTouchOver.offsetWidth / 2,
											y: elementTouchOver.offsetTop + elementTouchOver.offsetHeight / 2,
										}
									})

									if(!newState.itemsSelected.includes(parseInt(elementTouchOver.dataset.start))) {
										newState.itemsSelected.push(parseInt(elementTouchOver.dataset.start))
									}
								} else {
									isDrawStart = false
								}
							}
						}
					}
				}

				 this.lineUp.mouseupListener = (event) => {
					this.onDragendHandler();
					let elementTouchEnd = event.changedTouches ? document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY) : '';
					if(isDrawStart) {
						if(event.target.classList.contains('item') && event.type === 'mouseup') {

						} else if(event.type === 'touchend') {
							if(elementTouchEnd && !elementTouchEnd.classList.contains('item') || startElement === elementTouchEnd) {
								this.lineUp.resetLine();
							}

							if(elementTouchEnd && this.state.itemsSelected.includes(parseInt(elementTouchEnd.dataset.start))) {
								this.lineUp.resetLine();
							}
						}
					}

					isDrawStart = false;
				}

				this.lineUp.snapToCenter = (element) => {
					let newState = {...this.state}
					if(!newState.itemsSelected.includes(parseInt(element.dataset.start))) {
						logger.info('doesnt include')
						newState.itemsSelected.push(parseInt(element.dataset.start));
					}

					if(this.state.selectedNo === 0) {
						logger.info('snap 1')
						this.setState({
							lineCoordinates: {
								x: element.offsetLeft + element.offsetWidth / 2,
								y: element.offsetTop + element.offsetHeight / 2,
							},
							selectedNo: this.state.selectedNo + 1,
							itemsSelected: newState.itemsSelected
						})
						startElement.removeEventListener('mousedown', this.lineUp.mouseDownListener);
						startElement.removeEventListener('touchstart', this.lineUp.mouseDownListener);
					} else {
						logger.info('snap 2');
						this.setState({
							lineCoordinatesSecond: {
								x: element.offsetLeft + element.offsetWidth / 2,
								y: element.offsetTop + element.offsetHeight / 2,
							},
							selectedNo: this.state.selectedNo + 1,
							itemsSelected: newState.itemsSelected
						}, () => {
							startElement.removeEventListener('mousedown', this.lineUp.mouseDownListener);
							startElement.removeEventListener('touchstart', this.lineUp.mouseDownListener);
						})

						if(this.state.selectedNo > 1) {
							logger.info('remove last')
							element.removeEventListener('mousedown', this.lineUp.mouseDownListener);
							element.removeEventListener('touchstart', this.lineUp.mouseDownListener);

							this.lineUp.validate();
							this.props.timer.pause();

							if(this.state.current + 1 < parseInt(this.state.challengePlay.total_rounds)) {
								this.lineUp.nextWords();
							} else {
								// setTimeout(() => {
								// 	this.props.uiRedirect({
								// 		pathname: '/result-game'
								// 	})
								// }, 600)
							}
						}
					}
				}

				this.lineUp.resetLine = () => {
					let newState = {...this.state}
					logger.info('reset');
					newState.itemsSelected.pop()

					if(!this.state.selectedNo) {
						this.setState({
							startPosition: {
								x: -1000,
								y: -1000
							},
							lineCoordinates: {
								x: -1000,
								y: -1000
							},
							itemsSelected: newState.itemsSelected
						})
					} else {
						this.setState({
							startPositionSecond: {
								x: -1000,
								y: -1000
							},
							lineCoordinatesSecond: {
								x: -1000,
								y: -1000
							},
							itemsSelected: newState.itemsSelected
						})
					}
				}

				this.lineUp.validate = (isHint) => {
					if(isHint) {
						this.props.handleHint(false, true);
					}

					let finalCountdown = parseInt(this.props.timer.getTime()/1000);

					if(finalCountdown <= 0) {
						finalCountdown = this.state.secondsNegative;
					}

					this.props.timer.pause();

					let itemsSelected = [...this.state.itemsSelected];

					let answer = [];

					for(let i = 0; i < this.state.challengePlay.rounds.phrases.length; i++) {
						answer.push({
							order: itemsSelected[i]
						})
					}

					if(this.props.gamesSocket.data.challengePlay.current_round + 1 === this.props.gamesSocket.data.challengePlay.total_rounds) {
						this.setState({
							toFinish: true
						})
					}
					this.setState({
						pauseNegative: true,
						validating: true,
						isHint: isHint,
					}, () => {
						if(!isHint) {
							this.props.socketLoadUpdateChallenge({ challengeCurrentRound: this.state.challengePlay.current_round, answer: answer, countdown: finalCountdown });
						} else {
							this.props.socketLoadUpdateChallenge({ challengeCurrentRound: this.state.challengePlay.current_round, autosolve: true, countdown: finalCountdown });
						}
					})
				}

				this.lineUp.nextWords = () => {
					// let newState = {...this.state};
					//
					// newState.itemsSelected = [];
					// newState.selectedNo = 0;
					// newState.patternTransform = 0;
					// newState.pattern2Transform = 0;
					// newState.startPosition = {x: -1000, y: -1000};
					// newState.startPositionSecond = {x: -1000, y: -1000};
					// newState.lineCoordinates = {x: -1000, y: -1000};
					// newState.lineCoordinatesSecond = {x: -1000, y: -1000};
					// newState.current = newState.current + 1;
					// newState.wordsTemplate = this.getRandomInt(1, 4);
					// newState.validating = false;

					setTimeout(() => {
						// this.props.timer.start();

						this.setState({
							changeWords: true
						});
						setTimeout(() => {
							this.setState({
								changeWords: false
							})
						}, 200)
						this.lineUp.addListeners();
					}, 600)



				}

				this.lineUp.addListeners = () => {
					item1.addEventListener('mousedown', this.lineUp.mouseDownListener);
					item2.addEventListener('mousedown', this.lineUp.mouseDownListener);
					itemLast.addEventListener('mousedown', this.lineUp.mouseDownListener);
					gameContainer.addEventListener('mousemove', this.lineUp.mouseMoveListener);
					gameContainer.addEventListener('mouseup', this.lineUp.mouseupListener);

					item1.addEventListener('touchstart', this.lineUp.mouseDownListener);
					item2.addEventListener('touchstart', this.lineUp.mouseDownListener);
					itemLast.addEventListener('touchstart', this.lineUp.mouseDownListener);
					gameContainer.addEventListener('touchmove', this.lineUp.mouseMoveListener);
					gameContainer.addEventListener('touchend', this.lineUp.mouseupListener);
				}

				this.lineUp.addListeners();
			}

			if (!isEqual(prevProps.gamesSocket, this.props.gamesSocket) && this.props.gamesSocket && this.props.gamesSocket.data && this.props.gamesSocket.data.challengePlay && this.props.gamesSocket.call === 'start_challenge') {

				let answers = [];
				for(let i = 0; i < this.props.gamesSocket.data.challengePlay.total_rounds; i++) {
					logger.info('this.props.gamesSocket.data.challengePlay.rounds_status[i]', this.props.gamesSocket.data.challengePlay.rounds_status[i])
					if(typeof this.props.gamesSocket.data.challengePlay.rounds_status[i] !== "undefined") {
						answers[i] = this.props.gamesSocket.data.challengePlay.rounds_status[i];
					} else {
						answers[i] = null;
					}
				}

				if(parseInt(this.props.gamesSocket.data.challengePlay.timer) <= 0) {
					this.setState({
						secondsNegative: this.props.gamesSocket.data.challengePlay.timer
					}, () => {
						this.countNegative();
					})
				}

				this.setState({
					challengePlay: this.props.gamesSocket.data.challengePlay,
					answers,
					index: (this.props.gamesSocket.data.challengePlay.total_rounds - 1) - this.props.gamesSocket.data.challengePlay.current_round,
				})

				this.props.timer.setCheckpoints([
					{
						time: 6000,
						callback: () => {
							this.setState({
								timeCritical: true
							})
						},
					},
					{
						time: 0,
						callback: () => {
							this.countNegative();
						},
					},
				]);

				this.props.timer.setTime((this.props.gamesSocket.data.challengePlay.timer <= 0 ? 0 : this.props.gamesSocket.data.challengePlay.timer * 1000 + 500));

				this.props.getTitle(this.props.gamesSocket.data.challengePlay.name[this.props.appGetInterfaceLang])
			}

			if(!isEqual(prevProps.gamesSocket.data, this.props.gamesSocket.data) && this.props.gamesSocket && this.props.gamesSocket.data && this.props.gamesSocket.data.challengePlay && this.props.gamesSocket.call === 'update_challenge') {
				let answers = [...this.state.answers];

				answers[this.props.gamesSocket.data.challengePlay.current_round - 1] = this.props.gamesSocket.data.challengePlay.rounds_status[this.props.gamesSocket.data.challengePlay.current_round - 1];

				if(prevProps.gamesSocket.data.challengePlay.current_round + 1 === this.props.gamesSocket.data.challengePlay.total_rounds) {
					answers[this.props.gamesSocket.data.challengePlay.current_round] = this.props.gamesSocket.data.challengePlay.rounds_status[this.props.gamesSocket.data.challengePlay.current_round];
				}

				if(this.state.isHint) {
					this.props.userLoadInfo();
				}

				this.setState({
					challengePlay: {
						...this.state.challengePlay,
						current_round: this.props.gamesSocket.data.challengePlay.current_round,
						total_rounds: this.props.gamesSocket.data.challengePlay.total_rounds
					},
					answers,
				}, () => {
					let newState = {...this.state};

					newState.challengePlay = this.props.gamesSocket.data.challengePlay;
					if(!this.state.toFinish) {
						newState.isHint = false;
						newState.validating = false;
						newState.itemsSelected = [];
						newState.selectedNo = 0;
						newState.patternTransform = 0;
						newState.pattern2Transform = 0;
						newState.startPosition = {x: -1000, y: -1000};
						newState.startPositionSecond = {x: -1000, y: -1000};
						newState.lineCoordinates = {x: -1000, y: -1000};
						newState.lineCoordinatesSecond = {x: -1000, y: -1000};
						newState.current = newState.current + 1;
						newState.wordsTemplate = this.getRandomInt(1, 5);
						newState.pauseNegative = false;
					}


					setTimeout(() => {
						if(this.state.toFinish) {
							this.props.socketLoadFinishChallenge();
						} else {
							if(this.props.timer.getTime() > 0) {
								this.props.timer.start();
							}

							this.setState({
								...this.state,
								...newState
							})
							this.props.handleHint(false, false);
						}
					}, 600)
				})
			}

			if(!isEqual(prevProps.gamesSocket, this.props.gamesSocket) && this.props.gamesSocket.gameFinished) {
				this.props.handleHint(false, false);
				this.props.uiRedirect({
					pathname: `/result-game/${this.state.challengePlay.challenge_id}`,
					state: {
						finish: true,
						challengePlay: this.props.gamesSocket.data.challengePlay,
					}
				})
			}
		}

		if(prevState.imagesLoaded !== this.state.imagesLoaded && this.state.imagesLoaded) {
			setTimeout(() => {
				this.props.socketLoadStartChallenge();
				this.props.timer.start();
			}, 800)
		}

		if(prevProps.runHint !== this.props.runHint && this.props.runHint) {
			this.lineUp.validate(true);
			this.props.timer.pause();

			if(this.state.current + 1 < parseInt(this.state.challengePlay.total_rounds)) {
				this.lineUp.nextWords();
			}
		}
	}

	listener = (e) => {
		if (! this.state.scrollable) {
			e.preventDefault();
		}
	}

	onDragstartHandler = () => {
		this.setState({
			scrollable: false
		})
	}

	onDragendHandler = () => {
		this.setState({
			scrollable: true
		})
	}

	returnTimeLeft = () => {
		return (
		  <>
		  	<Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} />
		  </>
		);
	}

	getRandomInt = (min, max) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	}

	componentWillUnmount() {
		document.removeEventListener('touchmove', this.listener, { passive:false });

		this.props.socketLoadCloseConnection();
		this.props.checkGameReady(false);

		clearInterval(this.interval);
	}

	render() {
		return (
		  <LineWordsComponent
			{...this.state}
			canvasRef={this.canvasRef}
			gameContainerRef={this.gameContainerRef}
			line1Ref={this.line1Ref}
			line2Ref={this.line2Ref}
			item1Ref={this.item1Ref}
			item2Ref={this.item2Ref}
			itemLast={this.itemLast}
			returnTimeLeft={this.returnTimeLeft}
			imagesLoaded={this.state.imagesLoaded}
			handlePreloadImages={this.handlePreloadImages}
			lang={this.props.appGetInterfaceLang}
		  />
		);
	}
}

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
  <Timer {...timerProps}>
	  {timerRenderProps =>
		<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
  </Timer>
);

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 60000,
	startImmediately: false,
})(LineWordsContainer);

export default TimerHOC;