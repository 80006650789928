import {
	put, fork, race, take, call, apply, takeLatest, cancel, cancelled, select, delay
} from 'redux-saga/effects';
import Observable from 'Observable';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import ls from 'local-storage';
import { createBrowserHistory } from 'history';
import logger from 'AppUtils/logging';

import { getQueryVariables, removeQueryVariable, getQueryVariable } from 'AppUtils/url';
import { getValue } from 'AppUtils/objects';
import XDate from 'AppUtils/xdate';

import * as GAMES from './types';
import { apiPost, apiGet, apiPatch } from 'AppUtils/api';
import { authApikey, authUuid } from "../../auth/store/selectors";
import SocketConnection from "../../../utils/socket";
import { gamesSocketChallengeId, gamesSocketChallengePlayId, gamesNotificationId } from "./selectors";
import * as USER from "../../user/store/types";
import * as COMMON from "../../../store/types";
import { userInfo } from "../../user/store/selectors";


function* onLoadStartBonus(action) {
	const uuid = yield select(authUuid);
	const playId = getValue(action, 'payload.playId', '');
	const notificationId = getValue(action, 'payload.notificationId', '');
	const userToken = yield select(authApikey);

	const retry = 3;
	let startBonus = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/bonus-games/start/${playId}`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  startBonus = response.play;
		  } else {
			  startBonus = response.error;
			  msg = response.error;
		  }
	  });

	if (!msg) {
		if (userToken != process.env.REACT_APP_GUEST_APIKEY) {
			yield put({ type: USER.USER_LOAD_EVENT, payload: {
				type: 'ACTIVITY_START',
				'metadata[activity_session_id]': playId,
				'metadata[activity_type]': 2,
				'metadata[activity_subtype]': getValue(startBonus,'bonus_game.id')
			} });
		} else {
			yield put({ type: USER.USER_LOAD_GUEST_EVENT, payload: {
				type: 'ACTIVITY_START',
				'metadata[activity_session_id]': playId,
				'metadata[activity_type]': 2,
				'metadata[activity_subtype]': getValue(startBonus,'bonus_game.id')
			} });
		}
	}

	yield put({ type: GAMES.GAMES_SET_START_BONUS, payload: { startBonus, msg, statusCode, notificationId } });
}

function* onLoadFinishBonus(action) {
	const uuid = yield select(authUuid);
	const notificationId = yield select(gamesNotificationId);
	const playId = getValue(action, 'payload.playId', '');
	const userToken = yield select(authApikey);
	const info = yield select(userInfo);

	const retry = 3;
	let finishBonus = '';
	let msg = '';
	let statusCode = '';

	const responseBonus = yield apiPost(`/bonus-games/finish/${playId}`, { uuid })
	  .retryWhen(errors => errors.delay(1000).take(retry))
	  .catch(e => Observable.of([]))
	  .mergeMap(res => {
		  const resp = res.json();
		  statusCode = res.status;
		  return resp;
	  }).toPromise().then(function (response) {
		  if (response && !response.error) {
			  finishBonus = response.play;
		  } else {
			  finishBonus = response.error;
			  msg = response.error;
		  }
	  });

	yield put({ type: USER.USER_LOAD_INFO });

	if (!msg) {
		if (userToken != process.env.REACT_APP_GUEST_APIKEY) {
			yield put({ type: USER.USER_LOAD_EVENT, payload: {
				type: 'ACTIVITY_END',
				'metadata[activity_session_id]': playId,
				'metadata[activity_type]': 2,
				'metadata[activity_subtype]': getValue(finishBonus,'bonus_game.id'),
			} });
		} else {
			yield put({ type: USER.USER_LOAD_GUEST_EVENT, payload: {
				type: 'ACTIVITY_END',
				'metadata[activity_session_id]': playId,
				'metadata[activity_type]': 2,
				'metadata[activity_subtype]': getValue(finishBonus,'bonus_game.id'),
			} });
		}
	}

	if (notificationId) {
		if (finishBonus) {
			yield put({ type: USER.USER_LOAD_NOTIFICATIONS_CONSUME, payload: { notificationId } });
			yield take(USER.USER_SET_NOTIFICATIONS_CONSUME);
			yield take(USER.USER_SET_NOTIFICATIONS);
			yield put({ type: GAMES.GAMES_SET_FINISH_BONUS, payload: { finishBonus, msg, statusCode } });
		}
	}
}

function* onSocketConnect() {
	const socket = new SocketConnection();

	socket.connect();

	yield put({ type: GAMES.SOCKET_SET_CONNECT, payload: { status: 'connected' } });
}

function* onSocketTimeout(action) {
	const uuid = yield select(authUuid);
	const userToken = yield select(authApikey);
	const challengeId = getValue(action, 'payload.challengeId');

	let payload = {
		uuid,
		userToken,
		challengeId,
	};
	const socket = new SocketConnection();

	socket.emit('connect_timeout', payload);

	yield put({ type: GAMES.SOCKET_SET_TIMEOUT, payload: {} });
}

function* onSocketDisconnect() {
	const socket = new SocketConnection();
	socket.disconnect();

	yield put({ type: GAMES.SOCKET_SET_DISCONNECT, payload: { status: 'disconnected' } });
}

function* onSocketInitChallenge(action) {
	const uuid = yield select(authUuid);
	const userToken = yield select(authApikey);
	const challengeId = getValue(action, 'payload.challengeId');

	yield put({ type: GAMES.SOCKET_LOAD_CONNECT });

	let payload = {
		params: {
			uuid,
			userToken,
			challengeId
		},
		call: 'init_challenge'
	};
	let error = '';
	let status = '';
	const socket = new SocketConnection();
	socket.emit('do_request', payload);

	logger.info('init_challenge', payload);

	const response = yield new Promise((resolve, reject) => {
		socket.on('send_response', (e) => {
			status = 'connected';
			resolve(e);
		});

		socket.on('error_code', (e) => {
			status = 'disconnected';
			reject(e);
		});
	}).catch(e => {
		error = e;
	});

	if (error) {
		yield put({ type: GAMES.SOCKET_LOAD_DISCONNECT });
	}

	yield put({ type: GAMES.SOCKET_SET_INIT_CHALLENGE, payload: { data: response, error, status } });
}

function* onSocketStartChallenge() {
	const userToken = yield select(authApikey);
	const challengeId = yield select(gamesSocketChallengeId);
	const challengePlayId = yield select(gamesSocketChallengePlayId);

	let payload = {
		params: {
			userToken,
			challengePlay: {
				challenge_id: challengeId,
				id: challengePlayId
			}
		},
		call: 'start_challenge'
	};
	let error = '';
	let status = '';

	const socket = new SocketConnection();

	if (!getValue(socket, 'socket.connected')) {
		yield put({
			type: GAMES.SOCKET_SET_START_CHALLENGE,
			payload: { error: 'disconnected', status: 'disconnected' }
		});
	}

	socket.emit('do_request', payload);

	logger.info('start_challenge', payload);

	const response = yield new Promise((resolve, reject) => {
		socket.on('send_response', (e) => {
			status = 'connected';
			resolve(e);
		});

		socket.on('error_code', (e) => {
			status = 'disconnected';
			reject(e);
		});
	}).catch(e => {
		error = e;
	});

	if (error) {
		yield put({ type: GAMES.SOCKET_LOAD_DISCONNECT });
	} else {
		if (userToken != process.env.REACT_APP_GUEST_APIKEY) {
			yield put({ type: USER.USER_LOAD_EVENT, payload: {
				type: 'ACTIVITY_START',
				'metadata[activity_session_id]': challengePlayId,
				'metadata[activity_type]': 1,
				'metadata[activity_subtype]': challengeId,
			} });
		} else {
			yield put({ type: USER.USER_LOAD_GUEST_EVENT, payload: {
				type: 'ACTIVITY_START',
				'metadata[activity_session_id]': challengePlayId,
				'metadata[activity_type]': 1,
				'metadata[activity_subtype]': challengeId,
			} });
		}
	}


	yield put({ type: GAMES.SOCKET_SET_START_CHALLENGE, payload: { error, status } });
}

function* onSocketUpdateChallenge(action) {
	const userToken = yield select(authApikey);
	const challengeId = yield select(gamesSocketChallengeId);
	const challengePlayId = yield select(gamesSocketChallengePlayId);
	const challengeCurrentRound = getValue(action, 'payload.challengeCurrentRound');
	const answer = getValue(action, 'payload.answer');
	const countdown = getValue(action, 'payload.countdown');
	const autosolve = getValue(action, 'payload.autosolve');

	let payload = {
		params: {
			userToken,
			answer,
			countdown,
			autosolve,
			challengePlay: {
				challenge_id: challengeId,
				id: challengePlayId,
				current_round: challengeCurrentRound,
			}
		},
		call: 'update_challenge'
	};
	let error = '';
	let status = '';

	const socket = new SocketConnection();

	if (!getValue(socket, 'socket.connected')) {
		yield put({
			type: GAMES.SOCKET_SET_UPDATE_CHALLENGE,
			payload: { error: 'disconnected', status: 'disconnected' }
		});
	}

	socket.emit('do_request', payload);

	logger.info('update_challenge', payload);

	const response = yield new Promise((resolve, reject) => {
		socket.on('send_response', (e) => {
			status = 'connected';
			resolve(e);
		});

		socket.on('error_code', (e) => {
			status = 'disconnected';
			reject(e);
		});
	}).catch(e => {
		error = e;
	});

	if (error) {
		yield put({ type: GAMES.SOCKET_LOAD_DISCONNECT });
	}

	// if (response && response.challengePlay) {
	// 	const total_rounds = getValue(response, 'challengePlay.total_rounds');
	// 	const current_round = getValue(response, 'challengePlay.current_round');
	//
	// 	if (current_round == (total_rounds - 1)) {
	// 		yield put({ type: GAMES.SOCKET_LOAD_FINISH_CHALLENGE });
	// 	}
	// }

	yield put({ type: GAMES.SOCKET_SET_UPDATE_CHALLENGE, payload: { data: response, error, status } });
}

function* onSocketFinishChallenge() {
	const userToken = yield select(authApikey);
	const info = yield select(userInfo);
	const challengeId = yield select(gamesSocketChallengeId);
	const challengePlayId = yield select(gamesSocketChallengePlayId);

	let payload = {
		params: {
			userToken,
			challengePlay: {
				challenge_id: challengeId,
				id: challengePlayId,
			}
		},
		call: 'finish_challenge'
	};
	let error = '';
	let status = '';

	const socket = new SocketConnection();

	if (!getValue(socket, 'socket.connected')) {
		yield put({
			type: GAMES.SOCKET_SET_FINISH_CHALLENGE,
			payload: { error: 'disconnected', status: 'disconnected' }
		});
	}

	socket.emit('do_request', payload);

	logger.info('finish_challenge', payload);

	const response = yield new Promise((resolve, reject) => {
		socket.on('send_response', (e) => {
			status = 'connected';
			resolve(e);
		});

		socket.on('error_code', (e) => {
			status = 'disconnected';
			reject(e);
		});
	}).catch(e => {
		error = e;
	});

	yield put({ type: USER.USER_LOAD_INFO });

	if (userToken != process.env.REACT_APP_GUEST_APIKEY) {
		yield put({ type: USER.USER_LOAD_EVENT, payload: {
			type: 'ACTIVITY_END',
			'metadata[activity_session_id]': challengePlayId,
			'metadata[activity_type]': 1,
			'metadata[activity_subtype]': challengeId,
		} });

		//update onboarding
		if (getValue(info, 'settings.onboardingStep') <= 2) {
			yield put({ type: USER.USER_LOAD_UPDATE_INFO, payload: { settings: JSON.stringify({ onboardingStep: 3, onboardingSubstep: 1 }) }});
		} else if (getValue(info, 'settings.onboardingStep') <= 3 && challengeId == getValue(info, 'character.map.cities.0.challenges.1.id') && getValue(info, 'character.map.cities.0.challenges.1.status') === 'active') {
			yield put({ type: USER.USER_LOAD_UPDATE_INFO, payload: { settings: JSON.stringify({ onboardingStep: 4, onboardingSubstep: 1 }) }});
		}

	} else {
		yield put({ type: USER.USER_LOAD_GUEST_EVENT, payload: {
			type: 'ACTIVITY_END',
			'metadata[activity_session_id]': challengePlayId,
			'metadata[activity_type]': 1,
			'metadata[activity_subtype]': challengeId,
		} });
	}

	yield put({ type: GAMES.SOCKET_SET_FINISH_CHALLENGE, payload: { data: response, error, status } });
}

function* watchInitialize() {
	yield takeLatest(GAMES.GAMES_LOAD_START_BONUS, onLoadStartBonus);
	yield takeLatest(GAMES.GAMES_LOAD_FINISH_BONUS, onLoadFinishBonus);

	yield takeLatest(GAMES.SOCKET_LOAD_CONNECT, onSocketConnect);
	yield takeLatest(GAMES.SOCKET_LOAD_TIMEOUT, onSocketTimeout);
	yield takeLatest(GAMES.SOCKET_LOAD_DISCONNECT, onSocketDisconnect);
	yield takeLatest(GAMES.SOCKET_LOAD_INIT_CHALLENGE, onSocketInitChallenge);
	yield takeLatest(GAMES.SOCKET_LOAD_START_CHALLENGE, onSocketStartChallenge);
	yield takeLatest(GAMES.SOCKET_LOAD_UPDATE_CHALLENGE, onSocketUpdateChallenge);
	yield takeLatest(GAMES.SOCKET_LOAD_FINISH_CHALLENGE, onSocketFinishChallenge);
}

export default function* sagas() {
	yield fork(watchInitialize);
}
