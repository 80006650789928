import socketIOClient from 'socket.io-client';
import logger from 'AppUtils/logging';
/**
 * Socket handler class
 */

class SocketConnection {
	/**
	 * @desc Singleton instance of the SocketConnection class
	 */
	static instance;

	socket;

	/**
	 * @desc Constructor
	 * @returns {*}
	 */
	constructor() {
		if(SocketConnection.instance){
			return SocketConnection.instance;
		}

		SocketConnection.instance = this;
		this.socket = socketIOClient(process.env.REACT_APP_WS_HOST);
	}

	/**
	 * @desc On event
	 * @param event
	 * @param callback
	 */
	on(event, callback){
		this.socket.on(event, callback);
		logger.info(`On ${event}`);
	}

	/**
	 * @desc Emit event
	 * @param event
	 * @param data
	 */
	emit(event, data){
		this.socket.emit(event, data);
		logger.info(`Emit ${event}`);
	}

	disconnect() {
		logger.info('disconnected');
		this.socket.disconnect();
	}

	connect() {
		logger.info('connected');
		this.socket.connect();
	}
}

export default SocketConnection;
