import React, {Component} from 'react';

import ConnectWordsInstructionsComponent from "./ConnectWordsInstructionsComponent";

class ConnectWordsInstructionsContainer extends Component {

    state = {

    };

    render() {
        return (
            <ConnectWordsInstructionsComponent
              imagesLoaded={this.props.imagesLoaded}
              handlePreloadImages={this.props.handlePreloadImages}
              challengePlay={this.props.challengePlay}
              handleStart={this.props.handleStart}
              lang={this.props.appGetInterfaceLang}
            />
        );
    }
}

export default ConnectWordsInstructionsContainer;