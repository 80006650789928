import React from 'react';

import SigninContainer from "../subcomponents/signin/SigninContainer";
import SignupContainer from "../subcomponents/signup/main/SignupContainer";
import RecoverContainer from "../subcomponents/recover/main/RecoverContainer";

import './Auth.scss';

const AuthComponent = (props) => {

    return (
        <>
            {
                props.authType === 'signin' ?
                    <SigninContainer {...props} />
                    :
                props.authType === 'signup' ?
                    <SignupContainer {...props} />
                    :
                props.authType === 'recover' ?
                    <RecoverContainer {...props} />
                    : null
            }
        </>
    );
};

export default AuthComponent;