import React from 'react';
import clsx from "clsx";
import { motion } from "framer-motion";
import getText from 'AppUtils/language';
import Preload from "react-preload";

import WrapperGame from '../../../subcomponents/wrapper-game/WrapperGame';
import TimerComponent from "../../../../../components/timer/Timer";
import InstructionsGame from "../../../subcomponents/instructions-game/InstructionsGame";
import ProgressBar from "../../../../../components/progress-bar/ProgressBar";
import LoaderPage from "../../../../../components/loader-page/LoaderPage";

import './LineWords.scss';

import bgPattern from './img/bg-pattern.png';
import correct from '../../../img/correct.svg';
import incorrect from '../../../img/incorrect.svg';
import bgWords from './img/bg-words.png';
import bgWordsActive from './img/bg-words-active.png';

const gameVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const wordVariants = {
	hidden: {
		scale: 0
	},
	visible: (custom) => ({
		opacity: 1,
		scale: 1,
		transition: {
			type: 'spring',
			stiffness: 300,
			damping: 20,
			duration: 0.2,
			delay: custom * 0.2
		}
	}),
	between: {
		opacity: 0,
		scale: 0,
		transition: {
			type: 'spring',
			stiffness: 300,
			damping: 20,
			duration: 0.1,
			// delay: custom * 0.2
		}
	},
}

const validIconVariant = {
	hidden: {
		top: '50%',
		left: 0,
		right: 0,
		opacity: 0
	},
	visible: {
		top: '30%',
		opacity: [1, 0],
		transition: {
			type: 'tween',
			duration: 1,
			times: [0, 0.9]
		},
	},
}

const LineWordsComponent = (props) => {

	const { dataWords, answers, totalAnswered, loadNewItems, wordsTemplate, gameContainerRef, line1Ref, line2Ref, item1Ref, item2Ref, itemLast, startPosition, lineCoordinates, startPositionSecond, lineCoordinatesSecond, itemsSelected, current } = props;

	let progress = 0;

	if(props.challengePlay && props.imagesLoaded) {
		progress = (((props.challengePlay.current_round+1)/props.answers.length) * 100).toFixed(3);

		if(props.challengePlay.current_round + 1 === props.challengePlay.total_rounds) {
			progress = 100;
		}
	}

	return (
	  <>
		  <LoaderPage isVisible={props.imagesLoaded && props.challengePlay} />
		  <Preload
			images={[bgPattern, correct, incorrect, bgWords, bgWordsActive]}
			autoResolveDelay={5000}
			// onError={}
			onSuccess={props.handlePreloadImages}
			resolveOnError={true}
			mountChildren={false}
		  >
		  </Preload>

		  {props.imagesLoaded && props.challengePlay && (
			<motion.div
			  className="line-words-component content-wrapper"
			  ref={gameContainerRef}
			  variants={gameVariants}
			  initial="hidden"
			  animate="visible"
			>
				<WrapperGame>
					<TimerComponent timeLeft={props.returnTimeLeft} timeCritical={props.timeCritical} />
					<svg height="100%" width="100%">
						<defs>
							<pattern id="img1" patternUnits="userSpaceOnUse" width="21" height="30" x={10.5} y={15} patternTransform={`rotate(${props.patternTransform.toFixed(2)})`}>
								<image href={bgPattern} width="21" height="30" />
							</pattern>
							<pattern id="img2" patternUnits="userSpaceOnUse" width="21" height="30" x={10.5} y={15} patternTransform={`rotate(${props.pattern2Transform.toFixed(2)})`}>
								<image href={bgPattern} width="21" height="30" />
							</pattern>
						</defs>
						<line x1={startPosition.x} y1={startPosition.y} x2={lineCoordinates.x} y2={lineCoordinates.y} stroke="url(#img1)" strokeLinecap="round" strokeWidth={30} ref={line1Ref} />
						<line x1={startPositionSecond.x} y1={startPositionSecond.y} x2={lineCoordinatesSecond.x} y2={lineCoordinatesSecond.y} stroke="url(#img2)" strokeLinecap="round" strokeWidth={30} ref={line2Ref} />
					</svg>
					<ul className={`words-list words-template-${wordsTemplate}`}>
						<motion.li
						  className={clsx('item', itemsSelected.includes(1) && 'active')}
						  ref={item1Ref}
						  data-start={1}
						  custom={1}
						  variants={wordVariants}
						  initial="hidden"
						  animate={props.changeWords ? 'between' : 'visible'}
						>
							<p>{props.challengePlay.rounds.phrases[0].text[props.lang]}</p>
						</motion.li>
						<motion.li
						  className={clsx('item', itemsSelected.includes(2) && 'active')}
						  ref={itemLast}
						  data-start={2}
						  custom={2}
						  variants={wordVariants}
						  initial="hidden"
						  animate={props.changeWords ? 'between' : 'visible'}
						>
							<p>{props.challengePlay.rounds.phrases[1].text[props.lang]}</p>
						</motion.li>
						<motion.li
						  className={clsx('item', itemsSelected.includes(3) && 'active')}
						  ref={item2Ref}
						  data-start={3}
						  custom={3}
						  variants={wordVariants}
						  initial="hidden"
						  animate={props.changeWords ? 'between' : 'visible'}
						>
							<p>{props.challengePlay.rounds.phrases[2].text[props.lang]}</p>
						</motion.li>
					</ul>

					{ props.answers[props.current] && props.answers[props.current] !== null && props.validating ?
					  <motion.img
						className="incorect-icon"
						src={correct}
						style={{height: 90, width: 90, position: "absolute", marginLeft: 'auto', marginRight: 'auto', zIndex: 2}}
						variants={validIconVariant}
						initial="hidden"
						animate="visible"
					  />
					  : !props.answers[props.current] && props.answers[props.current] !== null && props.validating ?
						<motion.img
						  className="correct-icon"
						  src={incorrect}
						  style={{height: 90, width: 90, position: "absolute", marginLeft: 'auto', marginRight: 'auto', zIndex: 2}}
						  variants={validIconVariant}
						  initial="hidden"
						  animate="visible"
						/>
						: null
					}

					<ProgressBar steps={[...props.answers]} progress={progress} total={props.challengePlay.current_round+1} />
				</WrapperGame>
				<InstructionsGame
				  lineFirst={props.challengePlay.hint[props.lang]}
				/>
			</motion.div>
		  )}
	  </>
	);
};

export default LineWordsComponent;