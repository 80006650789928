import React, {Component, createRef} from 'react';
import connect from 'react-redux/es/connect/connect';
import Timer from 'react-compound-timer'

import ConnectWordsComponent from "./ConnectWordsComponent";
import logger from 'AppUtils/logging';

import {uiRedirect, uiToggleModal} from "../../../../../store/actions";
import {
	socketLoadCloseConnection,
	socketLoadFinishChallenge,
	socketLoadStartChallenge,
	socketLoadUpdateChallenge
} from "../../../store/actions";
import {gamesSocket} from "../../../store/selectors";
import {appGetInterfaceLang} from "../../../../../store/selectors";
import isEqual from "lodash-es/isEqual";
import getText from "../../../../../utils/language";
import {userInfo} from "../../../../user/store/selectors";
import {userLoadInfo} from "../../../../user/store/actions";

const stateToProps = state => ({
	gamesSocket: gamesSocket(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
	userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadInfo: () => dispatch(userLoadInfo()),
	socketLoadUpdateChallenge: (data) => dispatch(socketLoadUpdateChallenge(data)),
	socketLoadStartChallenge: () => dispatch(socketLoadStartChallenge()),
	socketLoadFinishChallenge: () => dispatch(socketLoadFinishChallenge()),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	socketLoadCloseConnection: () => dispatch(socketLoadCloseConnection()),
});

@connect(stateToProps, actionsToProps)
class ConnectWordsContainer extends Component {

	constructor(props) {
		super(props);

		this.randomTemplateVal = this.getRandomInt(0, 2);
		this.wordsTemplates = [
			['top', 'top', 'bottom', 'bottom'],
			['top', 'bottom', 'top', 'bottom'],
			['top', 'top', 'bottom', 'bottom'],
		];

		this.state = {
			imagesLoaded: false,
			answers: [],
			totalAnswered: 0,
			wordsTemplate: this.randomTemplateVal,
			circlePosition: this.wordsTemplates[this.randomTemplateVal],
			choice: null,
			current: 0,
			validating: false,
			scrollable: true,
			animationComplete: 0,
			availableSkills: props.userInfo.character.skills.items[this.props.location.state.gameType].available,
			secondsNegative: 0,
			challengePlay: false,
		};

		this.constraintsRef = createRef();

		if(!this.props.location.start) {
			this.props.uiRedirect({
				pathname: `/challenge/instructions/${this.props.match.params.challengeId}`
			})
		} else if(!this.props.match.params.challengeId) {
			this.props.uiRedirect({
				pathname: '/map'
			})
		}

		let interval = false;
	}

	componentDidMount() {
		document.addEventListener('touchmove', this.listener, { passive:false });
		document.addEventListener('gesturestart', this.preventBehavior)
		document.addEventListener('gesturechange', this.preventBehavior)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.gamesSocket.error !== this.props.gamesSocket.error && this.props.gamesSocket.error) {
			if(this.state.isHint) {
				this.props.handleHint(false, false);
			}

			this.openModal(getText('Error'), getText('Something went wrong. Please try again later.'), true, getText('Retry'), false, false, true, () => {
				this.props.uiRedirect({
					pathname: `/map`
				})
				this.closeModal();
			});
		} else if(!this.props.gamesSocket.error) {
			if (!isEqual(prevProps.gamesSocket, this.props.gamesSocket) && this.props.gamesSocket && this.props.gamesSocket.data && this.props.gamesSocket.data.challengePlay && this.props.gamesSocket.call === 'start_challenge') {
				this.props.checkGameReady(true);

				let answers = [];
				for(let i = 0; i < this.props.gamesSocket.data.challengePlay.total_rounds; i++) {
					logger.info('this.props.gamesSocket.data.challengePlay.rounds_status[i]', this.props.gamesSocket.data.challengePlay.rounds_status[i])
					if(typeof this.props.gamesSocket.data.challengePlay.rounds_status[i] !== "undefined") {
						answers[i] = this.props.gamesSocket.data.challengePlay.rounds_status[i];
					} else {
						answers[i] = null;
					}
				}

				if(parseInt(this.props.gamesSocket.data.challengePlay.timer) <= 0) {
					this.setState({
						secondsNegative: this.props.gamesSocket.data.challengePlay.timer
					}, () => {
						this.countNegative();
					})
				}

				let newRounds = [];
				for(let i = 0; i < this.props.gamesSocket.data.challengePlay.rounds.phrases.length; i++) {
					let canAdd = true;
					logger.info(this.props.gamesSocket.data.challengePlay.rounds.phrases[i])
					for(let j = 0; j < this.state.circlePosition.length; j++) {
						if(this.props.gamesSocket.data.challengePlay.rounds.phrases[i].layout === this.state.circlePosition[j] && typeof newRounds[j] === 'undefined' && canAdd) {
							newRounds[j] = this.props.gamesSocket.data.challengePlay.rounds.phrases[i];
							canAdd = false;
						}
					}
				}

				let copyChallengePlay = {...this.props.gamesSocket.data.challengePlay};
				copyChallengePlay.rounds.phrases = newRounds;

				this.setState({
					challengePlay: copyChallengePlay,
					answers,
					index: (this.props.gamesSocket.data.challengePlay.total_rounds - 1) - this.props.gamesSocket.data.challengePlay.current_round,
				})

				this.props.timer.setCheckpoints([
					{
						time: 6000,
						callback: () => {
							this.setState({
								timeCritical: true
							})
						},
					},
					{
						time: 0,
						callback: () => {
							console.log('count negative =======================================')
							this.countNegative();
						},
					},
				]);

				this.props.timer.setTime((this.props.gamesSocket.data.challengePlay.timer <= 0 ? 0 : this.props.gamesSocket.data.challengePlay.timer * 1000 + 500));

				this.props.getTitle(this.props.gamesSocket.data.challengePlay.name[this.props.appGetInterfaceLang])
			}

			if(!isEqual(prevProps.gamesSocket.data, this.props.gamesSocket.data) && this.props.gamesSocket && this.props.gamesSocket.data && this.props.gamesSocket.data.challengePlay && this.props.gamesSocket.call === 'update_challenge') {
				let answers = [...this.state.answers];

				answers[this.props.gamesSocket.data.challengePlay.current_round - 1] = this.props.gamesSocket.data.challengePlay.rounds_status[this.props.gamesSocket.data.challengePlay.current_round - 1];

				if(prevProps.gamesSocket.data.challengePlay.current_round + 1 === this.props.gamesSocket.data.challengePlay.total_rounds) {
					answers[this.props.gamesSocket.data.challengePlay.current_round] = this.props.gamesSocket.data.challengePlay.rounds_status[this.props.gamesSocket.data.challengePlay.current_round];
				}

				if(this.state.isHint) {
					this.props.userLoadInfo();
				}

				this.setState({
					// challengePlay: this.props.gamesSocket.data.challengePlay,
					answers,
				}, () => {


					setTimeout(() => {
						if(this.state.toFinish) {
							this.props.socketLoadFinishChallenge();
						} else {
							if(this.props.timer.getTime() > 0) {
								this.props.timer.start();
							}

							this.setState({
								validating: false,
								isHint: false,
								pauseNegative: false,
							})
							this.props.handleHint(false, false);
						}
					}, 800)
				})
			}

			if(!isEqual(prevProps.gamesSocket, this.props.gamesSocket) && this.props.gamesSocket.gameFinished) {
				this.props.handleHint(false, false);
				this.props.uiRedirect({
					pathname: `/result-game/${this.state.challengePlay.challenge_id}`,
					state: {
						finish: true,
						challengePlay: this.props.gamesSocket.data.challengePlay,
					}
				})
			}

			if(prevState.current !== this.state.current) {
				let newRounds = [];
				for(let i = 0; i < this.props.gamesSocket.data.challengePlay.rounds.phrases.length; i++) {
					let canAdd = true;
					logger.info(this.props.gamesSocket.data.challengePlay.rounds.phrases[i])
					for(let j = 0; j < this.state.circlePosition.length; j++) {
						if(this.props.gamesSocket.data.challengePlay.rounds.phrases[i].layout === this.state.circlePosition[j] && typeof newRounds[j] === 'undefined' && canAdd) {
							newRounds[j] = this.props.gamesSocket.data.challengePlay.rounds.phrases[i];
							canAdd = false;
						}
					}
				}

				let copyChallengePlay = {...this.props.gamesSocket.data.challengePlay};
				copyChallengePlay.rounds.phrases = newRounds;

				this.setState({
					challengePlay: copyChallengePlay
				})
			}
		}

		if(prevState.imagesLoaded !== this.state.imagesLoaded && this.state.imagesLoaded) {
			setTimeout(() => {
				this.props.socketLoadStartChallenge();
				this.props.timer.start();
			}, 800)
		}
	}

	componentWillUnmount() {
		document.removeEventListener('touchmove', this.listener, { passive:false });
		document.removeEventListener('gesturestart', this.preventBehavior)
		document.removeEventListener('gesturechange', this.preventBehavior)

		this.props.socketLoadCloseConnection();
		this.props.checkGameReady(false);
		clearInterval(this.interval);
	}

	preventBehavior = (e) => {
		e.preventDefault();
	};

	listener = (e) => {
		if (! this.state.scrollable) {
			e.preventDefault();
		}
	}

	openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback } );
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	countNegative = () => {
		let stateCopy = {...this.state};
		this.interval = setInterval(() => {
			if(this.state.pauseNegative) {
				// clearInterval(interval)
			} else {
				this.setState({
					secondsNegative: stateCopy.secondsNegative--
				})
			}
		}, 1000);
	}

	onDragstartHandler = () => {
		this.setState({
			scrollable: false
		})
	}

	onDragendHandler = () => {
		this.setState({
			scrollable: true
		})
	}

	getRandomInt = (min, max) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	setValidating = (value) => {
		this.setState({
			validating: value
		})
	}

	handleNext = (nextTemplate) => {
		logger.info('handle next template', nextTemplate)
		this.setState({
			current: this.state.current + 1,
			circlePosition: this.wordsTemplates[nextTemplate],
			wordsTemplate: nextTemplate,
			validating: false,
			choice: null
		})
	}

	handleChoice = (first, second, isHint) => {
		if(isHint) {
			this.props.handleHint(false, true);
		}

		let finalCountdown = parseInt(this.props.timer.getTime()/1000);

		if(finalCountdown <= 0) {
			finalCountdown = this.state.secondsNegative;
		}

		this.props.timer.pause();

		if(this.props.gamesSocket.data.challengePlay.current_round + 1 === this.props.gamesSocket.data.challengePlay.total_rounds) {
			this.setState({
				toFinish: true
			})
		}
		this.setState({
			pauseNegative: true,
			validating: true,
			animationComplete: 0,
			choice: [first, second],
			isHint: isHint,
		}, () => {
			if(!isHint) {
				this.props.socketLoadUpdateChallenge({ challengeCurrentRound: this.state.challengePlay.current_round, answer: [first, second], countdown: finalCountdown });
			} else {
				this.props.socketLoadUpdateChallenge({ challengeCurrentRound: this.state.challengePlay.current_round, autosolve: true, countdown: finalCountdown });
			}
		})
	}

	handleAnimationComplete = () => {
		this.setState({
			animationComplete: this.state.animationComplete + 1
		})
	}

	returnTimeLeft = () => {
		return (
		  <>
			  <Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} />
		  </>
		);
	}

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	}

	render() {
		return (
		  <ConnectWordsComponent
			{...this.state}
			returnTimeLeft={this.returnTimeLeft}
			constraintsRef={this.constraintsRef}
			handleNext={this.handleNext}
			handleChoice={this.handleChoice}
			setValidating={this.setValidating}
			getRandomInt={this.getRandomInt}
			onDragstartHandler={this.onDragstartHandler}
			onDragendHandler={this.onDragendHandler}
			uiRedirect={this.props.uiRedirect}
			imagesLoaded={this.state.imagesLoaded}
			handlePreloadImages={this.handlePreloadImages}
			lang={this.props.appGetInterfaceLang}
			handleAnimationComplete={this.handleAnimationComplete}
			runHint={this.props.runHint}
		  />
		);
	}
}

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
  <Timer {...timerProps}>
	  {timerRenderProps =>
		<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
  </Timer>
);

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 60000,
	startImmediately: false,
})(ConnectWordsContainer);

export default TimerHOC;