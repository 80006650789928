import React from 'react';
import clsx from "clsx";

import './ProgressBar.scss';

const ProgressBar = (props) => {

	const renderSteps = () => {
		return props.steps.map((step, index) => {
			return <div className={clsx("step", step && step !== null && 'correct', !step && step !== null && 'incorrect')} key={index}></div>;
		})
	}

	let widthProgressLine = 0;

	if(props.steps) {
		if(props.total > 0) {
			widthProgressLine = props.total < props.steps.length ? props.progress - ((1/props.steps.length) * 100).toFixed(3)/2 +3 : props.progress;
		}
	}

	return (
	  <div className="progress-bar">
		  <div className="progress-line" style={{width: `${widthProgressLine}%`}}></div>
		  {props.steps && renderSteps()}
	  </div>
	);
};

export default ProgressBar;