import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import Observable from 'Observable';


import PromptComponent from "./PromptComponent";

import { apiPost } from 'AppUtils/api';
import logger from 'AppUtils/logging';

import {validateSubmit} from "../../../../../../utils/form";
import { uiRedirect, uiToggleModal } from "../../../../../../store/actions";
import { authStatus, authUuid } from "../../../../store/selectors";
import getText from 'AppUtils/language';
import { authSet } from "../../../../store/actions";
import { userLoadEvent, userLoadGuestEvent, userLoadInfo } from "../../../../../user/store/actions";
import { getValue } from 'AppUtils/objects';
import {appGetInterfaceLang} from "../../../../../../store/selectors";

const stateToProps = state => ({
	authUuid: authUuid(state),
	authStatus: authStatus(state),
	lang: appGetInterfaceLang(state)
});

const actionsToProps = dispatch => ({
	authSet: (auth) => dispatch(authSet(auth)),
	userLoadInfo: (data) => dispatch(userLoadInfo(data)),
	userLoadEvent: (data) => dispatch(userLoadEvent(data)),
	userLoadGuestEvent: (data) => dispatch(userLoadGuestEvent(data)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
});

@connect(stateToProps, actionsToProps)
class PromptContainer extends Component {

	state = {
		isSubmitting: false,
		currentStep: 1,
		formData: {
			prefix: {
				element: 'input',
				value: `+${this.props.prefix}`,
				config: {
					id: 'prefix',
					name: 'prefix',
					type: 'tel',
					placeholder: getText('Prefix'),
				},
				validation: {
					required: false,
					minlength: 0,
					numeric: true
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
			phone: {
				element: 'input',
				value: '',
				config: {
					id: 'phone',
					name: 'phone',
					type: 'tel',
					placeholder: getText('Phone'),
				},
				validation: {
					required: true,
					minlength: this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.msisdn.minLength ? this.props.appGetConfig.users.msisdn.minLength : 9,
					maxLength: this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.msisdn.maxLength ? this.props.appGetConfig.users.msisdn.maxLength : 9,
					numeric: true
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
		},
		formDataStep2: {
			pin: {
				element: 'input',
				value: '',
				config: {
					id: 'pin',
					name: 'pin',
					type: 'pin',
					placeholder: 'Pin'
				},
				validation: {
					required: true,
					minlength: this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.pin.minLength ? this.props.appGetConfig.users.pin.minLength : 6,
					maxLength: this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.pin.maxLength ? this.props.appGetConfig.users.pin.maxLength : 6,
				},
				valid: false,
				touched: false,
				validationMessage: ''
			}
		}
	}

	updateField = (fieldToUpdate, newFieldState) => {
		this.setState({
			formData: {
				...this.state.formData,
				[fieldToUpdate]: newFieldState
			}
		})
	}

	updateFieldStep2 = (fieldToUpdate, newFieldState) => {
		this.setState({
			formDataStep2: {
				...this.state.formDataStep2,
				[fieldToUpdate]: newFieldState
			}
		})
	}

	handleSubmit = (e) => {
		logger.info('submit')
		e.preventDefault();

		if(!this.state.isSubmitting) {
			let invalid = validateSubmit(this.state.formData);

			if(invalid) {
				this.setState({
					formData: invalid
				})
			} else {
				logger.info('valid')
				if(this.props.direct) {
					this.handleGenerate();
				} else {
					this.handleSubscribePrompt();
				}
			}
		}
	}

	handleSubmitStep2 = (e) => {
		logger.info('submit');
		e.preventDefault();


		let invalid = validateSubmit(this.state.formDataStep2);

		if(invalid) {
			this.setState({
				formDataStep2: invalid
			})
		} else {
			logger.info('valid')
			this.handleSubscribeDirectPrompt();
		}
	}

	handleGenerate = () => {
		let msisdn = this.state.formData.phone.value; //this.state.msisdn
		if(this.props.prefix) {
			msisdn = `${this.props.prefix.replace('+', '')}${this.state.formData.phone.value}`;
		}
		let uuid = this.props.authUuid;

		this.setState({
			isSubmitting: true
		})

		apiPost('/user/generate', { msisdn, uuid })
		  .catch((e) => {
			  this.setState({
				  isSubmitting: false
			  });

			  this.openModal(getText('Error'), getText('Invalid mobile number'));

			  return Observable.empty();
		  })
		  .mergeMap(res => {
			  let resp = Promise.resolve({ error: true });
			  if (res.ok) {
				  resp = res.json();
			  }

			  return Promise.resolve({ resp, statusCode: res.status });
		  })
		  .subscribe((result) => {
			  const { resp, statusCode } = result;

			  resp.then(response => {
				  if (response && !response.error) {
					  this.setState({
						  errorMsg: '',
						  isSubmitting: false,
						  currentStep: 2
					  });

				  } else {
					  this.setState({
						  errorMsg: response.error,
						  isSubmitting: false,
					  });

					  let errorMsg = '';
					  switch (statusCode) {
						  case 304:
							  errorMsg = getText('User already subscribed');
							  break;
						  case 400:
							  errorMsg = getText('Authentification service bad request, please try again later');
							  break;
						  case 401:
							  errorMsg = getText('Wrong username or password, please try again');
							  break;
						  case 403:
							  errorMsg = getText('Authentification service forbidden, please try again later');
							  break;
						  case 404:
							  errorMsg = getText('Authentification service not available, please try again later');
							  break;
						  case 500:
							  errorMsg = getText('Authentification service internal error, please try again later');
							  break;
						  case 502:
							  errorMsg = getText('Authentification service internal error, please try again later');
							  break;
						  case 503:
							  errorMsg = getText('Authentification service too busy, please try again later');
							  break;
						  default:
							  errorMsg = getText('Wrong username or password, please try again');
					  }
					  this.openModal(getText('Error'), errorMsg);
				  }
			  });
		  });
	};

	handleSubscribeDirectPrompt = () => {
		let msisdn = this.state.formData.phone.value; //this.state.msisdn
		let password = this.state.formDataStep2.pin.value;
		if(this.props.prefix) {
			msisdn = `${this.props.prefix.replace('+', '')}${this.state.formData.phone.value}`;
		}
		let uuid = this.props.authUuid;

		this.setState({
			isSubmitting: true
		})

		apiPost('/user/subscribe-direct', { msisdn, uuid, password })
		  .catch((e) => {
			  this.setState({
				  isSubmitting: false
			  });

			  this.openModal(getText('Error'), getText('Invalid mobile number'));

			  return Observable.empty();
		  })
		  .mergeMap(res => {
			  let resp = Promise.resolve({ error: true });
			  if (res.ok) {
				  resp = res.json();
			  }

			  return Promise.resolve({ resp, statusCode: res.status });
		  })
		  .subscribe((result) => {
			  const { resp, statusCode } = result;

			  resp.then(response => {
				  if (response && !response.error) {
					  this.setState({
						  errorMsg: '',
						  isSubmitting: false
					  });

					  const apikey = response.apikey;

					  if (apikey) {
						  this.handleSuccessAuth(apikey, statusCode);
					  }
				  } else {
					  this.setState({
						  errorMsg: response.error,
						  isSubmitting: false
					  });

					  let errorMsg = '';
					  switch (statusCode) {
						  case 304:
							  errorMsg = getText('User already subscribed');
							  break;
						  case 400:
							  errorMsg = getText('Authentification service bad request, please try again later');
							  break;
						  case 401:
							  errorMsg = getText('Wrong username or password, please try again');
							  break;
						  case 403:
							  errorMsg = getText('Authentification service forbidden, please try again later');
							  break;
						  case 404:
							  errorMsg = getText('Authentification service not available, please try again later');
							  break;
						  case 500:
							  errorMsg = getText('Authentification service internal error, please try again later');
							  break;
						  case 502:
							  errorMsg = getText('Authentification service internal error, please try again later');
							  break;
						  case 503:
							  errorMsg = getText('Authentification service too busy, please try again later');
							  break;
						  default:
							  errorMsg = getText('Wrong username or password, please try again');
					  }
					  this.openModal(getText('Error'), errorMsg);
				  }
			  });
		  });
	};

	handleSuccessAuth = (apikey, statusCode) => {
		this.props.authSet({ apikey, status: 'valid' });

		// now we have an apikey yey... load info
		this.props.userLoadInfo({ apikey, status: 'valid' });

		//load event
		if (apikey != process.env.REACT_APP_GUEST_APIKEY) {
			this.props.userLoadEvent({ type: 'LOGIN', statusCode });
		} else {
			this.props.userLoadGuestEvent({ type: 'LOGIN', statusCode });
		}

		this.props.uiRedirect({
			pathname: '/'
		});

		//redirect the dude to previous page.
		// const from = getValue(this.props, 'location.state.from', null);
		//
		// if (from) {
		// 	this.props.uiRedirect({
		// 		pathname: from
		// 	});
		// } else {
		// 	this.props.uiRedirect({
		// 		pathname: '/'
		// 	});
		// }
	};

	handleSubscribePrompt = () => {
		let msisdn = this.state.formData.phone.value; //this.state.msisdn
		if(this.props.prefix) {
			msisdn = `${this.props.prefix.replace('+', '')}${this.state.formData.phone.value}`;
		}
		let uuid = this.props.authUuid;

		this.setState({
			isSubmitting: true
		})

		apiPost('/user/subscribe-prompt', { msisdn, uuid })
		  .catch((e) => {
			  this.setState({
				  isSubmitting: false
			  });

			  this.openModal(getText('Error'), getText('Invalid mobile number'));

			  return Observable.empty();
		  })
		  .mergeMap(res => {
			  let resp = Promise.resolve({ error: true });
			  if (res.ok) {
				  resp = res.json();
			  }

			  return Promise.resolve({ resp, statusCode: res.status });
		  })
		  .subscribe((result) => {
			  const { resp, statusCode } = result;

			  resp.then(response => {
				  if (response && !response.error) {
					  this.setState({
						  errorMsg: '',
						  isSubmitting: false
					  });

					  // this.props.uiRedirect({
					  // 	pathname: '/'
					  // });
					  this.openModal(getText('Success'), getText('In a few moments you will receive an SMS with your username and password'), true, getText('Sign in'), false, false,() => {
						  this.props.uiToggleModal({}, { toggle: false }, {} );
						  this.props.uiRedirect({
							  pathname: '/'
						  })
					  });
				  } else {
					  this.setState({
						  errorMsg: response.error,
						  isSubmitting: false
					  });

					  let errorMsg = '';
					  switch (statusCode) {
						  case 304:
							  errorMsg = getText('User already subscribed');
							  break;
						  case 400:
							  errorMsg = getText('Authentification service bad request, please try again later');
							  break;
						  case 401:
							  errorMsg = getText('Wrong username or password, please try again');
							  break;
						  case 403:
							  errorMsg = getText('Authentification service forbidden, please try again later');
							  break;
						  case 404:
							  errorMsg = getText('Authentification service not available, please try again later');
							  break;
						  case 500:
							  errorMsg = getText('Authentification service internal error, please try again later');
							  break;
						  case 502:
							  errorMsg = getText('Authentification service internal error, please try again later');
							  break;
						  case 503:
							  errorMsg = getText('Authentification service too busy, please try again later');
							  break;
						  default:
							  errorMsg = getText('Wrong username or password, please try again');
					  }
					  this.openModal(getText('Error'), errorMsg);
				  }
			  });
		  });
	};

	openModal = (title, message, button, buttonText, button2, button2Text, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, button2, button2Text, callback  } );
	};

	render() {
		return (
		  <PromptComponent
			currentStep={this.state.currentStep}
			formData={this.state.formData}
			formDataStep2={this.state.formDataStep2}
			updateField={this.updateField}
			updateFieldStep2={this.updateFieldStep2}
			handleSubmit={this.handleSubmit}
			handleSubmitStep2={this.handleSubmitStep2}
			termsLink={this.props.appGetConfig.content.terms[this.props.lang]}
		  />
		);
	}
}

export default PromptContainer;