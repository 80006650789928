import React from 'react';
import { Link } from "react-router-dom";
import getText, { HtmlParser } from 'AppUtils/language';

import FormField from "../../../../../../components/form-field/FormField";

const PromptComponent = (props) => {
	return (
	  <div className="phone-form">
		  <p className="instructions-auth">{getText('Enter the telephone number for you account')}</p>
		  <form action="">
			  <div className="row-phone">
				  <div className="prefix">
					  <FormField
						element={props.formData.prefix}
						updateField={props.updateField}
						disabled={true}
					  />
				  </div>

				  <FormField
					element={props.formData.phone}
					updateField={props.updateField}
				  />
			  </div>
			  <div className="submit-input-group">
				  <button
					type="submit"
					className="submit-btn"
					onClick={(e) => props.handleSubmit(e)}
				  >{getText('SEND SMS')}</button>
			  </div>
		  </form>
	  </div>
	);
};

export default PromptComponent;