import React from 'react';
import { Link } from "react-router-dom";
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';

import Preload from "react-preload";
import { motion } from "framer-motion";

import LoaderPage from "../../components/loader-page/LoaderPage";
import BuyButton from "../../components/buy-button/BuyButton";

import user1 from "./img/user-1.jpg";
import editPhoto from './img/edit-photo.svg';
import iconLevel from './img/icon-level.svg';
import iconCup from './img/icon-cup.svg';
import iconTicketBig from './img/icon-ticket-big.svg';
import iconCart from './img/icon-cart.svg';
import iconTicket from './img/icon-ticket.svg';
import iconSkills from './img/icon-skills.svg';
import iconAchievments from './img/icon-achievments.svg';
import waveAvatar from './img/wave-avatar.svg';
import waveBoxTop from './img/wave-box-top.svg';
import iconUnsubscribe from './img/icon-unsubscribe.png';

import './Profile.scss';

const profileVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const avatarVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
			delay: 0.2
		}
	}
}

const listVariants = {
	hidden: {
		y: -40,
		opacity: 0,
	},
	visible: (custom) => ({
		y: 0,
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.3,
			delay: custom * 0.1
		}
	})
}

const ProfileComponent = (props) => {
	const userInfoCh = getValue(props, 'userInfo.character');
	const userInfoEl = getValue(props, 'userInfo.eligibility');
	const credits = getValue(userInfoEl, 'credits.purchased', 0) + getValue(userInfoEl, 'credits.subscription', 0);

	return (
	  <>
	  <LoaderPage isVisible={props.imagesLoaded && props.userInfo}/>
	  <Preload
		images={[user1, editPhoto, iconLevel, iconCup, iconTicketBig, iconCart, iconTicket, iconSkills, iconAchievments, waveAvatar, waveBoxTop, iconUnsubscribe]}
		autoResolveDelay={5000}
		// onError={}
		onSuccess={props.handlePreloadImages}
		resolveOnError={true}
		mountChildren={false}
	  >
	  </Preload>

	  {props.imagesLoaded && props.userInfo && (
		<motion.div
		  className="profile-component"
		  variants={profileVariants}
		  initial="hidden"
		  animate="visible"
		>
			<div className="content-wrapper-small">
				<motion.div
				  className="info-user"
				  variants={avatarVariants}
				  initial="hidden"
				  animate="visible"
				>
					<div className="avatar">
						<div className="avatar-user">
							<img src={getValue(userInfoCh, 'avatar')} alt=""/>
						</div>
						<Link className="edit-photo" to="/profile-edit">
							<img src={editPhoto} alt=""/>
						</Link>
					</div>
					<h3 className="username">{userInfoCh.nickname}</h3>
					<Link to="/profile-edit" className="edit-link">{getText('EDIT PROFILE')}</Link>
				</motion.div>
				<div className="stats-user">
					<motion.div
					  className="box-stats box-xp"
					  custom={2}
					  variants={listVariants}
					  initial="hidden"
					  animate="visible"
					>
						<div className="infos-box">
							<div className="info-1">
								<img src={iconLevel} alt=""/>
								<div className="text-info">
									<p>{getText('LEVEL')}</p>
									<p className="number"><strong>{getValue(userInfoCh, 'level.current', 0) ? userInfoCh.level.current : 0}</strong></p>
								</div>
							</div>
							<div className="info-2 info-xp">
								<p>{getValue(userInfoCh, 'points', 0)} XP</p>
								<div className="progress"><span style={{width: `${getValue(userInfoCh, 'level.percentage', 0)}%`}}></span></div>
							</div>
							<div className="info-3">
								<img src={iconCup} alt=""/>
								<div className="text-info">
									<p>{getText('POSITION')}</p>
									<p className="number"><strong>{getValue(userInfoCh, 'position', 0)}</strong></p>
								</div>
							</div>
						</div>
					</motion.div>

					<motion.div
					  className="box-stats box-tickets"
					  custom={3}
					  variants={listVariants}
					  initial="hidden"
					  animate="visible"
					>
						<div className="infos-box">
							<div className="info-1">
								<img src={iconTicketBig} alt=""/>
								<div className="text-info">
									<p>{getText('TICKETS')}</p>
									<p className="number"><strong>{credits}</strong></p>
								</div>
							</div>
							<div className="info-2 tickets-list">
								{credits >= 1 && <img src={iconTicket} alt=""/>}
								{credits >= 2 && <img src={iconTicket} alt=""/>}
								{credits >= 3 && <img src={iconTicket} alt=""/>}
								{credits >= 4 && <img src={iconTicket} alt=""/>}
								{credits >= 5 && <img src={iconTicket} alt=""/>}
							</div>
							{!props.disableBuyCredits &&
								<div className="info-3">
									<img src={iconCart} alt=""/>
									<BuyButton title={getText('BUY MORE')} link="/store/tickets" className="buy-btn"/>
								</div>
							}
						</div>
					</motion.div>

					<motion.div
					  className="stats-row"
					  custom={4}
					  variants={listVariants}
					  initial="hidden"
					  animate="visible"
					>
						<div className="box-stats box-skills">
							<div className="infos-box">
								<div className="info-1">
									<img src={iconSkills} alt=""/>
									<Link to="/skills" className="text-info">
										<p>{getText('SKILLS')}</p>
									</Link>
								</div>
							</div>
						</div>

						<div className="box-stats box-achievments">
							<div className="infos-box">
								<div className="info-1">
									<img src={iconAchievments} alt=""/>
									<Link to="/achievements" className="text-info">
										<p>{getText('ACHIEVEMENTS')}</p>
									</Link>
								</div>
							</div>
						</div>
					</motion.div>

					<motion.div
					  className="box-stats box-unsubscribe"
					  custom={4}
					  variants={listVariants}
					  initial="hidden"
					  animate="visible"
					  onClick={() => props.handleUnsubscribe()}
					>
						<div className="btn-unsubscribe">
							<img src={iconUnsubscribe} alt=""/>
							<div className="text-info">
								<p>{getText('UNSUBSCRIBE')}</p>
							</div>
						</div>
					</motion.div>
				</div>
			</div>
		</motion.div>
	  )}
	  </>
	);
};

export default ProfileComponent;