import React from 'react';
import clsx from "clsx";
import Preload from "react-preload";
import { motion } from "framer-motion";
import getText, { HtmlParser } from 'AppUtils/language';

import BoxShadow from "../../components/box-shadow/BoxShadow";
import LoaderPage from "../../components/loader-page/LoaderPage";

import arrow from './img/arrow.png';

import './Tutorial.scss';

const tutorialVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const listVariants = {
	hidden: {
		y: -40,
		opacity: 0,
	},
	visible: (custom) => ({
		y: 0,
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.3,
			delay: custom * 0.1
		}
	})
}

const TutorialComponent = (props) => {
	return (
	  <>
		  <LoaderPage isVisible={props.imagesLoaded && (props.items.length > 0)} />
		  <Preload
			images={[arrow]}
			autoResolveDelay={5000}
			// onError={}
			onSuccess={props.handlePreloadImages}
			resolveOnError={true}
			mountChildren={false}
		  >
		  </Preload>

		  {props.imagesLoaded && (
			<motion.div
			  className="tutorial-component"
			  variants={tutorialVariants}
			  initial="hidden"
			  animate="visible"
			>
				<div className="content-wrapper-small">
					<div className="tutorials-list">
						{props.items.length > 0 && props.items.map((item, idx) => 	<motion.div
						  key={idx}
						  className={clsx('tutorial-item', props.openTutorial === idx && 'opened')}
						  custom={idx + 1}
						  variants={listVariants}
						  initial="hidden"
						  animate="visible"
						>
							<BoxShadow>
								<div className="title" onClick={() => props.handleOpenTutorial(idx)}>
									<h3>{item.title[props.lang]}</h3>
									<div className="arrow"><img src={arrow} alt="" /></div>
								</div>

								<div className="content">
									<img src={item.image} alt="" className="content-image" />
									<p><HtmlParser html={item.description[props.lang]} /></p>
								</div>
							</BoxShadow>
						</motion.div>)}
					</div>
				</div>
			</motion.div>
		  )}
	  </>
	);
};

export default TutorialComponent;