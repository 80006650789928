const GAMES_LOAD_START_BONUS = 'GAMES_LOAD_START_BONUS';
const GAMES_SET_START_BONUS = 'GAMES_SET_START_BONUS';
const GAMES_LOAD_FINISH_BONUS = 'GAMES_LOAD_FINISH_BONUS';
const GAMES_SET_FINISH_BONUS = 'GAMES_SET_FINISH_BONUS';

const SOCKET_LOAD_CONNECT = 'SOCKET_LOAD_CONNECT';
const SOCKET_SET_CONNECT = 'SOCKET_SET_CONNECT';
const SOCKET_LOAD_DISCONNECT = 'SOCKET_LOAD_DISCONNECT';
const SOCKET_SET_DISCONNECT = 'SOCKET_SET_DISCONNECT';
const SOCKET_LOAD_TIMEOUT = 'SOCKET_LOAD_TIMEOUT';
const SOCKET_SET_TIMEOUT = 'SOCKET_SET_TIMEOUT';
const SOCKET_LOAD_INIT_CHALLENGE = 'SOCKET_LOAD_INIT_CHALLENGE';
const SOCKET_SET_INIT_CHALLENGE = 'SOCKET_SET_INIT_CHALLENGE';
const SOCKET_LOAD_START_CHALLENGE = 'SOCKET_LOAD_START_CHALLENGE';
const SOCKET_SET_START_CHALLENGE = 'SOCKET_SET_START_CHALLENGE';
const SOCKET_LOAD_UPDATE_CHALLENGE = 'SOCKET_LOAD_UPDATE_CHALLENGE';
const SOCKET_SET_UPDATE_CHALLENGE = 'SOCKET_SET_UPDATE_CHALLENGE';
const SOCKET_LOAD_FINISH_CHALLENGE = 'SOCKET_LOAD_FINISH_CHALLENGE';
const SOCKET_SET_FINISH_CHALLENGE = 'SOCKET_SET_FINISH_CHALLENGE';

export {
	GAMES_LOAD_START_BONUS,
    GAMES_SET_START_BONUS,
    GAMES_LOAD_FINISH_BONUS,
    GAMES_SET_FINISH_BONUS,
	SOCKET_LOAD_CONNECT,
	SOCKET_SET_CONNECT,
	SOCKET_LOAD_DISCONNECT,
	SOCKET_SET_DISCONNECT,
	SOCKET_LOAD_TIMEOUT,
	SOCKET_SET_TIMEOUT,
	SOCKET_LOAD_INIT_CHALLENGE,
	SOCKET_SET_INIT_CHALLENGE,
	SOCKET_LOAD_START_CHALLENGE,
	SOCKET_SET_START_CHALLENGE,
	SOCKET_LOAD_UPDATE_CHALLENGE,
	SOCKET_SET_UPDATE_CHALLENGE,
	SOCKET_LOAD_FINISH_CHALLENGE,
	SOCKET_SET_FINISH_CHALLENGE,
};
