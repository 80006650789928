import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';

import AuthComponent from "./AuthComponent";
import {appGetConfig, appGetInterfaceLang} from "../../../store/selectors";
import {userRepositoryPrizes} from "../../user/store/selectors";

const stateToProps = state => ({
    appGetConfig: appGetConfig(state),
    userRepositoryPrizes: userRepositoryPrizes(state),
    lang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({

});

@connect(stateToProps, actionsToProps)
class AuthContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authType: this.checkAuthType(props.match.url)
        }
    }

    checkAuthType = (url) => {
        let authType = '';

        switch (url) {
            case '/signin':
            case '/signup':
            case '/recover':
            case '/restricted':
                authType = url.replace('/','');
                break;
            default:
                authType = 'signin';
        }

        return authType;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.url !== this.props.match.url) {
            this.setState({
                authType: this.checkAuthType(this.props.match.url)
            })
        }
    }

    render() {
        // if(!this.props.appGetConfig) {
        //   return null;
        // }

        let prefix = this.props.appGetConfig && this.props.appGetConfig.users && this.props.appGetConfig.users.msisdn ? this.props.appGetConfig.users.msisdn.prefix : false;

        return (
            <AuthComponent
                {...this.state}
                {...this.props}
                prefix={prefix}
            />
        );
    }
}

export default AuthContainer;