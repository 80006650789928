import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';

import MenuComponent from "./MenuComponent";
import { appGetConfig, appGetInterfaceLang, uiGetSection } from "../../store/selectors";
import {authLogout} from "../../containers/auth/store/actions";
import { appLoadTranslations, uiRedirect } from "../../store/actions";

const stateToProps = state => ({
	section: uiGetSection(state),
	appGetConfig: appGetConfig(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
	authLogout: () => dispatch(authLogout()),
	appLoadTranslations: (data) => dispatch(appLoadTranslations(data)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
});

@connect(stateToProps, actionsToProps)
class MenuContainer extends Component {

	state = {
		currentLanguage: localStorage.getItem('languagePromo'),
		modalLanguagesIsOpen: false,
	}

	handleSubmitLanguage = () => {
		this.props.appLoadTranslations({lang: this.state.currentLanguage});
		localStorage.setItem('languagePromo', this.state.currentLanguage);
		this.toggleModal(false);
	}

	handleChangeLanguage = (e) => {
		const languageValue = e.target.value;

		this.setState({
			currentLanguage: e.target.value
		});
	}

	toggleModal = (value) => {
		this.setState({
			modalLanguagesIsOpen: value
		})
	}

	render() {
		return (
		  <MenuComponent
			key={this.props.appGetInterfaceLang}
			appGetConfig={this.props.appGetConfig}
			showMenu={this.props.showMenu}
			toggleMenu={this.props.toggleMenu}
			section={this.props.section}
			authLogout={this.props.authLogout}
			modalLanguagesIsOpen={this.state.modalLanguagesIsOpen}
			toggleModal={this.toggleModal}
			currentLanguage={this.state.currentLanguage}
			handleChangeLanguage={this.handleChangeLanguage}
			handleSubmitLanguage={this.handleSubmitLanguage}
			lang={this.props.appGetInterfaceLang}
			generate={this.props.generate}
		  />
		);
	}
}

export default MenuContainer;