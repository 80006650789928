import React from 'react';
import { Link } from "react-router-dom";
import getText, { HtmlParser } from 'AppUtils/language';

import FormField from "../../../../../../components/form-field/FormField";

const PromptComponent = (props) => {
	return (
	  <>
		  {props.currentStep === 1 ?
			<div className="phone-form">
				<p className="instructions-auth">{getText('Enter the telephone number for you account')}</p>
				<form>
					<div className="row-phone">
						<div className="prefix">
							<FormField
							  element={props.formData.prefix}
							  updateField={props.updateField}
							  disabled={true}
							/>
						</div>

						<FormField
						  element={props.formData.phone}
						  updateField={props.updateField}
						/>
					</div>
					<div className="submit-input-group">
						<button
						  type="submit"
						  className="submit-btn"
						  onClick={(e) => props.handleSubmit(e)}
						>{getText('Send SMS')}</button>
					</div>
				</form>

				<p className="terms"><HtmlParser html="By submiting this form you are agreeing to our {0}" strFormat={[`<a href=${props.termsLink} target="_blank">${getText('Terms & conditions')}</Link>`]} /></p>
			</div>
		  	:
			props.currentStep === 2 ?
			  <div className="step-2-signup">
				  <p className="instructions-auth">{getText('Enter the pin number you have received on your phone')}</p>
				  <form>
					  <FormField
						element={props.formDataStep2.pin}
						updateField={props.updateFieldStep2}
					  />
					  <div className="submit-input-group">
						  <button
							type="submit"
							className="submit-btn"
							onClick={(e) => props.handleSubmitStep2(e)}
						  >{getText('Subscribe')}</button>
					  </div>
				  </form>
			  </div>
			  : null
		  }
	  </>
	);
};

export default PromptComponent;