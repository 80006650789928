import React from 'react';
import { motion, AnimatePresence } from "framer-motion";

import './LoaderPage.scss';
import getText from 'AppUtils/language';

import globe from "../loader/img/glob.gif";

const loaderPageVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const loaderPageIconVariants = {
	hidden: {
		scale: 0
	},
	visible: {
		scale: 1,
		transition: {
			type: 'spring',
			stiffness: 300,
			damping: 20,
			duration: 0.2,
		}
	}
}

const loaderPageTextVariants = {
	hidden: {
		opacity: 0,
		y: -20
	},
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			type: 'spring',
			stiffness: 300,
			damping: 20,
			duration: 0.2
		}
	}
}

const LoaderPage = (props) => {
	return (
	  <AnimatePresence>
		  {!props.isVisible && (
			<motion.div
			  className="loader-page"
			  key="loader-page"
			  initial="hidden"
			  animate="visible"
			  exit="hidden"
			  variants={loaderPageVariants}
			>
				<div className="loader-wrapper">
					<motion.img
					  src={globe}
					  alt="Loading"
					  variants={loaderPageIconVariants}
					  initial="hidden"
					  animate="visible"
					  exit="hidden"
					/>
					<motion.p
					  variants={loaderPageTextVariants}
					  initial="hidden"
					  animate="visible"
					  exit="hidden"
					>{getText('Loading')}</motion.p>
				</div>
			</motion.div>
		  )}
	  </AnimatePresence>
	);
};

export default LoaderPage;