import React from 'react';
import { motion } from "framer-motion"
import {Link} from "react-router-dom";
import clsx from "clsx";
import Modal from 'react-modal';
import getText from 'AppUtils/language';
import { getValue } from 'AppUtils/objects';

import BoxShadow from "../../components/box-shadow/BoxShadow";
import FooterContainer from "../footer/FooterContainer";

import poweredBy from "./img/powered-by.svg";
import iconClose from "../../resources/img/icon-close.svg";
import prizeBanner from '../../containers/prizes/img/prize_banner.png';

import './Menu.scss';
import './ModalLanguages.scss';

const menuVariants = {
	hidden: {
		x: '-100%',
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	},
	visible: {
		x: 0,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const buttonCloseVariants = {
	hidden: {
		y: -70,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	},
	visible: {
		y: 0,
		transition: {
			type: 'tween',
			duration: 0.2,
		}
	}
}

const MenuComponent = (props) => {

	const renderLanguages = () => {
		if(props.appGetConfig && props.appGetConfig.i18n) {
			return Object.keys(props.appGetConfig.i18n.languages).map((language, i) => {
				const icon = getValue(props, `appGetConfig.i18n.languages.${language}.icon`);

				return <div className="custom-radio" key={language}>
					<input
					  type="radio"
					  id={language}
					  name="radio-group"
					  value={language}
					  checked={props.currentLanguage === language}
					  onChange={(e) => props.handleChangeLanguage(e)}
					/>
					<label htmlFor={language}><img src={icon} alt=""/>{props.appGetConfig.i18n.languages[language].name}
					</label>
				</div>
			})
		}
	};

	return (
	  <motion.div
		className="menu"
		variants={menuVariants}
		initial={false}
		animate={props.showMenu ? "visible" : "hidden"}
	  >
		  <div className="menu-top">
			  <img src={poweredBy} alt=""/>
			  {Object.keys(getValue(props, `appGetConfig.i18n.languages`, {})).length > 1 && <div
				className="select-languages"
			   	onClick={() => props.toggleModal(true)}
			  >
				  <img src={getValue(props, `appGetConfig.i18n.languages.${props.lang ? props.lang  : 'en'}.icon`)} alt=""/>
			  </div>}
			  <motion.div
				className="close"
				variants={buttonCloseVariants}
				initial={false}
				animate={props.showMenu ? "visible" : "hidden"}
			  >
				  <img onClick={() => props.toggleMenu()} src={iconClose} alt=""/>
			  </motion.div>
		  </div>

		  <div className="menu-list">
			  <ul className="pages">
				  <li><Link to="/" className={clsx(props.section === 'index' && "active")} onClick={() => props.toggleMenu()}>{getText('HOME')}</Link></li>
				  <li><Link to="/map" className={clsx(props.section === 'map' && "active")} onClick={() => props.toggleMenu()}>{getText('MAP')}</Link></li>
				  <li><Link to="/prizes" className={clsx(props.section === 'prizes' && "active")} onClick={() => props.toggleMenu()}>{getText('PRIZES')}</Link></li>
				  <li><Link to="/store" className={clsx(props.section === 'store' && "active")} onClick={() => props.toggleMenu()}>{getText('STORE')}</Link></li>
				  <li><Link to="/profile" className={clsx(props.section === 'profile' && "active")} onClick={() => props.toggleMenu()}>{getText('PROFILE')}</Link></li>
				  <li><Link to="/leaderboard" className={clsx(props.section === 'leaderboard' && "active")} onClick={() => props.toggleMenu()}>{getText('LEADERBOARD')}</Link></li>
			  </ul>
			  <ul className="links">
				  <li><Link to="/tutorial" onClick={() => props.toggleMenu()}>{getText('FAQ')}</Link></li>
				  {/*{getValue(props.appGetConfig, 'content.pages.faq') && <li><a target="_blank" href={props.appGetConfig.content.pages.faq} onClick={() => props.toggleMenu()}>{getText('FAQ')}</a></li>}*/}
				  {getValue(props.appGetConfig, 'content.terms') && <li><a target="_blank" href={props.appGetConfig.content.terms[props.lang]} onClick={() => props.toggleMenu()}>{getText('Terms and conditions')}</a></li>}
				  <li><Link className="logout-link" to="" onClick={() => {props.toggleMenu(); props.authLogout();}}>{getText('Logout')}</Link></li>
			  </ul>
		  </div>

		  <div className="ad">
			  <FooterContainer />
		  </div>

		  <Modal
			isOpen={props.modalLanguagesIsOpen}
			onRequestClose={() => props.toggleModal(false)}
			className="modal-general modal-languages"
		  >
			  <BoxShadow className="modal-wrapper">
				  <div className="modal-content">
					  <div className="modal-info">
						  <form action="">
							  {renderLanguages()}
						  </form>
					  </div>
					  <div className="buttons-group">
						  <div
							className="btn-modal btn-secondary"
							onClick={() => props.handleSubmitLanguage()}
						  >{getText('OK')}</div>
					  </div>
				  </div>
			  </BoxShadow>
		  </Modal>
	  </motion.div>
	);
};

export default MenuComponent;