import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import isEqual from "react-fast-compare";

import WorldMapComponent from "./WorldMapComponent";

import { userInfo, userRepositoryMap } from "../user/store/selectors";
import { appGetInterfaceLang } from "../../store/selectors";
import { userRepositoryLoadMap } from "../user/store/actions";
import MapComponent from "../map/MapComponent";
import {uiRedirect} from "../../store/actions";

const stateToProps = state => ({
	userRepositoryMap: userRepositoryMap(state),
	userInfo: userInfo(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
	userRepositoryLoadMap: () => dispatch(userRepositoryLoadMap()),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
});

@connect(stateToProps, actionsToProps)
class WorldMapContainer extends Component {

	state = {
		imagesLoaded: false,
		repositoryMap: [],
		filtersUi: {
			isLoading: true
		},
		value: {
			scale: 11,
			translation: { x: 0, y: 0}
		},
		translationBounds: {
			xMin: 0,
			xMax: 0,
			yMin: 0,
			yMax: 0
		}
	};

	mapRef = React.createRef();
	mapComponentRef = React.createRef();
	activeCityRef = React.createRef();

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	}

	handleMap = (value) => {
		this.setState({
			value,
			translationBounds: {
				xMin: -(this.mapRef.current.clientWidth * this.state.value.scale) + this.mapRef.current.clientWidth,
				xMax: 0,
				yMin: -(this.mapRef.current.clientHeight * this.state.value.scale) + this.mapComponentRef.current.clientHeight,
				yMax: 0
			}
		})
	}

	setDefault = (value) => {
		// alert('ready')
		document.querySelector('.world-zoom-out').click();

		this.setState({
			value
		})
	}

	updateDefault = (value) => {
		this.setState({
			value
		})
	}

	componentDidMount() {
		this.props.userRepositoryLoadMap();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { userRepositoryMap } = this.props;

		if (userRepositoryMap.isLoading == false && userRepositoryMap.list && (this.state.repositoryMap.length == 0 || !isEqual(userRepositoryMap.list, prevProps.userRepositoryMap.list))) {
			this.setState(prevState => ({
				repositoryMap: userRepositoryMap.list,
				filtersUi: {
					...prevState.filtersUi,
					isLoading: false
				},
			}));
		}
	}

	render() {
		const { repositoryMap, filtersUi } = this.state;

		return (
		  <WorldMapComponent
			value={this.state.value}
			translationBounds={this.state.translationBounds}
			imagesLoaded={this.state.imagesLoaded}
			handlePreloadImages={this.handlePreloadImages}
			handleMap={this.handleMap}
			mapRef={this.mapRef}
			mapComponentRef={this.mapComponentRef}
			handleMapLoad={this.handleMapLoad}
			setDefault={this.setDefault}
			repositoryMap={repositoryMap}
			filtersUi={filtersUi}
			userInfo={this.props.userInfo}
			updateDefault={this.updateDefault}
			activeCityRef={this.activeCityRef}
			uiRedirect={this.props.uiRedirect}
		  />
		);
	}
}

export default WorldMapContainer;