import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import Shake from '../../../../../node_modules/shake.js/shake';
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';
import {Redirect} from 'react-router-dom';

import LuckyCageComponent from './LuckyCageComponent';

import { uiRedirect, uiToggleModal } from "../../../../store/actions";
import {appGetInterfaceLang} from "../../../../store/selectors";
import {gamesFinishBonus, gamesStartBonus} from "../../store/selectors";
import {gamesLoadFinishBonus} from "../../store/actions";
import isEqual from "lodash-es/isEqual";

const stateToProps = state => ({
	appGetInterfaceLang: appGetInterfaceLang(state),
	gamesStartBonus: gamesStartBonus(state),
	gamesFinishBonus: gamesFinishBonus(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	gamesLoadFinishBonus: (data) => dispatch(gamesLoadFinishBonus(data)),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class LuckyCageContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
			playId:  getValue(this.props, 'location.state.playId'),
			notificationId: getValue(this.props, 'location.state.notificationId'),
            readyToOpen: false,
            opening: false,
			opened: false,
			finished: false,
			showPrize: false,
			color: this.getRandomInt(1, 4),
			shakeSupport: true,
        }

		// this.handleLoad = this.handleLoad.bind(this);
		this.videoCopyRef = false;

		if(!this.state.playId) {
			this.props.uiRedirect({
				pathname: '/'
			})
		}
    }

    handleVideoMounted = element => {
        if (element !== null) {
			this.videoCopyRef = element;
            element.addEventListener('timeupdate', () => {
                if(element.currentTime > 1.94 && !this.state.opening && !this.state.opened) {
					element.pause();
                    this.setState({
                        readyToOpen: true
                    })
                } else if(this.state.opening) {
					element.currentTime = 2.1;
					this.setState({
						opening: false,
						opened: true
					})
				} else if(this.state.opened && element.currentTime > 4.5 && !this.state.finished) {
					element.currentTime = 4;
				} else if(this.state.finished && element.currentTime < this.getBallColorTiming()) {
					element.currentTime = this.getBallColorTiming();
					setTimeout(() => {
						element.pause();
					}, 3000)
					this.setState({
						showPrize: true
					})
				}
            })
        }
    };

    getBallColorTiming = () => {
    	switch(this.state.color) {
			case 1:
				return 6;
				break;
			case 2:
				return 9;
				break;
			case 3:
				return 12;
				break;
			case 4:
				return 15;
				break;
			case 5:
				return 18;
				break;
		}
	}

    shakeEventDidOccur = () => {
        if(!this.state.opening && !this.state.opened && !this.state.finished) {
			this.setState({
				opening: true
			}, () => this.videoCopyRef.play())
		} else if(this.state.opened) {
			this.setState({
				finished: true
			})
		}
    }


	getRandomInt = (min, max) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback });
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	handleRedeem = () => {
		if(!this.state.submited) {
			this.setState({
				submited: true
			}, () => {
				this.props.gamesLoadFinishBonus({playId: this.state.playId, notificationId: this.state.notificationId})
			})
		}

		if(this.props.gamesStartBonus.prize_type === 'instant') {
			this.openModal(getText('Info'), getText('Please check your phone in order to activate the prize!'), true, getText('Ok'), false, false, false, () => {
				this.closeModal();
			});
		}
	}

    componentDidMount() {
		if(!("ondevicemotion" in window)){
			this.setState({
				shakeSupport: false
			})
		}


		let myShakeEvent = new Shake({
			threshold: 15, // optional shake strength threshold
			timeout: 1000 // optional, determines the frequency of event generation
		});


		myShakeEvent.start();

		window.addEventListener('shake', this.shakeEventDidOccur, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    	if(!isEqual(prevProps.gamesFinishBonus, this.props.gamesFinishBonus) && this.props.gamesFinishBonus) {
			this.props.uiRedirect({
			  pathname: '/'
			});
		}
	}

	render() {
		if(!this.props.gamesStartBonus) {
			return <Redirect to="/"/>;
		}

        return (
            <LuckyCageComponent
			  shakeSupport={this.state.shakeSupport}
			  showPrize={this.state.showPrize}
              handleVideoMounted={this.handleVideoMounted}
			  shakeEventDidOccur={this.shakeEventDidOccur}
			  lang={this.props.appGetInterfaceLang}
			  playId={this.state.playId}
			  notificationId={this.state.notificationId}
			  gamesStartBonus={this.props.gamesStartBonus}
			  gamesLoadFinishBonus={this.props.gamesLoadFinishBonus}
			  uiRedirect={this.props.uiRedirect}
			  handleRedeem={this.handleRedeem}
            />
        );
    }
}

export default LuckyCageContainer;