import React from 'react';
import clsx from "clsx";

import './Position.scss';

const PositionComponent = (props) => {
    return (
        <div className={clsx('position-component',
            props.position == "1" && 'position-gold',
            props.position == "2" && 'position-silver',
            props.position == "3" && 'position-bronze')}>
            <div className="position-inside">
                <p>{props.position}</p>
            </div>
        </div>
    );
};

export default PositionComponent;