import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';

import LeaderboardComponent from "./LeaderboardComponent";
import { userLoadLeaderBoard } from "../user/store/actions";
import { userInfo, userLeaderBoard } from "../user/store/selectors";
import isEqual from "react-fast-compare";
import { authStatus } from "../auth/store/selectors";

const stateToProps = state => ({
	userLeaderBoard: userLeaderBoard(state),
	userInfo: userInfo(state),
	authStatus: authStatus(state),
});

const actionsToProps = dispatch => ({
	userLoadLeaderBoard: (payload) => dispatch(userLoadLeaderBoard(payload)),
});

@connect(stateToProps, actionsToProps)
class LeaderboardContainer extends Component {

    state = {
        imagesLoaded: false,
        leaderBoard: [],
		hasMore: true,
		limit: 10,
		offset: 0,
		filtersUi: {
			isLoading: false
		},
    };

    handlePreloadImages = () => {
        this.setState({
            imagesLoaded: true
        })
    };

    componentDidMount() {
        const { limit, offset } = this.state;

		this.props.userLoadLeaderBoard({
			limit,
            offset
        });
    }

	componentDidUpdate(prevProps, prevState, snapshot) {
        const { userLeaderBoard } = this.props;

		if (userLeaderBoard.isLoading == false && userLeaderBoard.list.length > 0 && (this.state.leaderBoard.length == 0 || !isEqual(userLeaderBoard.list, prevProps.userLeaderBoard.list))) {
			this.setState(prevState => ({
				leaderBoard: [...prevState.leaderBoard, ...userLeaderBoard.list],
				hasMore: userLeaderBoard.list.length > 0,
				filtersUi: {
					...prevState.filtersUi,
					isLoading: false
				},
			}));
		}
	}

	loadItems = () => {
		if (this.state.filtersUi.isLoading || !this.state.hasMore) return;

		const { limit } = this.state;

		this.setState(prevState => ({
			offset: prevState.offset + limit,
			hasMore: false,
		}), () => {
			const { offset } = this.state;

			this.props.userLoadLeaderBoard({
				limit,
				offset
			});
		});
	}

    render() {
    	const { hasMore, filtersUi, leaderBoard, limit } = this.state;
    	const { userInfo, authStatus } = this.props;

        return (
            <LeaderboardComponent
              imagesLoaded={this.state.imagesLoaded}
              handlePreloadImages={this.handlePreloadImages}
              mainRef={this.props.mainRef}
			  hasMore={hasMore}
			  limit={limit}
			  filtersUi={filtersUi}
			  leaderBoard={leaderBoard}
			  userInfo={userInfo}
			  authStatus={authStatus}
			  loadItems={this.loadItems}
            />
        );
    }
}

export default LeaderboardContainer;