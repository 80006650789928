import React from 'react';

import './WrapperGame.scss';

const WrapperGame = (props) => {
	return (
	  <div className="wrapper-game">
		  {props.children}
	  </div>
	);
};

export default WrapperGame;