import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import isEqual from "lodash-es/isEqual";

import GameComponent from "./GameComponent";

import { gamesMsg, gamesSocket, gamesStatusCode} from "../../store/selectors";
import {appGetInterfaceLang} from "../../../../store/selectors";
import {uiRedirect, uiToggleModal} from "../../../../store/actions";
import {
	socketLoadCloseConnection,
	socketLoadInitChallenge,
} from "../../store/actions";
import {userRepositoryLoadMap} from "../../../user/store/actions";
import {userRepositoryMap} from "../../../user/store/selectors";
import getText from "../../../../utils/language";

const stateToProps = state => ({
	gamesMsg: gamesMsg(state),
	gamesSocket: gamesSocket(state),
	gamesStatusCode: gamesStatusCode(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
	userRepositoryMap: userRepositoryMap(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	socketLoadInitChallenge: (data) => dispatch(socketLoadInitChallenge(data)),
	userRepositoryLoadMap: () => dispatch(userRepositoryLoadMap()),
	socketLoadCloseConnection: () => dispatch(socketLoadCloseConnection()),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class GameContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			imagesLoaded: false,
			gameType: '',
			challengePlay: false,
			actionType: false
		}
	}

	handlePreloadImages = () => {
		this.setState({
			imagesLoaded: true
		})
	}

	handleStart = () => {
		this.setState({
			actionType: 'start'
		}, () => {
			this.props.uiRedirect({
				pathname: `/challenge/${this.state.gameType}/${this.state.challengePlay.challenge_id}`,
				start: true,
				state: {
					gameType: this.state.gameType
				}
			})
		})
	}

	openModal = (title, message, button, buttonText, button2, button2Text, restrictClose, callback, callback2) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback } );
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	componentDidMount() {
		this.props.socketLoadInitChallenge({ challengeId: this.props.match.params.challengeId });
		this.setState({
			actionType: 'init'
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.gamesSocket.error !== this.props.gamesSocket.error && this.props.gamesSocket.error) {
			this.openModal(getText('Error'), getText('Something went wrong. Please try again later.'), true, getText('Retry'), false, false, true, () => {
				this.props.uiRedirect({
					pathname: `/map`
				})
				this.closeModal();
			});
		} else {
			if (!isEqual(prevProps.gamesSocket, this.props.gamesSocket) && this.props.gamesSocket && this.props.gamesSocket.data && this.props.gamesSocket.data.challengePlay && this.state.actionType === 'init') {
				this.setState({
					challengePlay: this.props.gamesSocket.data.challengePlay,
					gameType: this.props.gamesSocket.data.challengePlay.type
				})
				if(this.props.gamesSocket.data.challengePlay.name) {
					this.props.getTitle(this.props.gamesSocket.data.challengePlay.name[this.props.appGetInterfaceLang])
				}
			}
		}
	}

	componentWillUnmount() {
		if(this.state.actionType !== 'start') {
			this.props.socketLoadCloseConnection();
		}
	}

	render() {
		return (
		  <GameComponent
			{...this.props}
			imagesLoaded={this.state.imagesLoaded}
			gameType={this.state.gameType}
			challengePlay={this.state.challengePlay}
			handleStart={this.handleStart}
			handlePreloadImages={this.handlePreloadImages}
		  />
		);
	}
}

export default GameContainer;